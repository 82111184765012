import { useState, useEffect } from "react";
import { Flex } from "@aws-amplify/ui-react";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";

const CompareSlider = ({ originalImage, newImage }) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const img = new Image();
    img.src = originalImage;
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      const maxStageHeight = window.innerHeight * 0.6;
      const maxStageWidth = maxStageHeight * aspectRatio;
      setDimensions({ width: maxStageWidth, height: maxStageHeight });
    };
  }, [originalImage]);

  return (
    <Flex
      direction="column"
      gap="2rem"
      alignSelf="center"
      height={dimensions.height}
      width={dimensions.width}
    >
      <ReactCompareSlider
        position="50"
        itemOne={<ReactCompareSliderImage src={originalImage} />}
        itemTwo={<ReactCompareSliderImage src={newImage} />}
      />
    </Flex>
  );
};

export default CompareSlider;
