/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Autocomplete,
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  ScrollView,
  Text,
  TextAreaField,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import {
  getContractor,
  listBids,
  listMessages,
  listPortfolioItems,
} from "../graphql/queries";
import {
  updateBid,
  updateContractor,
  updateMessage,
  updatePortfolioItem,
} from "../graphql/mutations";
const client = generateClient();
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function ContractorUpdateForm(props) {
  const {
    id: idProp,
    contractor: contractorModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    companyName: "",
    website: [],
    socialNetworkLinks: [],
    expertises: [],
    portfolio: "",
    PortfolioItems: [],
    phone: "",
    email: "",
    location: "",
    photo: "",
    name: "",
    status: "",
    Bids: [],
    Messages: [],
    paidStatus: "",
    paidStart: "",
    paidEnd: "",
    paidPlan: "",
  };
  const [companyName, setCompanyName] = React.useState(
    initialValues.companyName
  );
  const [website, setWebsite] = React.useState(initialValues.website);
  const [socialNetworkLinks, setSocialNetworkLinks] = React.useState(
    initialValues.socialNetworkLinks
  );
  const [expertises, setExpertises] = React.useState(initialValues.expertises);
  const [portfolio, setPortfolio] = React.useState(initialValues.portfolio);
  const [PortfolioItems, setPortfolioItems] = React.useState(
    initialValues.PortfolioItems
  );
  const [PortfolioItemsLoading, setPortfolioItemsLoading] =
    React.useState(false);
  const [portfolioItemsRecords, setPortfolioItemsRecords] = React.useState([]);
  const [phone, setPhone] = React.useState(initialValues.phone);
  const [email, setEmail] = React.useState(initialValues.email);
  const [location, setLocation] = React.useState(initialValues.location);
  const [photo, setPhoto] = React.useState(initialValues.photo);
  const [name, setName] = React.useState(initialValues.name);
  const [status, setStatus] = React.useState(initialValues.status);
  const [Bids, setBids] = React.useState(initialValues.Bids);
  const [BidsLoading, setBidsLoading] = React.useState(false);
  const [bidsRecords, setBidsRecords] = React.useState([]);
  const [Messages, setMessages] = React.useState(initialValues.Messages);
  const [MessagesLoading, setMessagesLoading] = React.useState(false);
  const [messagesRecords, setMessagesRecords] = React.useState([]);
  const [paidStatus, setPaidStatus] = React.useState(initialValues.paidStatus);
  const [paidStart, setPaidStart] = React.useState(initialValues.paidStart);
  const [paidEnd, setPaidEnd] = React.useState(initialValues.paidEnd);
  const [paidPlan, setPaidPlan] = React.useState(initialValues.paidPlan);
  const autocompleteLength = 10;
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = contractorRecord
      ? {
          ...initialValues,
          ...contractorRecord,
          PortfolioItems: linkedPortfolioItems,
          Bids: linkedBids,
          Messages: linkedMessages,
        }
      : initialValues;
    setCompanyName(cleanValues.companyName);
    setWebsite(cleanValues.website ?? []);
    setCurrentWebsiteValue("");
    setSocialNetworkLinks(cleanValues.socialNetworkLinks ?? []);
    setCurrentSocialNetworkLinksValue("");
    setExpertises(cleanValues.expertises ?? []);
    setCurrentExpertisesValue("");
    setPortfolio(cleanValues.portfolio);
    setPortfolioItems(cleanValues.PortfolioItems ?? []);
    setCurrentPortfolioItemsValue(undefined);
    setCurrentPortfolioItemsDisplayValue("");
    setPhone(cleanValues.phone);
    setEmail(cleanValues.email);
    setLocation(
      typeof cleanValues.location === "string" || cleanValues.location === null
        ? cleanValues.location
        : JSON.stringify(cleanValues.location)
    );
    setPhoto(cleanValues.photo);
    setName(cleanValues.name);
    setStatus(cleanValues.status);
    setBids(cleanValues.Bids ?? []);
    setCurrentBidsValue(undefined);
    setCurrentBidsDisplayValue("");
    setMessages(cleanValues.Messages ?? []);
    setCurrentMessagesValue(undefined);
    setCurrentMessagesDisplayValue("");
    setPaidStatus(cleanValues.paidStatus);
    setPaidStart(cleanValues.paidStart);
    setPaidEnd(cleanValues.paidEnd);
    setPaidPlan(cleanValues.paidPlan);
    setErrors({});
  };
  const [contractorRecord, setContractorRecord] =
    React.useState(contractorModelProp);
  const [linkedPortfolioItems, setLinkedPortfolioItems] = React.useState([]);
  const canUnlinkPortfolioItems = true;
  const [linkedBids, setLinkedBids] = React.useState([]);
  const canUnlinkBids = false;
  const [linkedMessages, setLinkedMessages] = React.useState([]);
  const canUnlinkMessages = false;
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getContractor.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getContractor
        : contractorModelProp;
      const linkedPortfolioItems = record?.PortfolioItems?.items ?? [];
      setLinkedPortfolioItems(linkedPortfolioItems);
      const linkedBids = record?.Bids?.items ?? [];
      setLinkedBids(linkedBids);
      const linkedMessages = record?.Messages?.items ?? [];
      setLinkedMessages(linkedMessages);
      setContractorRecord(record);
    };
    queryData();
  }, [idProp, contractorModelProp]);
  React.useEffect(resetStateValues, [
    contractorRecord,
    linkedPortfolioItems,
    linkedBids,
    linkedMessages,
  ]);
  const [currentWebsiteValue, setCurrentWebsiteValue] = React.useState("");
  const websiteRef = React.createRef();
  const [currentSocialNetworkLinksValue, setCurrentSocialNetworkLinksValue] =
    React.useState("");
  const socialNetworkLinksRef = React.createRef();
  const [currentExpertisesValue, setCurrentExpertisesValue] =
    React.useState("");
  const expertisesRef = React.createRef();
  const [
    currentPortfolioItemsDisplayValue,
    setCurrentPortfolioItemsDisplayValue,
  ] = React.useState("");
  const [currentPortfolioItemsValue, setCurrentPortfolioItemsValue] =
    React.useState(undefined);
  const PortfolioItemsRef = React.createRef();
  const [currentBidsDisplayValue, setCurrentBidsDisplayValue] =
    React.useState("");
  const [currentBidsValue, setCurrentBidsValue] = React.useState(undefined);
  const BidsRef = React.createRef();
  const [currentMessagesDisplayValue, setCurrentMessagesDisplayValue] =
    React.useState("");
  const [currentMessagesValue, setCurrentMessagesValue] =
    React.useState(undefined);
  const MessagesRef = React.createRef();
  const getIDValue = {
    PortfolioItems: (r) => JSON.stringify({ id: r?.id }),
    Bids: (r) => JSON.stringify({ id: r?.id }),
    Messages: (r) => JSON.stringify({ id: r?.id }),
  };
  const PortfolioItemsIdSet = new Set(
    Array.isArray(PortfolioItems)
      ? PortfolioItems.map((r) => getIDValue.PortfolioItems?.(r))
      : getIDValue.PortfolioItems?.(PortfolioItems)
  );
  const BidsIdSet = new Set(
    Array.isArray(Bids)
      ? Bids.map((r) => getIDValue.Bids?.(r))
      : getIDValue.Bids?.(Bids)
  );
  const MessagesIdSet = new Set(
    Array.isArray(Messages)
      ? Messages.map((r) => getIDValue.Messages?.(r))
      : getIDValue.Messages?.(Messages)
  );
  const getDisplayValue = {
    PortfolioItems: (r) =>
      `${r?.projectName ? r?.projectName + " - " : ""}${r?.id}`,
    Bids: (r) => `${r?.title ? r?.title + " - " : ""}${r?.id}`,
    Messages: (r) => `${r?.content ? r?.content + " - " : ""}${r?.id}`,
  };
  const validations = {
    companyName: [],
    website: [],
    socialNetworkLinks: [],
    expertises: [],
    portfolio: [],
    PortfolioItems: [],
    phone: [{ type: "Phone" }],
    email: [{ type: "Email" }],
    location: [{ type: "JSON" }],
    photo: [],
    name: [],
    status: [],
    Bids: [],
    Messages: [],
    paidStatus: [],
    paidStart: [],
    paidEnd: [],
    paidPlan: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  const fetchPortfolioItemsRecords = async (value) => {
    setPortfolioItemsLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [
            { projectName: { contains: value } },
            { id: { contains: value } },
          ],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listPortfolioItems.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listPortfolioItems?.items;
      var loaded = result.filter(
        (item) => !PortfolioItemsIdSet.has(getIDValue.PortfolioItems?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setPortfolioItemsRecords(newOptions.slice(0, autocompleteLength));
    setPortfolioItemsLoading(false);
  };
  const fetchBidsRecords = async (value) => {
    setBidsLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ title: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listBids.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listBids?.items;
      var loaded = result.filter(
        (item) => !BidsIdSet.has(getIDValue.Bids?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setBidsRecords(newOptions.slice(0, autocompleteLength));
    setBidsLoading(false);
  };
  const fetchMessagesRecords = async (value) => {
    setMessagesLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ content: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listMessages.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listMessages?.items;
      var loaded = result.filter(
        (item) => !MessagesIdSet.has(getIDValue.Messages?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setMessagesRecords(newOptions.slice(0, autocompleteLength));
    setMessagesLoading(false);
  };
  React.useEffect(() => {
    fetchPortfolioItemsRecords("");
    fetchBidsRecords("");
    fetchMessagesRecords("");
  }, []);
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          companyName: companyName ?? null,
          website: website ?? null,
          socialNetworkLinks: socialNetworkLinks ?? null,
          expertises: expertises ?? null,
          portfolio: portfolio ?? null,
          PortfolioItems: PortfolioItems ?? null,
          phone: phone ?? null,
          email: email ?? null,
          location: location ?? null,
          photo: photo ?? null,
          name: name ?? null,
          status: status ?? null,
          Bids: Bids ?? null,
          Messages: Messages ?? null,
          paidStatus: paidStatus ?? null,
          paidStart: paidStart ?? null,
          paidEnd: paidEnd ?? null,
          paidPlan: paidPlan ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(
                    fieldName,
                    item,
                    getDisplayValue[fieldName]
                  )
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(
                fieldName,
                modelFields[fieldName],
                getDisplayValue[fieldName]
              )
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          const promises = [];
          const portfolioItemsToLink = [];
          const portfolioItemsToUnLink = [];
          const portfolioItemsSet = new Set();
          const linkedPortfolioItemsSet = new Set();
          PortfolioItems.forEach((r) =>
            portfolioItemsSet.add(getIDValue.PortfolioItems?.(r))
          );
          linkedPortfolioItems.forEach((r) =>
            linkedPortfolioItemsSet.add(getIDValue.PortfolioItems?.(r))
          );
          linkedPortfolioItems.forEach((r) => {
            if (!portfolioItemsSet.has(getIDValue.PortfolioItems?.(r))) {
              portfolioItemsToUnLink.push(r);
            }
          });
          PortfolioItems.forEach((r) => {
            if (!linkedPortfolioItemsSet.has(getIDValue.PortfolioItems?.(r))) {
              portfolioItemsToLink.push(r);
            }
          });
          portfolioItemsToUnLink.forEach((original) => {
            if (!canUnlinkPortfolioItems) {
              throw Error(
                `PortfolioItem ${original.id} cannot be unlinked from Contractor because contractorID is a required field.`
              );
            }
            promises.push(
              client.graphql({
                query: updatePortfolioItem.replaceAll("__typename", ""),
                variables: {
                  input: {
                    id: original.id,
                    contractorID: null,
                  },
                },
              })
            );
          });
          portfolioItemsToLink.forEach((original) => {
            promises.push(
              client.graphql({
                query: updatePortfolioItem.replaceAll("__typename", ""),
                variables: {
                  input: {
                    id: original.id,
                    contractorID: contractorRecord.id,
                  },
                },
              })
            );
          });
          const bidsToLink = [];
          const bidsToUnLink = [];
          const bidsSet = new Set();
          const linkedBidsSet = new Set();
          Bids.forEach((r) => bidsSet.add(getIDValue.Bids?.(r)));
          linkedBids.forEach((r) => linkedBidsSet.add(getIDValue.Bids?.(r)));
          linkedBids.forEach((r) => {
            if (!bidsSet.has(getIDValue.Bids?.(r))) {
              bidsToUnLink.push(r);
            }
          });
          Bids.forEach((r) => {
            if (!linkedBidsSet.has(getIDValue.Bids?.(r))) {
              bidsToLink.push(r);
            }
          });
          bidsToUnLink.forEach((original) => {
            if (!canUnlinkBids) {
              throw Error(
                `Bid ${original.id} cannot be unlinked from Contractor because contractorID is a required field.`
              );
            }
            promises.push(
              client.graphql({
                query: updateBid.replaceAll("__typename", ""),
                variables: {
                  input: {
                    id: original.id,
                    contractorID: null,
                  },
                },
              })
            );
          });
          bidsToLink.forEach((original) => {
            promises.push(
              client.graphql({
                query: updateBid.replaceAll("__typename", ""),
                variables: {
                  input: {
                    id: original.id,
                    contractorID: contractorRecord.id,
                  },
                },
              })
            );
          });
          const messagesToLink = [];
          const messagesToUnLink = [];
          const messagesSet = new Set();
          const linkedMessagesSet = new Set();
          Messages.forEach((r) => messagesSet.add(getIDValue.Messages?.(r)));
          linkedMessages.forEach((r) =>
            linkedMessagesSet.add(getIDValue.Messages?.(r))
          );
          linkedMessages.forEach((r) => {
            if (!messagesSet.has(getIDValue.Messages?.(r))) {
              messagesToUnLink.push(r);
            }
          });
          Messages.forEach((r) => {
            if (!linkedMessagesSet.has(getIDValue.Messages?.(r))) {
              messagesToLink.push(r);
            }
          });
          messagesToUnLink.forEach((original) => {
            if (!canUnlinkMessages) {
              throw Error(
                `Message ${original.id} cannot be unlinked from Contractor because contractorID is a required field.`
              );
            }
            promises.push(
              client.graphql({
                query: updateMessage.replaceAll("__typename", ""),
                variables: {
                  input: {
                    id: original.id,
                    contractorID: null,
                  },
                },
              })
            );
          });
          messagesToLink.forEach((original) => {
            promises.push(
              client.graphql({
                query: updateMessage.replaceAll("__typename", ""),
                variables: {
                  input: {
                    id: original.id,
                    contractorID: contractorRecord.id,
                  },
                },
              })
            );
          });
          const modelFieldsToSave = {
            companyName: modelFields.companyName ?? null,
            website: modelFields.website ?? null,
            socialNetworkLinks: modelFields.socialNetworkLinks ?? null,
            expertises: modelFields.expertises ?? null,
            portfolio: modelFields.portfolio ?? null,
            phone: modelFields.phone ?? null,
            email: modelFields.email ?? null,
            photo: modelFields.photo ?? null,
            name: modelFields.name ?? null,
            status: modelFields.status ?? null,
            paidStatus: modelFields.paidStatus ?? null,
            paidStart: modelFields.paidStart ?? null,
            paidEnd: modelFields.paidEnd ?? null,
            paidPlan: modelFields.paidPlan ?? null,
            location: modelFields.location
              ? JSON.parse(modelFields.location)
              : modelFields.location,
          };
          promises.push(
            client.graphql({
              query: updateContractor.replaceAll("__typename", ""),
              variables: {
                input: {
                  id: contractorRecord.id,
                  ...modelFieldsToSave,
                },
              },
            })
          );
          await Promise.all(promises);
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "ContractorUpdateForm")}
      {...rest}
    >
      <TextField
        label="Company name"
        isRequired={false}
        isReadOnly={false}
        value={companyName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              companyName: value,
              website,
              socialNetworkLinks,
              expertises,
              portfolio,
              PortfolioItems,
              phone,
              email,
              location,
              photo,
              name,
              status,
              Bids,
              Messages,
              paidStatus,
              paidStart,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            value = result?.companyName ?? value;
          }
          if (errors.companyName?.hasError) {
            runValidationTasks("companyName", value);
          }
          setCompanyName(value);
        }}
        onBlur={() => runValidationTasks("companyName", companyName)}
        errorMessage={errors.companyName?.errorMessage}
        hasError={errors.companyName?.hasError}
        {...getOverrideProps(overrides, "companyName")}
      ></TextField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              companyName,
              website: values,
              socialNetworkLinks,
              expertises,
              portfolio,
              PortfolioItems,
              phone,
              email,
              location,
              photo,
              name,
              status,
              Bids,
              Messages,
              paidStatus,
              paidStart,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            values = result?.website ?? values;
          }
          setWebsite(values);
          setCurrentWebsiteValue("");
        }}
        currentFieldValue={currentWebsiteValue}
        label={"Website"}
        items={website}
        hasError={errors?.website?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("website", currentWebsiteValue)
        }
        errorMessage={errors?.website?.errorMessage}
        setFieldValue={setCurrentWebsiteValue}
        inputFieldRef={websiteRef}
        defaultFieldValue={""}
      >
        <TextField
          label="Website"
          isRequired={false}
          isReadOnly={false}
          value={currentWebsiteValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.website?.hasError) {
              runValidationTasks("website", value);
            }
            setCurrentWebsiteValue(value);
          }}
          onBlur={() => runValidationTasks("website", currentWebsiteValue)}
          errorMessage={errors.website?.errorMessage}
          hasError={errors.website?.hasError}
          ref={websiteRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "website")}
        ></TextField>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              companyName,
              website,
              socialNetworkLinks: values,
              expertises,
              portfolio,
              PortfolioItems,
              phone,
              email,
              location,
              photo,
              name,
              status,
              Bids,
              Messages,
              paidStatus,
              paidStart,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            values = result?.socialNetworkLinks ?? values;
          }
          setSocialNetworkLinks(values);
          setCurrentSocialNetworkLinksValue("");
        }}
        currentFieldValue={currentSocialNetworkLinksValue}
        label={"Social network links"}
        items={socialNetworkLinks}
        hasError={errors?.socialNetworkLinks?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks(
            "socialNetworkLinks",
            currentSocialNetworkLinksValue
          )
        }
        errorMessage={errors?.socialNetworkLinks?.errorMessage}
        setFieldValue={setCurrentSocialNetworkLinksValue}
        inputFieldRef={socialNetworkLinksRef}
        defaultFieldValue={""}
      >
        <TextField
          label="Social network links"
          isRequired={false}
          isReadOnly={false}
          value={currentSocialNetworkLinksValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.socialNetworkLinks?.hasError) {
              runValidationTasks("socialNetworkLinks", value);
            }
            setCurrentSocialNetworkLinksValue(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "socialNetworkLinks",
              currentSocialNetworkLinksValue
            )
          }
          errorMessage={errors.socialNetworkLinks?.errorMessage}
          hasError={errors.socialNetworkLinks?.hasError}
          ref={socialNetworkLinksRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "socialNetworkLinks")}
        ></TextField>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              companyName,
              website,
              socialNetworkLinks,
              expertises: values,
              portfolio,
              PortfolioItems,
              phone,
              email,
              location,
              photo,
              name,
              status,
              Bids,
              Messages,
              paidStatus,
              paidStart,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            values = result?.expertises ?? values;
          }
          setExpertises(values);
          setCurrentExpertisesValue("");
        }}
        currentFieldValue={currentExpertisesValue}
        label={"Expertises"}
        items={expertises}
        hasError={errors?.expertises?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("expertises", currentExpertisesValue)
        }
        errorMessage={errors?.expertises?.errorMessage}
        setFieldValue={setCurrentExpertisesValue}
        inputFieldRef={expertisesRef}
        defaultFieldValue={""}
      >
        <TextField
          label="Expertises"
          isRequired={false}
          isReadOnly={false}
          value={currentExpertisesValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.expertises?.hasError) {
              runValidationTasks("expertises", value);
            }
            setCurrentExpertisesValue(value);
          }}
          onBlur={() =>
            runValidationTasks("expertises", currentExpertisesValue)
          }
          errorMessage={errors.expertises?.errorMessage}
          hasError={errors.expertises?.hasError}
          ref={expertisesRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "expertises")}
        ></TextField>
      </ArrayField>
      <TextField
        label="Portfolio"
        isRequired={false}
        isReadOnly={false}
        value={portfolio}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              companyName,
              website,
              socialNetworkLinks,
              expertises,
              portfolio: value,
              PortfolioItems,
              phone,
              email,
              location,
              photo,
              name,
              status,
              Bids,
              Messages,
              paidStatus,
              paidStart,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            value = result?.portfolio ?? value;
          }
          if (errors.portfolio?.hasError) {
            runValidationTasks("portfolio", value);
          }
          setPortfolio(value);
        }}
        onBlur={() => runValidationTasks("portfolio", portfolio)}
        errorMessage={errors.portfolio?.errorMessage}
        hasError={errors.portfolio?.hasError}
        {...getOverrideProps(overrides, "portfolio")}
      ></TextField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              companyName,
              website,
              socialNetworkLinks,
              expertises,
              portfolio,
              PortfolioItems: values,
              phone,
              email,
              location,
              photo,
              name,
              status,
              Bids,
              Messages,
              paidStatus,
              paidStart,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            values = result?.PortfolioItems ?? values;
          }
          setPortfolioItems(values);
          setCurrentPortfolioItemsValue(undefined);
          setCurrentPortfolioItemsDisplayValue("");
        }}
        currentFieldValue={currentPortfolioItemsValue}
        label={"Portfolio items"}
        items={PortfolioItems}
        hasError={errors?.PortfolioItems?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("PortfolioItems", currentPortfolioItemsValue)
        }
        errorMessage={errors?.PortfolioItems?.errorMessage}
        getBadgeText={getDisplayValue.PortfolioItems}
        setFieldValue={(model) => {
          setCurrentPortfolioItemsDisplayValue(
            model ? getDisplayValue.PortfolioItems(model) : ""
          );
          setCurrentPortfolioItemsValue(model);
        }}
        inputFieldRef={PortfolioItemsRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Portfolio items"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search PortfolioItem"
          value={currentPortfolioItemsDisplayValue}
          options={portfolioItemsRecords
            .filter(
              (r) => !PortfolioItemsIdSet.has(getIDValue.PortfolioItems?.(r))
            )
            .map((r) => ({
              id: getIDValue.PortfolioItems?.(r),
              label: getDisplayValue.PortfolioItems?.(r),
            }))}
          isLoading={PortfolioItemsLoading}
          onSelect={({ id, label }) => {
            setCurrentPortfolioItemsValue(
              portfolioItemsRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentPortfolioItemsDisplayValue(label);
            runValidationTasks("PortfolioItems", label);
          }}
          onClear={() => {
            setCurrentPortfolioItemsDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchPortfolioItemsRecords(value);
            if (errors.PortfolioItems?.hasError) {
              runValidationTasks("PortfolioItems", value);
            }
            setCurrentPortfolioItemsDisplayValue(value);
            setCurrentPortfolioItemsValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks(
              "PortfolioItems",
              currentPortfolioItemsDisplayValue
            )
          }
          errorMessage={errors.PortfolioItems?.errorMessage}
          hasError={errors.PortfolioItems?.hasError}
          ref={PortfolioItemsRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "PortfolioItems")}
        ></Autocomplete>
      </ArrayField>
      <TextField
        label="Phone"
        isRequired={false}
        isReadOnly={false}
        type="tel"
        value={phone}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              companyName,
              website,
              socialNetworkLinks,
              expertises,
              portfolio,
              PortfolioItems,
              phone: value,
              email,
              location,
              photo,
              name,
              status,
              Bids,
              Messages,
              paidStatus,
              paidStart,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            value = result?.phone ?? value;
          }
          if (errors.phone?.hasError) {
            runValidationTasks("phone", value);
          }
          setPhone(value);
        }}
        onBlur={() => runValidationTasks("phone", phone)}
        errorMessage={errors.phone?.errorMessage}
        hasError={errors.phone?.hasError}
        {...getOverrideProps(overrides, "phone")}
      ></TextField>
      <TextField
        label="Email"
        isRequired={false}
        isReadOnly={false}
        value={email}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              companyName,
              website,
              socialNetworkLinks,
              expertises,
              portfolio,
              PortfolioItems,
              phone,
              email: value,
              location,
              photo,
              name,
              status,
              Bids,
              Messages,
              paidStatus,
              paidStart,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            value = result?.email ?? value;
          }
          if (errors.email?.hasError) {
            runValidationTasks("email", value);
          }
          setEmail(value);
        }}
        onBlur={() => runValidationTasks("email", email)}
        errorMessage={errors.email?.errorMessage}
        hasError={errors.email?.hasError}
        {...getOverrideProps(overrides, "email")}
      ></TextField>
      <TextAreaField
        label="Location"
        isRequired={false}
        isReadOnly={false}
        value={location}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              companyName,
              website,
              socialNetworkLinks,
              expertises,
              portfolio,
              PortfolioItems,
              phone,
              email,
              location: value,
              photo,
              name,
              status,
              Bids,
              Messages,
              paidStatus,
              paidStart,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            value = result?.location ?? value;
          }
          if (errors.location?.hasError) {
            runValidationTasks("location", value);
          }
          setLocation(value);
        }}
        onBlur={() => runValidationTasks("location", location)}
        errorMessage={errors.location?.errorMessage}
        hasError={errors.location?.hasError}
        {...getOverrideProps(overrides, "location")}
      ></TextAreaField>
      <TextField
        label="Photo"
        isRequired={false}
        isReadOnly={false}
        value={photo}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              companyName,
              website,
              socialNetworkLinks,
              expertises,
              portfolio,
              PortfolioItems,
              phone,
              email,
              location,
              photo: value,
              name,
              status,
              Bids,
              Messages,
              paidStatus,
              paidStart,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            value = result?.photo ?? value;
          }
          if (errors.photo?.hasError) {
            runValidationTasks("photo", value);
          }
          setPhoto(value);
        }}
        onBlur={() => runValidationTasks("photo", photo)}
        errorMessage={errors.photo?.errorMessage}
        hasError={errors.photo?.hasError}
        {...getOverrideProps(overrides, "photo")}
      ></TextField>
      <TextField
        label="Name"
        isRequired={false}
        isReadOnly={false}
        value={name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              companyName,
              website,
              socialNetworkLinks,
              expertises,
              portfolio,
              PortfolioItems,
              phone,
              email,
              location,
              photo,
              name: value,
              status,
              Bids,
              Messages,
              paidStatus,
              paidStart,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks("name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("name", name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        {...getOverrideProps(overrides, "name")}
      ></TextField>
      <TextField
        label="Status"
        isRequired={false}
        isReadOnly={false}
        value={status}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              companyName,
              website,
              socialNetworkLinks,
              expertises,
              portfolio,
              PortfolioItems,
              phone,
              email,
              location,
              photo,
              name,
              status: value,
              Bids,
              Messages,
              paidStatus,
              paidStart,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            value = result?.status ?? value;
          }
          if (errors.status?.hasError) {
            runValidationTasks("status", value);
          }
          setStatus(value);
        }}
        onBlur={() => runValidationTasks("status", status)}
        errorMessage={errors.status?.errorMessage}
        hasError={errors.status?.hasError}
        {...getOverrideProps(overrides, "status")}
      ></TextField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              companyName,
              website,
              socialNetworkLinks,
              expertises,
              portfolio,
              PortfolioItems,
              phone,
              email,
              location,
              photo,
              name,
              status,
              Bids: values,
              Messages,
              paidStatus,
              paidStart,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            values = result?.Bids ?? values;
          }
          setBids(values);
          setCurrentBidsValue(undefined);
          setCurrentBidsDisplayValue("");
        }}
        currentFieldValue={currentBidsValue}
        label={"Bids"}
        items={Bids}
        hasError={errors?.Bids?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("Bids", currentBidsValue)
        }
        errorMessage={errors?.Bids?.errorMessage}
        getBadgeText={getDisplayValue.Bids}
        setFieldValue={(model) => {
          setCurrentBidsDisplayValue(model ? getDisplayValue.Bids(model) : "");
          setCurrentBidsValue(model);
        }}
        inputFieldRef={BidsRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Bids"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Bid"
          value={currentBidsDisplayValue}
          options={bidsRecords
            .filter((r) => !BidsIdSet.has(getIDValue.Bids?.(r)))
            .map((r) => ({
              id: getIDValue.Bids?.(r),
              label: getDisplayValue.Bids?.(r),
            }))}
          isLoading={BidsLoading}
          onSelect={({ id, label }) => {
            setCurrentBidsValue(
              bidsRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentBidsDisplayValue(label);
            runValidationTasks("Bids", label);
          }}
          onClear={() => {
            setCurrentBidsDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchBidsRecords(value);
            if (errors.Bids?.hasError) {
              runValidationTasks("Bids", value);
            }
            setCurrentBidsDisplayValue(value);
            setCurrentBidsValue(undefined);
          }}
          onBlur={() => runValidationTasks("Bids", currentBidsDisplayValue)}
          errorMessage={errors.Bids?.errorMessage}
          hasError={errors.Bids?.hasError}
          ref={BidsRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "Bids")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              companyName,
              website,
              socialNetworkLinks,
              expertises,
              portfolio,
              PortfolioItems,
              phone,
              email,
              location,
              photo,
              name,
              status,
              Bids,
              Messages: values,
              paidStatus,
              paidStart,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            values = result?.Messages ?? values;
          }
          setMessages(values);
          setCurrentMessagesValue(undefined);
          setCurrentMessagesDisplayValue("");
        }}
        currentFieldValue={currentMessagesValue}
        label={"Messages"}
        items={Messages}
        hasError={errors?.Messages?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("Messages", currentMessagesValue)
        }
        errorMessage={errors?.Messages?.errorMessage}
        getBadgeText={getDisplayValue.Messages}
        setFieldValue={(model) => {
          setCurrentMessagesDisplayValue(
            model ? getDisplayValue.Messages(model) : ""
          );
          setCurrentMessagesValue(model);
        }}
        inputFieldRef={MessagesRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Messages"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Message"
          value={currentMessagesDisplayValue}
          options={messagesRecords
            .filter((r) => !MessagesIdSet.has(getIDValue.Messages?.(r)))
            .map((r) => ({
              id: getIDValue.Messages?.(r),
              label: getDisplayValue.Messages?.(r),
            }))}
          isLoading={MessagesLoading}
          onSelect={({ id, label }) => {
            setCurrentMessagesValue(
              messagesRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentMessagesDisplayValue(label);
            runValidationTasks("Messages", label);
          }}
          onClear={() => {
            setCurrentMessagesDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchMessagesRecords(value);
            if (errors.Messages?.hasError) {
              runValidationTasks("Messages", value);
            }
            setCurrentMessagesDisplayValue(value);
            setCurrentMessagesValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks("Messages", currentMessagesDisplayValue)
          }
          errorMessage={errors.Messages?.errorMessage}
          hasError={errors.Messages?.hasError}
          ref={MessagesRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "Messages")}
        ></Autocomplete>
      </ArrayField>
      <TextField
        label="Paid status"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={paidStatus}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              companyName,
              website,
              socialNetworkLinks,
              expertises,
              portfolio,
              PortfolioItems,
              phone,
              email,
              location,
              photo,
              name,
              status,
              Bids,
              Messages,
              paidStatus: value,
              paidStart,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            value = result?.paidStatus ?? value;
          }
          if (errors.paidStatus?.hasError) {
            runValidationTasks("paidStatus", value);
          }
          setPaidStatus(value);
        }}
        onBlur={() => runValidationTasks("paidStatus", paidStatus)}
        errorMessage={errors.paidStatus?.errorMessage}
        hasError={errors.paidStatus?.hasError}
        {...getOverrideProps(overrides, "paidStatus")}
      ></TextField>
      <TextField
        label="Paid start"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={paidStart}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              companyName,
              website,
              socialNetworkLinks,
              expertises,
              portfolio,
              PortfolioItems,
              phone,
              email,
              location,
              photo,
              name,
              status,
              Bids,
              Messages,
              paidStatus,
              paidStart: value,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            value = result?.paidStart ?? value;
          }
          if (errors.paidStart?.hasError) {
            runValidationTasks("paidStart", value);
          }
          setPaidStart(value);
        }}
        onBlur={() => runValidationTasks("paidStart", paidStart)}
        errorMessage={errors.paidStart?.errorMessage}
        hasError={errors.paidStart?.hasError}
        {...getOverrideProps(overrides, "paidStart")}
      ></TextField>
      <TextField
        label="Paid end"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={paidEnd}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              companyName,
              website,
              socialNetworkLinks,
              expertises,
              portfolio,
              PortfolioItems,
              phone,
              email,
              location,
              photo,
              name,
              status,
              Bids,
              Messages,
              paidStatus,
              paidStart,
              paidEnd: value,
              paidPlan,
            };
            const result = onChange(modelFields);
            value = result?.paidEnd ?? value;
          }
          if (errors.paidEnd?.hasError) {
            runValidationTasks("paidEnd", value);
          }
          setPaidEnd(value);
        }}
        onBlur={() => runValidationTasks("paidEnd", paidEnd)}
        errorMessage={errors.paidEnd?.errorMessage}
        hasError={errors.paidEnd?.hasError}
        {...getOverrideProps(overrides, "paidEnd")}
      ></TextField>
      <TextField
        label="Paid plan"
        isRequired={false}
        isReadOnly={false}
        value={paidPlan}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              companyName,
              website,
              socialNetworkLinks,
              expertises,
              portfolio,
              PortfolioItems,
              phone,
              email,
              location,
              photo,
              name,
              status,
              Bids,
              Messages,
              paidStatus,
              paidStart,
              paidEnd,
              paidPlan: value,
            };
            const result = onChange(modelFields);
            value = result?.paidPlan ?? value;
          }
          if (errors.paidPlan?.hasError) {
            runValidationTasks("paidPlan", value);
          }
          setPaidPlan(value);
        }}
        onBlur={() => runValidationTasks("paidPlan", paidPlan)}
        errorMessage={errors.paidPlan?.errorMessage}
        hasError={errors.paidPlan?.hasError}
        {...getOverrideProps(overrides, "paidPlan")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || contractorModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || contractorModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
