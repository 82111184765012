import { useState, useEffect } from "react";
import { CheckCircle } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { post, generateClient } from "aws-amplify/api";
import {
  updateCustomer,
  updateContractor,
} from "../../../../graphql/mutations";
import { getCustomer, getContractor } from "../../../../graphql/queries";

export default function PaymentSuccess() {
  const client = generateClient();
  const navigate = useNavigate();
  const [checking, setChecking] = useState(true);
  const [error, setError] = useState(null);
  const id = localStorage.getItem("id");
  const role = localStorage.getItem("role");

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const sessionId = queryParams.get("session_id");
    const productId = queryParams.get("product_id");

    if (sessionId) {
      const verifyPayment = async () => {
        try {
          const restOperation = post({
            apiName: "payment",
            path: "/stripe/verify",
            options: {
              body: { sessionId },
              headers: {
                "Content-Type": "application/json",
              },
            },
          });

          const { body } = await restOperation.response;
          const responseBody = await body.json();
          if (responseBody.success) {
            setError(null);
            console.log("Payment verified successfully:", responseBody);
            if (role === "customer") {
              updateCustomerStatus(productId);
            } else {
              updateContractorStatus(productId);
            }
          } else {
            navigate("/payment/cancel");
          }
        } catch (error) {
          setError("Payment verification failed:", error);
          navigate("/payment/cancel");
        } finally {
          setChecking(false);
        }
      };

      verifyPayment();
    } else {
      setError("No session ID found in URL");
      navigate("/payment/cancel");
    }
  }, [navigate]);

  const updateCustomerStatus = async ({ productId }) => {
    const customer = await client.graphql({
      query: getCustomer.replaceAll("__typename", ""),
      variables: { id },
    });

    if (customer && customer.data.getCustomer) {
      const currentPaidStatus = customer.data.getCustomer.paidStatus || 0;
      try {
        const response = await client
          .graphql({
            query: updateCustomer.replaceAll("__typename", ""),
            variables: {
              input: {
                id,
                paidStatus: currentPaidStatus + 1,
                paidStart: new Date().toISOString().split("T")[0],
                paidPlan: productId,
              },
            },
          })
          .catch((error) => {
            setError("Error updating customer:", error);
          });

        if (response) {
          setError(null);
        }
      } catch (error) {
        setError("Error updating customer:", error);
      }
    }
  };

  const updateContractorStatus = async ({ productId }) => {
    const contractor = await client.graphql({
      query: getContractor.replaceAll("__typename", ""),
      variables: { id },
    });
    if (contractor && contractor.data.getContractor) {
      const currentPaidStatus = contractor.data.getContractor.paidStatus || 0;
      try {
        const response = await client.graphql({
          query: updateContractor.replaceAll("__typename", ""),
          variables: {
            input: {
              id,
              paidStatus: currentPaidStatus + 100,
              paidStart: new Date().toISOString().split("T")[0],
              paidPlan: productId,
            },
          },
        });

        if (response) {
          setError(null);
        }
      } catch (error) {
        setError("Error updating contractor:", error);
      }
    }
  };

  if (checking) {
    // Render loading animation while checking is true
    return (
      <div className="min-h-screen flex items-center justify-center bg-white">
        <div className="text-center justify-center items-center">
          <svg
            className="animate-spin h-10 w-10 text-green-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
            ></path>
          </svg>
          <p className="mt-4 text-gray-700">Checking payment status...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-white from-green-400 to-blue-500">
      <div className="bg-gray p-8 rounded-lg shadow-xl text-center">
        <CheckCircle className="mx-auto h-16 w-16 text-green-500 mb-4" />
        <h1 className="text-2xl font-bold text-gray-800 mb-2">
          Payment Successful!
        </h1>
        <p className="text-gray-600 mb-6">
          Thank you for your purchase. Your transaction has been completed
          successfully.
        </p>
        <button
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
          onClick={() => navigate("/")}
        >
          Return to Home
        </button>
      </div>
    </div>
  );
}
