/* eslint-disable no-extra-semi */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import { Authenticator, Flex } from "@aws-amplify/ui-react";
import { fetchUserAttributes, getCurrentUser } from "aws-amplify/auth";
import { generateClient } from "aws-amplify/api";
import { listUsers } from "../graphql/queries";

import Home from "./pages/homePage/Home";
import AboutPage from "./pages/aboutPage";
import CreatePage from "./pages/createPage";
import DetailPage from "./pages/createPage/detailPage";
import AuthenticationPage from "./pages/authenticatePage";
import ProjectListPage from "./pages/projectListPage/projectListPage";
import ProjectBidPage from "./pages/projectBidPage/projectBidPage";
import ProjectDetailModal from "./pages/projectListPage/projectDetailModal";
import PrivacyPolicy from "./pages/privacyPolicyPage";
import Dashboard from "./pages/dashboard/Dashboard";
import ProjectDetail from "./pages/dashboard/common/ProjectDetail";
// import BidDetail from "./pages/dashboard/contractor/BidDetail";
import PaymentSuccess from "./pages/homePage/statusPage/PaymentSuccess";
import PaymentCancel from "./pages/homePage/statusPage/PaymentCancel";
import Layout from "./Layout";

const client = generateClient();

const AppRouter = function () {
  // const { user } = useAuthenticator();
  const [user, setUser] = useState(undefined);
  const [userAttributes, setUserAttributes] = useState(null);
  const [userLoading, setUserLoading] = useState(true);
  const [authLoading, setAuthLoading] = useState(true);
  const [roleLoading, setRoleLoading] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const [userRoleId, setUserRoleId] = useState(null);

  useEffect(() => {
    async function fetchUser() {
      try {
        setUserLoading(true);
        const fetchedUserAttributes = await fetchUserAttributes();
        setUserAttributes(fetchedUserAttributes);
      } catch (err) {
        console.log(err);
      } finally {
        setUserLoading(false);
      }
    }
    fetchUser();
  }, []);

  useEffect(() => {
    async function currentAuthenticatedUser() {
      try {
        setAuthLoading(true);
        const { userId } = await getCurrentUser();
        setUser(userId);
      } catch (err) {
        console.log(err);
        setUser(undefined);
      } finally {
        setAuthLoading(false);
      }
    }
    currentAuthenticatedUser();
  }, []);

  useEffect(() => {
    if (!user) return;

    async function currentUserRole() {
      try {
        setRoleLoading(true);
        const userLists = (
          await client.graphql({
            query: listUsers,
          })
        ).data.listUsers.items;
        const currentUser = userLists.find(
          (userItem) => userItem.userId === user
        );
        if (currentUser) {
          setUserRole(currentUser.role);
          setUserRoleId(currentUser.roleId);

          localStorage.setItem("role", currentUser.role);
          localStorage.setItem("id", currentUser.roleId);
        } else {
          setUserRole(false);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setRoleLoading(false);
      }
    }
    currentUserRole();
  }, [user]);

  return (
    <>
      {authLoading && userLoading && roleLoading ? (
        <div>Loading...</div>
      ) : (
        <Layout>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <Home
                  user={user}
                  userAttributes={userAttributes}
                  userRole={userRole}
                />
              }
            />
            <Route exact path="/about" element={<AboutPage />} />
            <Route exact path="/create" element={<CreatePage />} />
            <Route exact path="/detail" element={<DetailPage />} />
            <Route exact path="/login" element={<AuthenticationPage />} />
            <Route exact path="/projects" element={<ProjectListPage />} />
            <Route
              path="/projects/:projectId"
              element={<ProjectDetailModal />}
            />
            <Route exact path="/projects/bid" element={<ProjectBidPage />} />
            <Route path="/dashboard/*" element={<Dashboard />} />
            <Route path="/projects/detail/:id" element={<ProjectDetail />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/payment/success" element={<PaymentSuccess />} />
            <Route path="/payment/cancel" element={<PaymentCancel />} />
            <Route path="*" element={<AuthenticationPage />} />
            {/* <Route path="/dashboard/bids/:id" element={<BidDetail />} /> */}
          </Routes>
        </Layout>
      )}
    </>
  );
};

const App = () => (
  <Authenticator.Provider>
    <Router>
      <AppRouter />
    </Router>
  </Authenticator.Provider>
);

export default App;
