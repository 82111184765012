import React, { useState, useEffect, useContext } from "react";
import { Flex, Alert } from "@aws-amplify/ui-react";
import { uploadData } from "aws-amplify/storage";
import { fetchUserAttributes } from "aws-amplify/auth";
import { generateClient } from "aws-amplify/api";
import ProjectCustomDetail from "../../../ui-components/ProjectCustomDetail";
import ProjectDetailImage from "./projectDetailImage";
import { usStates } from "../bulkData";
import "react-datepicker/dist/react-datepicker.css";
import { createProject } from "../../../graphql/mutations";
import { useNavigate } from "react-router-dom";
import { ProjectPageContext } from "./index";

const DetailPage = ({ selectedItems }) => {
  const client = generateClient();
  const [errorMessage, setErrorMessage] = useState(null);

  const {
    bedroomChecked,
    setBedroomChecked,
    livingRoomChecked,
    setLivingRoomChecked,
    kitchenChecked,
    setKitchenChecked,
    remodelType,
    setRemodelType,
    immediatelyRadio,
    setImmediatelyRadio,
    scheduledRadio,
    setScheduledRadio,
    expectedStartDate,
    setExpectedStartDate,
    projectName,
    setProjectName,
    description,
    setDescription,
    location,
    setLocation,
    phoneNumber,
    setPhoneNumber,
    dialCode,
    setDialCode,
    fullname,
    setFullname,
    email,
    setEmail,
    submitDateTime,
    setSubmitDateTime,
  } = useContext(ProjectPageContext);

  const [isPolicyChecked, setIsPolicyChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userAttributes, setUserAttributes] = useState(null);

  // useEffect(() => {
  //   let checkedRooms = [];
  //   if (bedroomChecked) checkedRooms.push("bedroom");
  //   if (livingRoomChecked) checkedRooms.push("livingroom");
  //   if (kitchenChecked) checkedRooms.push("kitchen");
  //   setRemodelType(checkedRooms);
  // }, [bedroomChecked, livingRoomChecked, kitchenChecked]);

  useEffect(() => {
    let checkedRooms = [];
    selectedItems.map((item) => {
      if (item.category === "bedroom") {
        setBedroomChecked(true);
        checkedRooms.push("bedroom");
      } else if (item.category === "livingroom") {
        setLivingRoomChecked(true);
        checkedRooms.push("livingroom");
      } else if (item.category === "kitchen") {
        setKitchenChecked(true);
        checkedRooms.push("kitchen");
      }
    });
    checkedRooms = [...new Set(checkedRooms)];
    setRemodelType(checkedRooms);
  }, [selectedItems]);

  useEffect(() => {
    async function fetchUser() {
      try {
        const userAttributes = await fetchUserAttributes();
        setUserAttributes(userAttributes);
      } catch (error) {
        setErrorMessage(
          `Error fetching user attributes: ${error.errors[0].message}`
        );
      }
    }
    fetchUser();
  }, [isPolicyChecked]);

  useEffect(() => {
    if (!submitDateTime) {
      setSubmitDateTime(new Date().toISOString());
    }
  }, [submitDateTime]);

  useEffect(() => {}, [immediatelyRadio, scheduledRadio]);

  const uploadImages = async () => {
    const awsImages = [];
    for (const [index, item] of selectedItems.entries()) {
      const awsImage = {
        beforeImage: null,
        afterImage: null,
        roomType: item.category,
        description: item.description,
        idx: item.idx,
        isMain: item.isMain,
        cost: JSON.stringify(item.cost),
      };
      try {
        if (item.file) {
          const beforeResult = await uploadData({
            path: ({ identityId }) =>
              `protected/${identityId}/project/${projectName}_${submitDateTime}/images/${item.idx}_before.jpg`,
            data: item.file,
            options: {
              contentType: "image/jpeg",
            },
          }).result;
          awsImage.beforeImage = beforeResult.path;
        }
        if (item.inpainted) {
          const blob = await fetch(
            item.inpainted[item.inpainted.length - 1]
          ).then((response) => response.blob());
          const afterResult = await uploadData({
            path: ({ identityId }) =>
              `protected/${identityId}/project/${projectName}_${submitDateTime}/images/${item.idx}_after_${index}.jpg`,
            data: blob,
            options: {
              contentType: "image/jpeg",
            },
          }).result;
          awsImage.afterImage = afterResult.path;
        }
        awsImages.push(awsImage);
      } catch (error) {
        setErrorMessage(
          `Error on uploading images: ${error.errors[0].message}`
        );
      }
    }
    return awsImages;
  };

  const uploadPublicImage = async (image) => {
    try {
      const uploadResult = await uploadData({
        path: `public/project_images/${projectName}_${submitDateTime}/${image.idx}_after.jpg`,
        data: image,
        options: {
          contentType: "image/jpeg",
        },
      }).result;
      return uploadResult.path;
    } catch (error) {
      setErrorMessage(
        `Error on uploading public image: ${error.errors[0].message}`
      );
      return null;
    }
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!isPolicyChecked) {
      alert("Please agree to our privacy policy.");
    } else if (!(livingRoomChecked || bedroomChecked || kitchenChecked)) {
      alert("Please check at least one room type.");
    } else {
      const customerId = localStorage.getItem("id");

      const awsImages = await uploadImages();

      const mainItem = selectedItems.find((item) => item.isMain);

      const publicMainImage = await uploadPublicImage(mainItem.file);

      const dialPhoneNumber = `${dialCode} ${phoneNumber}`;
      const projectInput = {
        projectName: projectName,
        projectDescription: description,
        images: awsImages,
        expectedStartDate: expectedStartDate,
        location: location,
        remodelType: remodelType,
        publiclyVisible: true,
        phone: dialPhoneNumber,
        email: email,
        submitDateTime: submitDateTime,
        customerID: customerId,
        status: "OPEN",
        publicImage: publicMainImage,
      };

      try {
        const newProject = await client.graphql({
          query: createProject,
          variables: { input: projectInput },
        });
        alert("Resia Project: Submission succeeded");
        navigate("/dashboard");
      } catch (error) {
        setErrorMessage(
          `Error on creating project: ${error.errors[0].message}`
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const overrides = {
    ProjectDetail: {
      height: "auto",
      as: "form",
      alignItems: "center",
      padding: "10px 10px 10px 10px",
      onSubmit: (e) => handleSubmit(e),
      onKeyPress: (event) => {
        if (event.key === "Enter" && event.target.type !== "textarea") {
          event.preventDefault();
        }
      },
      isDisabled: loading,
    },
    // Project name
    TextField109400: {
      isRequired: true,
      value: projectName,
      onChange: (e) => setProjectName(e.target.value),
    },
    // Remodeling Type
    label92594: { fontFamily: "unset", fontWeight: "400" },
    // Description of project
    TextAreaField: {
      value: description,
      resize: "vertical",
      onChange: (e) => setDescription(e.target.value),
    },
    // Period of Project
    label92601: { fontFamily: "unset", marginTop: "5px" },
    // Street address or P.O. Box
    TextField1111054: {
      isRequired: true,
      value: location.address1,
      onChange: (e) => setLocation({ ...location, address1: e.target.value }),
    },
    // Apt, suite, unit
    TextField1111061: {
      labelHidden: true,
      isRequired: false,
      value: location.address2,
      onChange: (e) => setLocation({ ...location, address2: e.target.value }),
    },
    // City
    TextField1111070: {
      labelHidden: true,
      isRequired: true,
      value: location.city,
      onChange: (e) => setLocation({ ...location, city: e.target.value }),
    },
    // Zip code
    TextField1111077: {
      labelHidden: true,
      isRequired: true,
      value: location.zipCode,
      onChange: (e) =>
        setLocation({ ...location, zipCode: parseInt(e.target.value) }),
    },
    // State
    SelectField: {
      children: usStates.map((state) => (
        <option key={state.value} value={state.value}>
          {state.label}
        </option>
      )),
      onChange: (e) => setLocation({ ...location, state: e.target.value }),
    },
    // datetime picker
    Datepicker: {
      selected: expectedStartDate,
      dateFormat: "yyyy-MM-dd",
      onChange: (date) => {
        setExpectedStartDate(date.toISOString().split("T")[0]);
        if (immediatelyRadio) {
          setImmediatelyRadio(false);
          setScheduledRadio(true);
        }
      },
    },
    // Immediately Radio
    Radio92604: {
      checked: immediatelyRadio,
      onChange: (e) => {
        setImmediatelyRadio(e.target.checked);
        setScheduledRadio(!e.target.checked);
        setExpectedStartDate(new Date().toISOString().split("T")[0]);
      },
    },
    // Scheduled Radio
    Radio92606: {
      checked: scheduledRadio,
      onChange: (e) => {
        setScheduledRadio(e.target.checked);
        setImmediatelyRadio(!e.target.checked);
      },
    },
    // Full name
    TextField921306: {
      isRequired: true,
      value: fullname,
      onChange: (e) => setFullname(e.target.value),
    },
    // Email address
    TextField109414: {
      type: "email",
      value: email,
      onChange: (e) => setEmail(e.target.value),
    },
    // Phone number
    PhoneNumberField: {
      isRequired: true,
      placeholder: "0000000000",
      value: phoneNumber,
      onDialCodeChange: (e) => setDialCode(e.target.value),
      onInput: (e) => setPhoneNumber(e.target.value),
    },
    // Terms and conditions
    CheckboxField1161263: {
      checked: isPolicyChecked,
      onChange: (e) => {
        setIsPolicyChecked(e.target.checked);
        if (e.target.checked) {
          window.open("/privacy-policy", "_blank");
        }
      },
    },
    // Bedroom, living room, kitchen
    CheckboxField1021307: {
      checked: bedroomChecked,
      onChange: (e) => setBedroomChecked(e.target.checked),
    },
    CheckboxField1021315: {
      checked: livingRoomChecked,
      onChange: (e) => setLivingRoomChecked(e.target.checked),
    },
    CheckboxField1021323: {
      checked: kitchenChecked,
      onChange: (e) => setKitchenChecked(e.target.checked),
    },
    Button: {
      type: "submit",
      isLoading: loading,
      isDisabled: loading,
      children: "Post Project",
      loadingText: "Submitting...",
      onSubmit: (e) => handleSubmit(e),
    },
  };

  return (
    <Flex
      direction="column"
      padding="20px"
      gap="20px"
      alignItems="center"
      alignContent="flex-start"
      justifyContent="center"
      wrap="unwrap"
    >
      <ProjectCustomDetail overrides={overrides} />
      {errorMessage && (
        <Alert
          variation="error"
          isDismissible={true}
          hasIcon={true}
          heading="Upload Error"
        >
          Please try again. <br />
          {errorMessage}
        </Alert>
      )}
    </Flex>
  );
};

export default DetailPage;
