/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Autocomplete,
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  ScrollView,
  Text,
  TextAreaField,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getCustomer, listMessages, listProjects } from "../graphql/queries";
import {
  updateCustomer,
  updateMessage,
  updateProject,
} from "../graphql/mutations";
const client = generateClient();
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function CustomerUpdateForm(props) {
  const {
    id: idProp,
    customer: customerModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    name: "",
    email: "",
    Projects: [],
    phone: "",
    location: "",
    photo: "",
    status: "",
    Messages: [],
    paidStatus: "",
    paidStart: "",
    paidEnd: "",
    paidPlan: "",
  };
  const [name, setName] = React.useState(initialValues.name);
  const [email, setEmail] = React.useState(initialValues.email);
  const [Projects, setProjects] = React.useState(initialValues.Projects);
  const [ProjectsLoading, setProjectsLoading] = React.useState(false);
  const [projectsRecords, setProjectsRecords] = React.useState([]);
  const [phone, setPhone] = React.useState(initialValues.phone);
  const [location, setLocation] = React.useState(initialValues.location);
  const [photo, setPhoto] = React.useState(initialValues.photo);
  const [status, setStatus] = React.useState(initialValues.status);
  const [Messages, setMessages] = React.useState(initialValues.Messages);
  const [MessagesLoading, setMessagesLoading] = React.useState(false);
  const [messagesRecords, setMessagesRecords] = React.useState([]);
  const [paidStatus, setPaidStatus] = React.useState(initialValues.paidStatus);
  const [paidStart, setPaidStart] = React.useState(initialValues.paidStart);
  const [paidEnd, setPaidEnd] = React.useState(initialValues.paidEnd);
  const [paidPlan, setPaidPlan] = React.useState(initialValues.paidPlan);
  const autocompleteLength = 10;
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = customerRecord
      ? {
          ...initialValues,
          ...customerRecord,
          Projects: linkedProjects,
          Messages: linkedMessages,
        }
      : initialValues;
    setName(cleanValues.name);
    setEmail(cleanValues.email);
    setProjects(cleanValues.Projects ?? []);
    setCurrentProjectsValue(undefined);
    setCurrentProjectsDisplayValue("");
    setPhone(cleanValues.phone);
    setLocation(
      typeof cleanValues.location === "string" || cleanValues.location === null
        ? cleanValues.location
        : JSON.stringify(cleanValues.location)
    );
    setPhoto(cleanValues.photo);
    setStatus(cleanValues.status);
    setMessages(cleanValues.Messages ?? []);
    setCurrentMessagesValue(undefined);
    setCurrentMessagesDisplayValue("");
    setPaidStatus(cleanValues.paidStatus);
    setPaidStart(cleanValues.paidStart);
    setPaidEnd(cleanValues.paidEnd);
    setPaidPlan(cleanValues.paidPlan);
    setErrors({});
  };
  const [customerRecord, setCustomerRecord] = React.useState(customerModelProp);
  const [linkedProjects, setLinkedProjects] = React.useState([]);
  const canUnlinkProjects = false;
  const [linkedMessages, setLinkedMessages] = React.useState([]);
  const canUnlinkMessages = false;
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getCustomer.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getCustomer
        : customerModelProp;
      const linkedProjects = record?.Projects?.items ?? [];
      setLinkedProjects(linkedProjects);
      const linkedMessages = record?.Messages?.items ?? [];
      setLinkedMessages(linkedMessages);
      setCustomerRecord(record);
    };
    queryData();
  }, [idProp, customerModelProp]);
  React.useEffect(resetStateValues, [
    customerRecord,
    linkedProjects,
    linkedMessages,
  ]);
  const [currentProjectsDisplayValue, setCurrentProjectsDisplayValue] =
    React.useState("");
  const [currentProjectsValue, setCurrentProjectsValue] =
    React.useState(undefined);
  const ProjectsRef = React.createRef();
  const [currentMessagesDisplayValue, setCurrentMessagesDisplayValue] =
    React.useState("");
  const [currentMessagesValue, setCurrentMessagesValue] =
    React.useState(undefined);
  const MessagesRef = React.createRef();
  const getIDValue = {
    Projects: (r) => JSON.stringify({ id: r?.id }),
    Messages: (r) => JSON.stringify({ id: r?.id }),
  };
  const ProjectsIdSet = new Set(
    Array.isArray(Projects)
      ? Projects.map((r) => getIDValue.Projects?.(r))
      : getIDValue.Projects?.(Projects)
  );
  const MessagesIdSet = new Set(
    Array.isArray(Messages)
      ? Messages.map((r) => getIDValue.Messages?.(r))
      : getIDValue.Messages?.(Messages)
  );
  const getDisplayValue = {
    Projects: (r) => `${r?.projectName ? r?.projectName + " - " : ""}${r?.id}`,
    Messages: (r) => `${r?.content ? r?.content + " - " : ""}${r?.id}`,
  };
  const validations = {
    name: [],
    email: [{ type: "Email" }],
    Projects: [],
    phone: [{ type: "Phone" }],
    location: [{ type: "JSON" }],
    photo: [],
    status: [],
    Messages: [],
    paidStatus: [],
    paidStart: [],
    paidEnd: [],
    paidPlan: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  const fetchProjectsRecords = async (value) => {
    setProjectsLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [
            { projectName: { contains: value } },
            { id: { contains: value } },
          ],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listProjects.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listProjects?.items;
      var loaded = result.filter(
        (item) => !ProjectsIdSet.has(getIDValue.Projects?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setProjectsRecords(newOptions.slice(0, autocompleteLength));
    setProjectsLoading(false);
  };
  const fetchMessagesRecords = async (value) => {
    setMessagesLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ content: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listMessages.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listMessages?.items;
      var loaded = result.filter(
        (item) => !MessagesIdSet.has(getIDValue.Messages?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setMessagesRecords(newOptions.slice(0, autocompleteLength));
    setMessagesLoading(false);
  };
  React.useEffect(() => {
    fetchProjectsRecords("");
    fetchMessagesRecords("");
  }, []);
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          name: name ?? null,
          email: email ?? null,
          Projects: Projects ?? null,
          phone: phone ?? null,
          location: location ?? null,
          photo: photo ?? null,
          status: status ?? null,
          Messages: Messages ?? null,
          paidStatus: paidStatus ?? null,
          paidStart: paidStart ?? null,
          paidEnd: paidEnd ?? null,
          paidPlan: paidPlan ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(
                    fieldName,
                    item,
                    getDisplayValue[fieldName]
                  )
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(
                fieldName,
                modelFields[fieldName],
                getDisplayValue[fieldName]
              )
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          const promises = [];
          const projectsToLink = [];
          const projectsToUnLink = [];
          const projectsSet = new Set();
          const linkedProjectsSet = new Set();
          Projects.forEach((r) => projectsSet.add(getIDValue.Projects?.(r)));
          linkedProjects.forEach((r) =>
            linkedProjectsSet.add(getIDValue.Projects?.(r))
          );
          linkedProjects.forEach((r) => {
            if (!projectsSet.has(getIDValue.Projects?.(r))) {
              projectsToUnLink.push(r);
            }
          });
          Projects.forEach((r) => {
            if (!linkedProjectsSet.has(getIDValue.Projects?.(r))) {
              projectsToLink.push(r);
            }
          });
          projectsToUnLink.forEach((original) => {
            if (!canUnlinkProjects) {
              throw Error(
                `Project ${original.id} cannot be unlinked from Customer because customerID is a required field.`
              );
            }
            promises.push(
              client.graphql({
                query: updateProject.replaceAll("__typename", ""),
                variables: {
                  input: {
                    id: original.id,
                    customerID: null,
                  },
                },
              })
            );
          });
          projectsToLink.forEach((original) => {
            promises.push(
              client.graphql({
                query: updateProject.replaceAll("__typename", ""),
                variables: {
                  input: {
                    id: original.id,
                    customerID: customerRecord.id,
                  },
                },
              })
            );
          });
          const messagesToLink = [];
          const messagesToUnLink = [];
          const messagesSet = new Set();
          const linkedMessagesSet = new Set();
          Messages.forEach((r) => messagesSet.add(getIDValue.Messages?.(r)));
          linkedMessages.forEach((r) =>
            linkedMessagesSet.add(getIDValue.Messages?.(r))
          );
          linkedMessages.forEach((r) => {
            if (!messagesSet.has(getIDValue.Messages?.(r))) {
              messagesToUnLink.push(r);
            }
          });
          Messages.forEach((r) => {
            if (!linkedMessagesSet.has(getIDValue.Messages?.(r))) {
              messagesToLink.push(r);
            }
          });
          messagesToUnLink.forEach((original) => {
            if (!canUnlinkMessages) {
              throw Error(
                `Message ${original.id} cannot be unlinked from Customer because customerID is a required field.`
              );
            }
            promises.push(
              client.graphql({
                query: updateMessage.replaceAll("__typename", ""),
                variables: {
                  input: {
                    id: original.id,
                    customerID: null,
                  },
                },
              })
            );
          });
          messagesToLink.forEach((original) => {
            promises.push(
              client.graphql({
                query: updateMessage.replaceAll("__typename", ""),
                variables: {
                  input: {
                    id: original.id,
                    customerID: customerRecord.id,
                  },
                },
              })
            );
          });
          const modelFieldsToSave = {
            name: modelFields.name ?? null,
            email: modelFields.email ?? null,
            phone: modelFields.phone ?? null,
            photo: modelFields.photo ?? null,
            status: modelFields.status ?? null,
            paidStatus: modelFields.paidStatus ?? null,
            paidStart: modelFields.paidStart ?? null,
            paidEnd: modelFields.paidEnd ?? null,
            paidPlan: modelFields.paidPlan ?? null,
            location: modelFields.location
              ? JSON.parse(modelFields.location)
              : modelFields.location,
          };
          promises.push(
            client.graphql({
              query: updateCustomer.replaceAll("__typename", ""),
              variables: {
                input: {
                  id: customerRecord.id,
                  ...modelFieldsToSave,
                },
              },
            })
          );
          await Promise.all(promises);
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "CustomerUpdateForm")}
      {...rest}
    >
      <TextField
        label="Name"
        isRequired={false}
        isReadOnly={false}
        value={name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name: value,
              email,
              Projects,
              phone,
              location,
              photo,
              status,
              Messages,
              paidStatus,
              paidStart,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks("name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("name", name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        {...getOverrideProps(overrides, "name")}
      ></TextField>
      <TextField
        label="Email"
        isRequired={false}
        isReadOnly={false}
        value={email}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              email: value,
              Projects,
              phone,
              location,
              photo,
              status,
              Messages,
              paidStatus,
              paidStart,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            value = result?.email ?? value;
          }
          if (errors.email?.hasError) {
            runValidationTasks("email", value);
          }
          setEmail(value);
        }}
        onBlur={() => runValidationTasks("email", email)}
        errorMessage={errors.email?.errorMessage}
        hasError={errors.email?.hasError}
        {...getOverrideProps(overrides, "email")}
      ></TextField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              name,
              email,
              Projects: values,
              phone,
              location,
              photo,
              status,
              Messages,
              paidStatus,
              paidStart,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            values = result?.Projects ?? values;
          }
          setProjects(values);
          setCurrentProjectsValue(undefined);
          setCurrentProjectsDisplayValue("");
        }}
        currentFieldValue={currentProjectsValue}
        label={"Projects"}
        items={Projects}
        hasError={errors?.Projects?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("Projects", currentProjectsValue)
        }
        errorMessage={errors?.Projects?.errorMessage}
        getBadgeText={getDisplayValue.Projects}
        setFieldValue={(model) => {
          setCurrentProjectsDisplayValue(
            model ? getDisplayValue.Projects(model) : ""
          );
          setCurrentProjectsValue(model);
        }}
        inputFieldRef={ProjectsRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Projects"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Project"
          value={currentProjectsDisplayValue}
          options={projectsRecords
            .filter((r) => !ProjectsIdSet.has(getIDValue.Projects?.(r)))
            .map((r) => ({
              id: getIDValue.Projects?.(r),
              label: getDisplayValue.Projects?.(r),
            }))}
          isLoading={ProjectsLoading}
          onSelect={({ id, label }) => {
            setCurrentProjectsValue(
              projectsRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentProjectsDisplayValue(label);
            runValidationTasks("Projects", label);
          }}
          onClear={() => {
            setCurrentProjectsDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchProjectsRecords(value);
            if (errors.Projects?.hasError) {
              runValidationTasks("Projects", value);
            }
            setCurrentProjectsDisplayValue(value);
            setCurrentProjectsValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks("Projects", currentProjectsDisplayValue)
          }
          errorMessage={errors.Projects?.errorMessage}
          hasError={errors.Projects?.hasError}
          ref={ProjectsRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "Projects")}
        ></Autocomplete>
      </ArrayField>
      <TextField
        label="Phone"
        isRequired={false}
        isReadOnly={false}
        type="tel"
        value={phone}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              email,
              Projects,
              phone: value,
              location,
              photo,
              status,
              Messages,
              paidStatus,
              paidStart,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            value = result?.phone ?? value;
          }
          if (errors.phone?.hasError) {
            runValidationTasks("phone", value);
          }
          setPhone(value);
        }}
        onBlur={() => runValidationTasks("phone", phone)}
        errorMessage={errors.phone?.errorMessage}
        hasError={errors.phone?.hasError}
        {...getOverrideProps(overrides, "phone")}
      ></TextField>
      <TextAreaField
        label="Location"
        isRequired={false}
        isReadOnly={false}
        value={location}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              email,
              Projects,
              phone,
              location: value,
              photo,
              status,
              Messages,
              paidStatus,
              paidStart,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            value = result?.location ?? value;
          }
          if (errors.location?.hasError) {
            runValidationTasks("location", value);
          }
          setLocation(value);
        }}
        onBlur={() => runValidationTasks("location", location)}
        errorMessage={errors.location?.errorMessage}
        hasError={errors.location?.hasError}
        {...getOverrideProps(overrides, "location")}
      ></TextAreaField>
      <TextField
        label="Photo"
        isRequired={false}
        isReadOnly={false}
        value={photo}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              email,
              Projects,
              phone,
              location,
              photo: value,
              status,
              Messages,
              paidStatus,
              paidStart,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            value = result?.photo ?? value;
          }
          if (errors.photo?.hasError) {
            runValidationTasks("photo", value);
          }
          setPhoto(value);
        }}
        onBlur={() => runValidationTasks("photo", photo)}
        errorMessage={errors.photo?.errorMessage}
        hasError={errors.photo?.hasError}
        {...getOverrideProps(overrides, "photo")}
      ></TextField>
      <TextField
        label="Status"
        isRequired={false}
        isReadOnly={false}
        value={status}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              email,
              Projects,
              phone,
              location,
              photo,
              status: value,
              Messages,
              paidStatus,
              paidStart,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            value = result?.status ?? value;
          }
          if (errors.status?.hasError) {
            runValidationTasks("status", value);
          }
          setStatus(value);
        }}
        onBlur={() => runValidationTasks("status", status)}
        errorMessage={errors.status?.errorMessage}
        hasError={errors.status?.hasError}
        {...getOverrideProps(overrides, "status")}
      ></TextField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              name,
              email,
              Projects,
              phone,
              location,
              photo,
              status,
              Messages: values,
              paidStatus,
              paidStart,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            values = result?.Messages ?? values;
          }
          setMessages(values);
          setCurrentMessagesValue(undefined);
          setCurrentMessagesDisplayValue("");
        }}
        currentFieldValue={currentMessagesValue}
        label={"Messages"}
        items={Messages}
        hasError={errors?.Messages?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("Messages", currentMessagesValue)
        }
        errorMessage={errors?.Messages?.errorMessage}
        getBadgeText={getDisplayValue.Messages}
        setFieldValue={(model) => {
          setCurrentMessagesDisplayValue(
            model ? getDisplayValue.Messages(model) : ""
          );
          setCurrentMessagesValue(model);
        }}
        inputFieldRef={MessagesRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Messages"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Message"
          value={currentMessagesDisplayValue}
          options={messagesRecords
            .filter((r) => !MessagesIdSet.has(getIDValue.Messages?.(r)))
            .map((r) => ({
              id: getIDValue.Messages?.(r),
              label: getDisplayValue.Messages?.(r),
            }))}
          isLoading={MessagesLoading}
          onSelect={({ id, label }) => {
            setCurrentMessagesValue(
              messagesRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentMessagesDisplayValue(label);
            runValidationTasks("Messages", label);
          }}
          onClear={() => {
            setCurrentMessagesDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchMessagesRecords(value);
            if (errors.Messages?.hasError) {
              runValidationTasks("Messages", value);
            }
            setCurrentMessagesDisplayValue(value);
            setCurrentMessagesValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks("Messages", currentMessagesDisplayValue)
          }
          errorMessage={errors.Messages?.errorMessage}
          hasError={errors.Messages?.hasError}
          ref={MessagesRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "Messages")}
        ></Autocomplete>
      </ArrayField>
      <TextField
        label="Paid status"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={paidStatus}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              name,
              email,
              Projects,
              phone,
              location,
              photo,
              status,
              Messages,
              paidStatus: value,
              paidStart,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            value = result?.paidStatus ?? value;
          }
          if (errors.paidStatus?.hasError) {
            runValidationTasks("paidStatus", value);
          }
          setPaidStatus(value);
        }}
        onBlur={() => runValidationTasks("paidStatus", paidStatus)}
        errorMessage={errors.paidStatus?.errorMessage}
        hasError={errors.paidStatus?.hasError}
        {...getOverrideProps(overrides, "paidStatus")}
      ></TextField>
      <TextField
        label="Paid start"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={paidStart}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              email,
              Projects,
              phone,
              location,
              photo,
              status,
              Messages,
              paidStatus,
              paidStart: value,
              paidEnd,
              paidPlan,
            };
            const result = onChange(modelFields);
            value = result?.paidStart ?? value;
          }
          if (errors.paidStart?.hasError) {
            runValidationTasks("paidStart", value);
          }
          setPaidStart(value);
        }}
        onBlur={() => runValidationTasks("paidStart", paidStart)}
        errorMessage={errors.paidStart?.errorMessage}
        hasError={errors.paidStart?.hasError}
        {...getOverrideProps(overrides, "paidStart")}
      ></TextField>
      <TextField
        label="Paid end"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={paidEnd}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              email,
              Projects,
              phone,
              location,
              photo,
              status,
              Messages,
              paidStatus,
              paidStart,
              paidEnd: value,
              paidPlan,
            };
            const result = onChange(modelFields);
            value = result?.paidEnd ?? value;
          }
          if (errors.paidEnd?.hasError) {
            runValidationTasks("paidEnd", value);
          }
          setPaidEnd(value);
        }}
        onBlur={() => runValidationTasks("paidEnd", paidEnd)}
        errorMessage={errors.paidEnd?.errorMessage}
        hasError={errors.paidEnd?.hasError}
        {...getOverrideProps(overrides, "paidEnd")}
      ></TextField>
      <TextField
        label="Paid plan"
        isRequired={false}
        isReadOnly={false}
        value={paidPlan}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              email,
              Projects,
              phone,
              location,
              photo,
              status,
              Messages,
              paidStatus,
              paidStart,
              paidEnd,
              paidPlan: value,
            };
            const result = onChange(modelFields);
            value = result?.paidPlan ?? value;
          }
          if (errors.paidPlan?.hasError) {
            runValidationTasks("paidPlan", value);
          }
          setPaidPlan(value);
        }}
        onBlur={() => runValidationTasks("paidPlan", paidPlan)}
        errorMessage={errors.paidPlan?.errorMessage}
        hasError={errors.paidPlan?.hasError}
        {...getOverrideProps(overrides, "paidPlan")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || customerModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || customerModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
