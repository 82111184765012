import React from "react";
import {
  Card,
  Flex,
  Text,
  Image,
  Heading,
  Collection,
  Divider,
  Button,
  View,
} from "@aws-amplify/ui-react";
import { AboutResia, AboutPageBackground } from "../../imagepath";

const AboutPage = () => {
  const items = [
    {
      title: "About Resia",
      description: `Resia simplifies home renovations by combining collaborative AI
            design tools, cost estimation, and contractor matchmaking. We help
            homeowners plan and manage their renovation projects from design
            through completion, ensuring they can connect with the right
            contractors and stay within budget.`,
      imageUrl: AboutResia,
    },
    {
      title: "Our Mission",
      description: `We believe everyone deserves a beautiful home. Resia helps you
            communicate your ideas clearly, manage costs, and ensure the right
            materials are used. We support your renovation from start to finish.`,
      imageUrl: AboutResia,
    },
  ];

  return (
    <Flex
      direction="column"
      alignItems="center"
      // justifyContent="center"
      padding="20px"
      gap="0"
      // marginTop="40px"
      backgroundColor="transparent"
      // backgroundImage={AboutPageBackground}
      style={{
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url(${AboutPageBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <Image
        width="500px"
        src={AboutResia}
        alt={"Resia"}
        marginBottom="10px"
        justifyContent="center"
        alignItems="center"
      />
      <div className="animated-gradient-text">
        We simplify your home renovations
      </div>
      <Card maxWidth="610px" backgroundColor="transparent">
        <Text fontSize="1.2rem" textAlign="start">
          {items[0].description}
        </Text>
      </Card>
      <Card maxWidth="625px" backgroundColor="transparent">
        <div className="animated-gradient-text">{items[1].title}</div>
        <Text fontSize="1.2rem" textAlign="start">
          {items[1].description}
        </Text>
      </Card>
    </Flex>
  );
};

export default AboutPage;
