import { useState, useEffect } from "react";
import {
  Flex,
  Text,
  TextField,
  TextAreaField,
  Card,
} from "@aws-amplify/ui-react";
import { getUrl } from "aws-amplify/storage";
import ImageGallery from "react-image-gallery";

const ProjectDetail = ({ projectItem }) => {
  const [imageItems, setImageItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const getImages = async () => {
    const images = await Promise.all(
      projectItem.images.flatMap(async (image) => {
        const beforeImageUrl = image.beforeImage
          ? await getUrl({ path: image.beforeImage })
          : null;
        const afterImageUrl = image.afterImage
          ? await getUrl({ path: image.afterImage })
          : null;
        if (afterImageUrl === null) {
          return [
            {
              original: beforeImageUrl.url,
              thumbnail: beforeImageUrl.url,
              // description: image.description,
            },
          ];
        } else {
          return [
            {
              original: afterImageUrl.url,
              thumbnail: afterImageUrl.url,
              // description: image.description,
            },
          ];
        }
      })
    );
    return images.filter((image) => image !== undefined);
  };

  useEffect(() => {
    getImages().then((images) => {
      setImageItems(images.flat());
    });
  }, []);

  return (
    <Flex
      direction="column"
      gap="1rem"
      padding="20px 10px 20px 10px"
      alignItems="center"
    >
      <Flex direction="column" gap="0" alignItems="center" width="100%">
        <Text fontSize="1rem" fontWeight="bold" marginBottom="5px">
          Project Information
        </Text>
        <Flex direction="column" gap="10px" width="540px">
          <TextField
            label="Project Name"
            value={projectItem.projectName}
            isReadOnly={true}
          />
          <TextAreaField
            label="Project Description"
            value={projectItem.projectDescription}
            isReadOnly={true}
          />
          <TextField
            label="Room Type"
            value={projectItem.remodelType.join(", ")}
            isReadOnly={true}
          />
          <TextField
            label="Location"
            value={`${projectItem.location.city}, ${projectItem.location.state}`}
            isReadOnly={true}
          />
          <TextField
            label="Expected Start Date"
            value={new Date(projectItem.expectedStartDate).toDateString()}
            isReadOnly={true}
          />
        </Flex>
      </Flex>
      <Card width="80%">
        <ImageGallery
          items={imageItems.map(({ description, ...item }) => item)}
          onSlide={setCurrentIndex}
          disableKeyDown={true}
          showPlayButton={false}
        />
      </Card>
    </Flex>
  );
};

export default ProjectDetail;
