/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEmailSubscription = /* GraphQL */ `
  query GetEmailSubscription($id: ID!) {
    getEmailSubscription(id: $id) {
      id
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEmailSubscriptions = /* GraphQL */ `
  query ListEmailSubscriptions(
    $filter: ModelEmailSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmailSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTaskProject = /* GraphQL */ `
  query GetTaskProject($id: ID!) {
    getTaskProject(id: $id) {
      id
      name
      Tasks {
        items {
          id
          title
          description
          status
          startDate
          endDate
          progress
          taskprojectID
          createdAt
          updatedAt
          taskProjectId
          taskContractorId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTaskProjects = /* GraphQL */ `
  query ListTaskProjects(
    $filter: ModelTaskProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        Tasks {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBid = /* GraphQL */ `
  query GetBid($id: ID!) {
    getBid(id: $id) {
      id
      title
      amount
      message
      startDate
      endDate
      description
      files
      status
      contractorID
      projectID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBids = /* GraphQL */ `
  query ListBids(
    $filter: ModelBidFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBids(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        amount
        message
        startDate
        endDate
        description
        files
        status
        contractorID
        projectID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bidsByContractorID = /* GraphQL */ `
  query BidsByContractorID(
    $contractorID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBidFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bidsByContractorID(
      contractorID: $contractorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        amount
        message
        startDate
        endDate
        description
        files
        status
        contractorID
        projectID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bidsByProjectID = /* GraphQL */ `
  query BidsByProjectID(
    $projectID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBidFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bidsByProjectID(
      projectID: $projectID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        amount
        message
        startDate
        endDate
        description
        files
        status
        contractorID
        projectID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      role
      userId
      roleId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        role
        userId
        roleId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      rating
      comment
      Contractor {
        id
        companyName
        website
        socialNetworkLinks
        expertises
        portfolio
        PortfolioItems {
          nextToken
          __typename
        }
        phone
        email
        location {
          city
          state
          address1
          address2
          zipCode
          __typename
        }
        photo
        name
        status
        Bids {
          nextToken
          __typename
        }
        Messages {
          nextToken
          __typename
        }
        paidStatus
        paidStart
        paidEnd
        paidPlan
        createdAt
        updatedAt
        __typename
      }
      Project {
        id
        projectName
        projectDescription
        images {
          afterImage
          beforeImage
          isMain
          roomType
          idx
          description
          cost
          __typename
        }
        expectedStartDate
        location {
          city
          state
          address1
          address2
          zipCode
          __typename
        }
        remodelType
        publiclyVisible
        customerID
        phone
        email
        submitDateTime
        status
        publicImage
        Bids {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      reviewContractorId
      reviewProjectId
      __typename
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rating
        comment
        Contractor {
          id
          companyName
          website
          socialNetworkLinks
          expertises
          portfolio
          phone
          email
          photo
          name
          status
          paidStatus
          paidStart
          paidEnd
          paidPlan
          createdAt
          updatedAt
          __typename
        }
        Project {
          id
          projectName
          projectDescription
          expectedStartDate
          remodelType
          publiclyVisible
          customerID
          phone
          email
          submitDateTime
          status
          publicImage
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        reviewContractorId
        reviewProjectId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      title
      description
      status
      startDate
      endDate
      Project {
        id
        projectName
        projectDescription
        images {
          afterImage
          beforeImage
          isMain
          roomType
          idx
          description
          cost
          __typename
        }
        expectedStartDate
        location {
          city
          state
          address1
          address2
          zipCode
          __typename
        }
        remodelType
        publiclyVisible
        customerID
        phone
        email
        submitDateTime
        status
        publicImage
        Bids {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Contractor {
        id
        companyName
        website
        socialNetworkLinks
        expertises
        portfolio
        PortfolioItems {
          nextToken
          __typename
        }
        phone
        email
        location {
          city
          state
          address1
          address2
          zipCode
          __typename
        }
        photo
        name
        status
        Bids {
          nextToken
          __typename
        }
        Messages {
          nextToken
          __typename
        }
        paidStatus
        paidStart
        paidEnd
        paidPlan
        createdAt
        updatedAt
        __typename
      }
      progress
      taskprojectID
      createdAt
      updatedAt
      taskProjectId
      taskContractorId
      __typename
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        status
        startDate
        endDate
        Project {
          id
          projectName
          projectDescription
          expectedStartDate
          remodelType
          publiclyVisible
          customerID
          phone
          email
          submitDateTime
          status
          publicImage
          createdAt
          updatedAt
          __typename
        }
        Contractor {
          id
          companyName
          website
          socialNetworkLinks
          expertises
          portfolio
          phone
          email
          photo
          name
          status
          paidStatus
          paidStart
          paidEnd
          paidPlan
          createdAt
          updatedAt
          __typename
        }
        progress
        taskprojectID
        createdAt
        updatedAt
        taskProjectId
        taskContractorId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tasksByTaskprojectID = /* GraphQL */ `
  query TasksByTaskprojectID(
    $taskprojectID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tasksByTaskprojectID(
      taskprojectID: $taskprojectID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        status
        startDate
        endDate
        Project {
          id
          projectName
          projectDescription
          expectedStartDate
          remodelType
          publiclyVisible
          customerID
          phone
          email
          submitDateTime
          status
          publicImage
          createdAt
          updatedAt
          __typename
        }
        Contractor {
          id
          companyName
          website
          socialNetworkLinks
          expertises
          portfolio
          phone
          email
          photo
          name
          status
          paidStatus
          paidStart
          paidEnd
          paidPlan
          createdAt
          updatedAt
          __typename
        }
        progress
        taskprojectID
        createdAt
        updatedAt
        taskProjectId
        taskContractorId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      name
      email
      Projects {
        items {
          id
          projectName
          projectDescription
          expectedStartDate
          remodelType
          publiclyVisible
          customerID
          phone
          email
          submitDateTime
          status
          publicImage
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      phone
      location {
        city
        state
        address1
        address2
        zipCode
        __typename
      }
      photo
      status
      Messages {
        items {
          id
          content
          createdAt
          customerRead
          contractorRead
          customerID
          contractorID
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      paidStatus
      paidStart
      paidEnd
      paidPlan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        Projects {
          nextToken
          __typename
        }
        phone
        location {
          city
          state
          address1
          address2
          zipCode
          __typename
        }
        photo
        status
        Messages {
          nextToken
          __typename
        }
        paidStatus
        paidStart
        paidEnd
        paidPlan
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPortfolioItem = /* GraphQL */ `
  query GetPortfolioItem($id: ID!) {
    getPortfolioItem(id: $id) {
      id
      contractorID
      projectName
      images
      description
      dateCompleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPortfolioItems = /* GraphQL */ `
  query ListPortfolioItems(
    $filter: ModelPortfolioItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPortfolioItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        contractorID
        projectName
        images
        description
        dateCompleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const portfolioItemsByContractorID = /* GraphQL */ `
  query PortfolioItemsByContractorID(
    $contractorID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPortfolioItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    portfolioItemsByContractorID(
      contractorID: $contractorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contractorID
        projectName
        images
        description
        dateCompleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContractor = /* GraphQL */ `
  query GetContractor($id: ID!) {
    getContractor(id: $id) {
      id
      companyName
      website
      socialNetworkLinks
      expertises
      portfolio
      PortfolioItems {
        items {
          id
          contractorID
          projectName
          images
          description
          dateCompleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      phone
      email
      location {
        city
        state
        address1
        address2
        zipCode
        __typename
      }
      photo
      name
      status
      Bids {
        items {
          id
          title
          amount
          message
          startDate
          endDate
          description
          files
          status
          contractorID
          projectID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Messages {
        items {
          id
          content
          createdAt
          customerRead
          contractorRead
          customerID
          contractorID
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      paidStatus
      paidStart
      paidEnd
      paidPlan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContractors = /* GraphQL */ `
  query ListContractors(
    $filter: ModelContractorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContractors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyName
        website
        socialNetworkLinks
        expertises
        portfolio
        PortfolioItems {
          nextToken
          __typename
        }
        phone
        email
        location {
          city
          state
          address1
          address2
          zipCode
          __typename
        }
        photo
        name
        status
        Bids {
          nextToken
          __typename
        }
        Messages {
          nextToken
          __typename
        }
        paidStatus
        paidStart
        paidEnd
        paidPlan
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      projectName
      projectDescription
      images {
        afterImage
        beforeImage
        isMain
        roomType
        idx
        description
        cost
        __typename
      }
      expectedStartDate
      location {
        city
        state
        address1
        address2
        zipCode
        __typename
      }
      remodelType
      publiclyVisible
      customerID
      phone
      email
      submitDateTime
      status
      publicImage
      Bids {
        items {
          id
          title
          amount
          message
          startDate
          endDate
          description
          files
          status
          contractorID
          projectID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectName
        projectDescription
        images {
          afterImage
          beforeImage
          isMain
          roomType
          idx
          description
          cost
          __typename
        }
        expectedStartDate
        location {
          city
          state
          address1
          address2
          zipCode
          __typename
        }
        remodelType
        publiclyVisible
        customerID
        phone
        email
        submitDateTime
        status
        publicImage
        Bids {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const projectsByCustomerID = /* GraphQL */ `
  query ProjectsByCustomerID(
    $customerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectsByCustomerID(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectName
        projectDescription
        images {
          afterImage
          beforeImage
          isMain
          roomType
          idx
          description
          cost
          __typename
        }
        expectedStartDate
        location {
          city
          state
          address1
          address2
          zipCode
          __typename
        }
        remodelType
        publiclyVisible
        customerID
        phone
        email
        submitDateTime
        status
        publicImage
        Bids {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      content
      createdAt
      customerRead
      contractorRead
      customerID
      contractorID
      updatedAt
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        createdAt
        customerRead
        contractorRead
        customerID
        contractorID
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesByCustomerID = /* GraphQL */ `
  query MessagesByCustomerID(
    $customerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByCustomerID(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        createdAt
        customerRead
        contractorRead
        customerID
        contractorID
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesByContractorID = /* GraphQL */ `
  query MessagesByContractorID(
    $contractorID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByContractorID(
      contractorID: $contractorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        createdAt
        customerRead
        contractorRead
        customerID
        contractorID
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
