/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import {
  Button,
  CheckboxField,
  Flex,
  PhoneNumberField,
  Radio,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import DatePicker from "react-datepicker";
export default function ProjectDetail(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="5px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      borderRadius="8px"
      padding="0px 1px 0px 1px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "ProjectDetail")}
      {...rest}
    >
      <TextField
        width="590px"
        height="unset"
        label="Project Name"
        shrink="0"
        placeholder=""
        size="default"
        isDisabled={false}
        labelHidden={false}
        variation="default"
        {...getOverrideProps(overrides, "TextField109400")}
      ></TextField>
      <Flex
        gap="0"
        direction="column"
        width="592px"
        height="76px"
        justifyContent="flex-start"
        alignItems="flex-start"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="10px 0px 10px 0px"
        {...getOverrideProps(overrides, "RemodelingType")}
      >
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="700"
          color="rgba(48,64,80,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Remodeling Type"
          {...getOverrideProps(overrides, "label92594")}
        ></Text>
        <Flex
          gap="30px"
          direction="row"
          width="592px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="10px 0px 10px 0px"
          {...getOverrideProps(overrides, "RemodelingTypeCheckBox92595")}
        >
          <CheckboxField
            width="unset"
            height="unset"
            label="Bedroom"
            shrink="0"
            size="default"
            defaultChecked={true}
            isDisabled={false}
            labelPosition="end"
            {...getOverrideProps(overrides, "CheckboxField1021307")}
          ></CheckboxField>
          <CheckboxField
            width="unset"
            height="unset"
            label="Living Room"
            shrink="0"
            size="default"
            defaultChecked={false}
            isDisabled={false}
            labelPosition="end"
            {...getOverrideProps(overrides, "CheckboxField1021315")}
          ></CheckboxField>
          <CheckboxField
            width="unset"
            height="unset"
            label="Kitchen"
            shrink="0"
            size="default"
            defaultChecked={false}
            isDisabled={false}
            labelPosition="end"
            {...getOverrideProps(overrides, "CheckboxField1021323")}
          ></CheckboxField>
        </Flex>
      </Flex>
      <TextAreaField
        width="590px"
        height="unset"
        label="Write Description of Project"
        shrink="0"
        size="default"
        isDisabled={false}
        labelHidden={false}
        variation="default"
        {...getOverrideProps(overrides, "TextAreaField")}
      ></TextAreaField>
      <Flex
        gap="0"
        direction="column"
        width="592px"
        height="78px"
        justifyContent="center"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "TextField92600")}
      >
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="700"
          color="rgba(48,64,80,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Period of Project"
          {...getOverrideProps(overrides, "label92601")}
        ></Text>
        <Flex
          gap="10px"
          direction="row"
          width="592px"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          overflow="visible"
          shrink="0"
          position="relative"
          padding="10px 0px 10px 0px"
          {...getOverrideProps(overrides, "RemodelingTypeCheckBox92603")}
        >
          <Radio
            width="unset"
            height="unset"
            shrink="0"
            size="default"
            // defaultChecked={true}
            isDisabled={false}
            labelPosition="end"
            children="Start immediately"
            {...getOverrideProps(overrides, "Radio92604")}
          ></Radio>
          <Flex
            gap="10px"
            direction="row"
            width="auto"
            height="auto"
            justifyContent="flex-start"
            alignItems="center"
            overflow="visible"
            shrink="0"
            position="relative"
            padding="1px 1px 1px 1px"
            {...getOverrideProps(overrides, "Frame 446")}
          >
            <Radio
              width="unset"
              height="unset"
              shrink="0"
              size="default"
              // defaultChecked={false}
              isDisabled={false}
              labelPosition="end"
              children="Start on"
              {...getOverrideProps(overrides, "Radio92606")}
            ></Radio>
            {/* <Flex
              gap="10px"
              direction="column"
              width="155px"
              height="37px"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              border="1px SOLID rgba(220,222,224,1)"
              borderRadius="3px"
              padding="1px 1px 1px 1px"
              {...getOverrideProps(overrides, "Input")}
            > */}
            <DatePicker
              // dateFormat="dd/MM/yyyy"
              selected={new Date()}
              {...getOverrideProps(overrides, "Datepicker")}
            />
            {/* <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(128,128,128,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children=""
                {...getOverrideProps(overrides, "placeholder")}
              ></Text> */}
            {/* </Flex> */}
          </Flex>
        </Flex>
      </Flex>
      <TextField
        width="590px"
        height="unset"
        label="Full name"
        shrink="0"
        placeholder=""
        size="default"
        isDisabled={false}
        labelHidden={false}
        variation="default"
        {...getOverrideProps(overrides, "TextField921306")}
      ></TextField>
      <TextField
        width="590px"
        height="unset"
        label="Email address"
        shrink="0"
        placeholder=""
        size="default"
        isDisabled={false}
        labelHidden={false}
        variation="default"
        {...getOverrideProps(overrides, "TextField109414")}
      ></TextField>
      <PhoneNumberField
        width="590px"
        height="72px"
        label="Phone"
        shrink="0"
        placeholder="Placeholder"
        size="default"
        isDisabled={false}
        labelHidden={false}
        variation="default"
        {...getOverrideProps(overrides, "PhoneNumberField")}
      ></PhoneNumberField>
      <Flex
        gap="10px"
        direction="column"
        width="590px"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="1px 0px 1px 0px"
        {...getOverrideProps(overrides, "Frame 4393")}
      >
        <TextField
          width="590px"
          height="unset"
          label="Remodeling Location"
          placeholder="Street address or P.O. Box"
          padding="0px 1px 0px 1px"
          shrink="0"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "TextField1111054")}
        ></TextField>
        <TextField
          width="590px"
          height="unset"
          label="Project Name"
          placeholder="Apt, suite, unit, building, floor, etc."
          padding="0px 1px 0px 1px"
          shrink="0"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "TextField1111061")}
        ></TextField>
        <Flex
          gap="10px"
          direction="row"
          width="590px"
          height="unset"
          justifyContent="space-between"
          alignItems="flex-start"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="1px 1px 1px 1px"
          {...getOverrideProps(overrides, "Frame 4392")}
        >
          <TextField
            width="190px"
            height="unset"
            label="Project Name"
            placeholder="City"
            shrink="0"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            {...getOverrideProps(overrides, "TextField1111070")}
          ></TextField>
          <SelectField
            width="180px"
            height="unset"
            placeholder="State"
            shrink="0"
            size="default"
            isDisabled={false}
            labelHidden={true}
            variation="default"
            {...getOverrideProps(overrides, "SelectField")}
          ></SelectField>
          <TextField
            width="190px"
            height="unset"
            label="Project Name"
            placeholder="Zip code"
            shrink="0"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            {...getOverrideProps(overrides, "TextField1111077")}
          ></TextField>
        </Flex>
      </Flex>
      <CheckboxField
        width="unset"
        height="unset"
        label="You agree to our privacy policy."
        shrink="0"
        size="default"
        defaultChecked={true}
        isDisabled={false}
        labelPosition="end"
        {...getOverrideProps(overrides, "CheckboxField1161263")}
      ></CheckboxField>
      <Button
        width="unset"
        height="unset"
        shrink="0"
        size="default"
        isDisabled={false}
        variation="default"
        children="Submit"
        {...getOverrideProps(overrides, "Button")}
      ></Button>
    </Flex>
  );
}
