import React from "react";
import PropTypes from "prop-types";
import { FiInstagram, FiYoutube } from "react-icons/fi";
import { Logo } from "../imagepath";

const SocialIcon = ({ href, icon }) => {
  const iconStyles =
    "flex items-center justify-center text-gray transition-all duration-200 bg-transparent border border-gray-700 rounded-full w-7 h-7 focus:bg-blue-600 hover:bg-blue-600 hover:border-blue-600 focus:border-blue-600";

  return (
    <li>
      <a href={href} target="_blank" rel="noopener noreferrer">
        <div className={iconStyles}>{icon}</div>
      </a>
    </li>
  );
};

SocialIcon.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
};

const Footer = () => {
  const socialIcons = [
    {
      icon: <FiYoutube className="w-4 h-4" />,
      href: "https://www.youtube.com/@Resia.design",
    },
    {
      icon: <FiInstagram className="w-4 h-4" />,
      href: "https://www.instagram.com/resia.design/",
    },
  ];

  return (
    <section className="py-10 bg-white-900 sm:pt-6 lg:pt-10">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="flex flex-col items-center">
          <img
            className="h-8 w-auto mb-4"
            src={Logo}
            alt="Resia Logo"
            height={200}
            width="auto"
          />
          <div className="flex flex-wrap items-center justify-between w-full gap-4">
            <ul className="flex items-center space-x-3 md:order-3">
              {socialIcons.map((item, index) => (
                <SocialIcon key={index} href={item.href} icon={item.icon} />
              ))}
            </ul>

            <p className="w-full mt-8 text-sm text-center text-black-100 md:mt-0 md:w-auto md:order-2">
              © Copyright 2024, All Rights Reserved by Resia Incorporation
            </p>
            <a
              href="/about"
              className="w-full text-sm text-center text-black-100 hover:text-gray-700 md:w-auto md:order-1"
            >
              About Us
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
