import React from "react";
import { Flex, Text, Button, ScrollView, Message } from "@aws-amplify/ui-react";
import { FaTrash, FaPlus } from "react-icons/fa";
import CostEstimationTable from "../../../components/costEstimationTable";

const CostEstimation = ({
  windowWidth,
  windowHeight,
  costEstimation,
  setCostEstimation,
  handleCostEstimation,
  handleAddCost,
  handleDeleteCost,
  loading,
  totalCost,
}) => {
  return (
    <>
      <Flex direction="column" width={windowWidth}>
        <Button
          className="animated-gradient"
          color="white"
          isFullWidth={false}
          onClick={handleCostEstimation}
          isLoading={loading}
          loadingText="Estimating cost..."
          borderRadius="20px"
          borderColor="transparent"
        >
          Cost Estimation
        </Button>
        {totalCost !== 0 && (
          <Message colorTheme="success" textAlign="center">
            Estimated cost is ${totalCost}
          </Message>
        )}
        <ScrollView height={windowHeight}>
          <Flex direction="column" gap="10px">
            <CostEstimationTable
              costEstimation={costEstimation}
              setCostEstimation={setCostEstimation}
              editable={true}
            />
            {/* <Button
            onClick={handleAddCost}
            style={{
              backgroundColor: "#e0e0e0",
              borderRadius: "5px",
              color: "black",
            }}
          >
            <FaPlus /> Add More
          </Button> */}
          </Flex>
        </ScrollView>
      </Flex>
    </>
  );
};

export default CostEstimation;
