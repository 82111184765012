import { useLocation } from "react-router-dom";
import { Flex, Divider } from "@aws-amplify/ui-react";
import ProjectBid from "./projectBid";
import ProjectDetail from "./projectDetail";

const ProjectBidPage = () => {
  const location = useLocation();
  const projectItem = location.state.projectItem;

  return (
    <Flex direction="row" gap="10px" padding="10px" alignItems="center">
      <ProjectDetail projectItem={projectItem} />
      <Divider size="small" width="90%" orientation="vertical" />
      <ProjectBid projectItem={projectItem} />
    </Flex>
  );
};

export default ProjectBidPage;
