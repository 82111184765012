import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import WelcomeModal from "./welcomeModal";

import HeroSection from "./HeroSection";
import FeaturesSection from "./FeatureSection";
import UseCasesSection from "./UseCaseSection";
import NewFeaturesSection from "./NewFeatureSection";
import PricingSection from "./PricingSection";
import ReadyToTransformSection from "./ReadyToTransformSection";
import VideoModal from "../../components/VideoModal";

function Home({ user, userAttributes, userRole }) {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const scrollToHash = () => {
      if (location.hash) {
        const sectionId = location.hash.replace("#", "");
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    scrollToHash();

    window.addEventListener("hashchange", scrollToHash);

    return () => {
      window.removeEventListener("hashchange", scrollToHash);
    };
  }, [location]);

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (userRole === false) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [userRole]);

  const handleOpenVideoModal = () => {
    setIsVideoModalOpen(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(email);
  };

  return (
    <>
      <section id="hero">
        <HeroSection handleOpenVideoModal={handleOpenVideoModal} />
      </section>

      <section id="features">
        <FeaturesSection />
      </section>

      <section id="use-cases">
        <UseCasesSection />
      </section>

      <section id="new-features">
        <NewFeaturesSection />
      </section>

      <section id="pricing">
        <PricingSection userAttributes={userAttributes} />
      </section>

      <section id="ready">
        <ReadyToTransformSection
          handleSubmit={handleSubmit}
          email={email}
          setEmail={setEmail}
        />
      </section>
      <VideoModal
        isOpen={isVideoModalOpen}
        onClose={() => setIsVideoModalOpen(false)}
        videoId="fTKuNq54ZJI"
      />
      {user && (
        <WelcomeModal
          user={user}
          userAttributes={userAttributes}
          isOpen={isOpen}
          closeModal={closeModal}
        />
      )}
    </>
  );
}

export default Home;
