import { useState, useEffect, createContext } from "react";
import { Text, Flex, Button, ThemeProvider } from "@aws-amplify/ui-react";
import ImageGenerationPage from "./generationPage";
import DetailPage from "./detailPage";
import CustomizePage from "./customizePage";
import EstimatePage from "./estimatePage";
import "../../assets/css/style.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { beforeEx } from "../../imagepath";
import { getCustomer, getContractor } from "../../../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { useNavigate } from "react-router-dom";

const transparentButtonTheme = {
  name: "button-theme",
  tokens: {
    components: {
      button: {
        outlined: {
          info: {
            borderColor: "transparent",
            color: "transparent",
          },
        },
        primary: {
          backgroundColor: { value: "transparent" },
          borderColor: { value: "transparent" },
          _hover: {
            backgroundColor: { value: "transparent" },
            borderColor: { value: "transparent" },
          },
          _active: {
            backgroundColor: { value: "transparent" },
            borderColor: { value: "transparent" },
          },
          _focus: {
            backgroundColor: { value: "transparent" },
            borderColor: { value: "transparent" },
          },
        },
      },
    },
  },
};

export const ProjectPageContext = createContext();

function CreatePage() {
  const client = generateClient();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [imageItems, setImageItems] = useState([
    {
      original: beforeEx,
      generated: [],
      category: "kitchen",
      isExample: true,
      file: null,
    },
  ]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedHearts, setSelectedHearts] = useState({});
  const processLabels = {
    0: [null, "Customize"],
    1: ["Renovate", "Cost Estimation"],
    2: ["Customize", "Detail"],
    3: ["Cost Estimation", null],
  };

  const [bedroomChecked, setBedroomChecked] = useState(false);
  const [livingRoomChecked, setLivingRoomChecked] = useState(false);
  const [kitchenChecked, setKitchenChecked] = useState(false);
  const [remodelType, setRemodelType] = useState([]);
  const [expectedStartDate, setExpectedStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [projectName, setProjectName] = useState(null);
  const [description, setDescription] = useState(null);
  const [location, setLocation] = useState({
    city: null,
    state: null,
    address1: null,
    address2: null,
    zipCode: null,
  });
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [dialCode, setDialCode] = useState("+1");
  const [fullname, setFullname] = useState(null);
  const [email, setEmail] = useState(null);
  const [submitDateTime, setSubmitDateTime] = useState(null);
  const [immediatelyRadio, setImmediatelyRadio] = useState(true);
  const [scheduledRadio, setScheduledRadio] = useState(false);

  const [showGenExample, setShowGenExample] = useState(true);
  const [showExInfo, setShowExInfo] = useState(false);
  const isDebug = false;

  const userId = localStorage.getItem("id");
  const role = localStorage.getItem("role");

  const breadcrumbs = [
    {
      label: "Renovate your home",
      isCurrent: currentPage === 0,
    },
    {
      label: "Customize",
      isCurrent: currentPage === 1,
    },
    {
      label: "Cost\nEstimation",
      isCurrent: currentPage === 2,
    },
    {
      label: "Detail",
      isCurrent: currentPage === 3,
    },
  ];

  const [paid, setPaid] = useState(false);

  useEffect(() => {
    async function checkPaid() {
      try {
        const user = role === "customer" ? getCustomer : getContractor;
        const response = await client.graphql({
          query: user.replaceAll("__typename", ""),
          variables: { id: userId },
        });
        if (response) {
          if (role === "customer") {
            if (response.data.getCustomer.paidStatus > 0) {
              setPaid(true);
            }
          } else {
            if (response.data.getContractor.paidStatus > 0) {
              setPaid(true);
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
    checkPaid();
  }, []);

  const handlePrevNext = (direction) => {
    if (selectedItems.length === 0 && currentPage === 0) {
      alert("Please select at least one item");
      return;
    }

    if (currentPage === 0 && userId === null) {
      navigate("/login");
      return;
    }

    if (paid === false && currentPage === 0) {
      alert("Please subscribe to continue");
      navigate("/#pricing");
      return;
    }

    if (direction === "next") {
      if (currentPage === 1 && selectedItems[0].isExample) {
        alert(
          "You can't go to the next step with example image. Please upload your own image."
        );
        return;
      }
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <ThemeProvider theme={transparentButtonTheme} colorMode="light">
      <Flex
        width="100%"
        height="80%"
        padding="20px"
        direction="column"
        gap="20px"
        alignItems="center"
        alignContent="center"
        // marginBottom="15px"
      >
        <Text className="animated-gradient-text" padding="10px">
          {breadcrumbs[currentPage].label}
        </Text>
        <Flex
          direction="row"
          alignContent="center"
          gap="10px"
          padding="0"
          wrap="nowrap"
        >
          <Button
            gap="0"
            padding="10px"
            minWidth="50px"
            width="100px"
            height="200px"
            marginTop="100px"
            borderColor="transparent"
            onClick={() => handlePrevNext("prev")}
            style={{ visibility: currentPage === 0 ? "hidden" : "visible" }}
            disabled={currentPage === 0}
            variation="link"
            size="large"
          >
            <Flex
              direction="column"
              gap="10px"
              alignItems="center"
              color="green"
              textAlign="center"
            >
              <FaArrowLeft size="40" color="green" />{" "}
              {processLabels[currentPage][0]}
            </Flex>
          </Button>
          {currentPage === 0 ? (
            <ImageGenerationPage
              imageItems={imageItems}
              setImageItems={setImageItems}
              showGenExample={showGenExample}
              setShowGenExample={setShowGenExample}
              setShowExInfo={setShowExInfo}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              selectedHearts={selectedHearts}
              setSelectedHearts={setSelectedHearts}
              isDebug={isDebug}
            />
          ) : currentPage === 1 ? (
            <CustomizePage
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              showExInfo={showExInfo}
            />
          ) : currentPage === 2 ? (
            <EstimatePage
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
          ) : (
            <ProjectPageContext.Provider
              value={{
                bedroomChecked,
                setBedroomChecked,
                livingRoomChecked,
                setLivingRoomChecked,
                kitchenChecked,
                setKitchenChecked,
                remodelType,
                setRemodelType,
                immediatelyRadio,
                setImmediatelyRadio,
                scheduledRadio,
                setScheduledRadio,
                expectedStartDate,
                setExpectedStartDate,
                projectName,
                setProjectName,
                description,
                setDescription,
                location,
                setLocation,
                phoneNumber,
                setPhoneNumber,
                dialCode,
                setDialCode,
                fullname,
                setFullname,
                email,
                setEmail,
                submitDateTime,
                setSubmitDateTime,
              }}
            >
              <DetailPage selectedItems={selectedItems} />
            </ProjectPageContext.Provider>
          )}
          <Button
            gap="0"
            padding="10px"
            minWidth="50px"
            width="100px"
            height="200px"
            marginTop="100px"
            borderColor="transparent"
            onClick={() => handlePrevNext("next")}
            style={{ visibility: currentPage === 3 ? "hidden" : "visible" }}
            disabled={currentPage === 3}
            variation="link"
            size="large"
          >
            <Flex
              direction="column"
              gap="10px"
              textAlign="center"
              alignItems="center"
              color="green"
            >
              <FaArrowRight size="40" color="green" />
              {processLabels[currentPage][1]}
            </Flex>
          </Button>
        </Flex>
      </Flex>
    </ThemeProvider>
  );
}

export default CreatePage;
