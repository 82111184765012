import {
  Card,
  Flex,
  Image,
  Divider,
  ScrollView,
  Text,
  Button,
  DropZone,
  VisuallyHidden,
} from "@aws-amplify/ui-react";
import { FaHeart, FaCirclePlus } from "react-icons/fa6";

const acceptedFileTypes = ["image/png", "image/jpeg"];

const GenerationView = ({
  imageItems,
  hiddenInput,
  selectedHearts,
  showGenExample,
  handleFileUpload,
  handleImageClick,
  handleHeartClick,
  onFilePickerChange,
  setCurrentImageIndex,
  setShowGenerationModal,
}) => {
  return (
    <Flex
      direction="column"
      gap="20px"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
    >
      {imageItems.map(
        (imageObj, index) =>
          (showGenExample || !imageObj.isExample) && (
            <Flex
              direction="column"
              alignContent="center"
              alignItems="center"
              justifyContent="center"
              key={index}
            >
              <ScrollView key={`scrollview${index}`}>
                <Card
                  borderRadius="medium"
                  variation="elevated"
                  padding="5px"
                  backgroundColor="transparent"
                  key={`card${index}`}
                >
                  <Flex direction="row" height="auto" justifyContent="center">
                    <Image
                      src={imageObj.original}
                      alt={`Original ${index}`}
                      // minWidth="150px"
                      // maxWidth="350px"
                      // width="100%"
                      // height="auto"
                      onClick={() => handleImageClick(imageObj.original)}
                      style={{
                        cursor: "pointer",
                        borderRadius: "10px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        objectFit: "contain",
                        width: "100%",
                        height: "auto",
                        maxWidth: "100%",
                        maxHeight: "240px",
                      }}
                    />
                    {imageObj.generated.map((genObj, genIndex) => (
                      <div
                        style={{ position: "relative" }}
                        key={`gen${index}-${genIndex}`}
                      >
                        <Image
                          src={genObj.src}
                          alt={genObj.alt}
                          minWidth="150px"
                          maxWidth="350px"
                          height="240px"
                          onClick={() => handleImageClick(genObj.src)}
                          style={{
                            cursor: "pointer",
                            borderRadius: "10px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            objectFit: "contain",
                            maxWidth: "100%",
                            maxHeight: "240px",
                          }}
                        />
                        <FaHeart
                          style={{
                            position: "absolute",
                            bottom: "20px",
                            right: "10px",
                            cursor: "pointer",
                            color:
                              selectedHearts[index] === genIndex
                                ? "red"
                                : "lightgrey",
                          }}
                          size={30}
                          onClick={() => handleHeartClick(index, genIndex)}
                        />
                      </div>
                    ))}
                    <Button
                      borderRadius="medium"
                      borderColor="transparent"
                      style={{
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                      onClick={() => {
                        setCurrentImageIndex(index);
                        setShowGenerationModal(true);
                      }}
                    >
                      <Flex direction="column" gap="10px" alignItems="center">
                        <FaCirclePlus
                          style={{
                            cursor: "pointer",
                            color: "#4CAF50",
                          }}
                          size={30}
                        />
                        <Text color="#4CAF50" textAlign="center">
                          Generate Image
                        </Text>
                      </Flex>
                    </Button>
                  </Flex>
                </Card>
              </ScrollView>
              <Divider margin="10px" />
            </Flex>
          )
      )}
      {imageItems.length < 10 && (
        <DropZone
          marginTop="10px"
          width={imageItems.length !== 0 ? "200px" : "400px"}
          height={imageItems.length !== 0 ? "200px" : "300px"}
          acceptedFileTypes={acceptedFileTypes}
          onDropComplete={({ acceptedFiles }) =>
            handleFileUpload(acceptedFiles)
          }
          style={{
            alignContent: "center",
            border: "2px dashed #ccc",
            borderRadius: "10px",
            padding: "20px",
            textAlign: "center",
            backgroundColor: "#fafafa",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Flex direction="column" alignItems="center">
            <Text fontSize="20px">Drag images here or</Text>
            <Button onClick={() => hiddenInput.current.click()}>Browse</Button>
          </Flex>
          <VisuallyHidden>
            <input
              type="file"
              tabIndex={-1}
              ref={hiddenInput}
              onChange={onFilePickerChange}
              multiple={true}
              accept={acceptedFileTypes.join(",")}
            />
          </VisuallyHidden>
        </DropZone>
      )}
      {/* <Divider padding="10px" borderColor="#4CAF50" /> */}
      <Divider padding="10px" borderColor={"colors.green.60"} />
    </Flex>
  );
};

export default GenerationView;
