import ImageGallery from "react-image-gallery";
import {
  ImageModernKitchen,
  ImageMinimalistKitchen,
  ImageMidcenturyKitchen,
  ImageRusticKitchen,
  ImageScandinavianKitchen,
  ImageBohemianKitchen,
} from "../../imagepath";
import { MdPlayArrow } from "react-icons/md";

const commonStyles = {
  callToActionButton:
    "inline-flex items-center justify-center w-full px-8 py-3 text-lg font-bold text-white transition-all duration-200 bg-gray-900 border-2 border-transparent sm:w-auto rounded-xl font-pj hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900",
  demoButton:
    "inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-lg font-bold text-gray-900 transition-all duration-200 border-2 border-gray-400 sm:w-auto sm:mt-0 rounded-xl font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:bg-gray-900 focus:bg-gray-900 hover:text-white focus:text-white hover:border-gray-900 focus:border-gray-900",
};

const images = [
  { original: ImageModernKitchen, thumbnail: ImageModernKitchen },
  { original: ImageMinimalistKitchen, thumbnail: ImageMinimalistKitchen },
  { original: ImageMidcenturyKitchen, thumbnail: ImageMidcenturyKitchen },
  { original: ImageRusticKitchen, thumbnail: ImageRusticKitchen },
  { original: ImageScandinavianKitchen, thumbnail: ImageScandinavianKitchen },
  { original: ImageBohemianKitchen, thumbnail: ImageBohemianKitchen },
];

const HeroSection = ({ handleOpenVideoModal }) => {
  return (
    <div className="mx-auto my-4 flex flex-col lg:flex-row justify-between items-center px-4 sm:px-6 lg:px-8">
      {/* Left Side */}
      <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
        <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-4 leading-relaxed">
          <div className="flex flex-col items-start">
            <span className="leading-tight">One Stop</span>
            <span className="inline-block text-transparent bg-gradient-to-r from-pink-500 to-orange-400 bg-clip-text animate-typing">
              Remodeling
            </span>
            <span className="leading-tight">Solution with AI</span>
          </div>
        </h1>
        <p className="mx-auto text-lg sm:text-xl lg:text-2xl text-gray-600 mt-5">
          The AI-driven platform to Design, Estimate, Contract, and Manage your
          remodeling projects.
        </p>
        <div className="mt-8">
          <div className="px-0 sm:px-0 sm:space-x-5 sm:flex mt-9">
            <a
              href="/create"
              title="Get Started"
              className={commonStyles.callToActionButton}
              role="button"
            >
              Get Started
            </a>

            <a
              title="Watch demo"
              className={commonStyles.demoButton}
              role="button"
              onClick={handleOpenVideoModal}
            >
              <MdPlayArrow className="w-5 h-5 mr-2" />
              Watch Demo
            </a>
          </div>
        </div>
      </div>
      {/* Right Side */}
      <div className="w-full lg:w-1/2 rounded-lg overflow-hidden pointer-events-none">
        <ImageGallery
          items={images}
          showThumbnails={false}
          showFullscreenButton={false}
          showPlayButton={false}
          autoPlay={true}
          showNav={false}
        />
      </div>
    </div>
  );
};

export default HeroSection;
