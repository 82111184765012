import React from "react";
import { FaStar } from "react-icons/fa";
import { featureContractor } from "../../imagepath";

const commonStyles = {
  container:
    "flex flex-col overflow-hidden shadow-xl  transform transition-transform duration-300 hover:scale-105",
  card: "flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7",
  rating: "flex items-center text-[#FDB241]",
  testimonial: "flex-1 mt-8",
  testimonialText: "text-lg leading-relaxed text-gray-900 font-pj",
  profile: "flex items-center mt-8",
  profileImage: "flex-shrink-0 object-cover rounded-full w-11 h-11",
  profileInfo: "ml-4",
  profileName: "text-base font-bold text-gray-900 font-pj",
  profileRole: "mt-0.5 text-sm font-pj text-gray-600",
  link: "pb-2 text-base font-bold leading-7 text-gray-900 transition-all duration-200 border-b-2 border-gray-900 hover:border-gray-600 font-pj focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 hover:text-gray-600",
};

// Creating stars
function renderStars(count) {
  return Array.from({ length: count }, (_, index) => <FaStar key={index} />);
}

const UseCaseSection = () => {
  const useCases = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a366b614be523f7d4fa413793502ff207fbfdc4c85899d7016938fd348e8c8d1",
      title: "Contractors",
      description:
        "Generate accurate quotes for remodel projects quickly, improving bid accuracy and win rates.",
    },
    {
      icon: featureContractor,
      title: "Architects",
      description:
        "Provide clients with instant cost feedback on renovation designs and modifications.",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/b61cbb3bd2f1019186bac1d11a767e0f5f92055d2cce4228b0338dfac84ac033",
      title: "Homeowners",
      description:
        "Get reliable cost estimates for home renovation projects to make informed decisions.",
    },
  ];

  return (
    <section className="py-12 sm:py-16 lg:py-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center" gap="4">
          <h3 className="text-4xl font-bold mb-4">Who Uses Resia?</h3>
          <h6 className="text-lg text-gray-600 mb-4 opacity-80">
            Quick and Easy cost estimates for all your renovation projects.
          </h6>
        </div>

        <div className="relative mt-10">
          <div className="absolute -inset-x-1 inset-y-16 md:-inset-x-2 md:-inset-y-6">
            <div
              className="w-full h-full max-w-5xl mx-auto rounded-3xl opacity-30 blur-lg filter"
              style={{
                background:
                  "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)",
              }}
            ></div>
          </div>

          <div className="relative grid max-w-lg grid-cols-1 gap-6 mx-auto md:max-w-none lg:gap-10 md:grid-cols-3">
            {useCases.map((useCase, index) => (
              <div
                key={index}
                className={commonStyles.container}
                style={{ borderRadius: "20px" }}
              >
                <div className={commonStyles.card}>
                  <div className={commonStyles.testimonial}>
                    <div
                      className={commonStyles.rating}
                      style={{ marginBottom: 4 }}
                    >
                      {renderStars(5)}
                    </div>
                    <blockquote className={commonStyles.testimonialText}>
                      {useCase.description}
                    </blockquote>
                  </div>
                  <div className={commonStyles.profile}>
                    <img
                      className={commonStyles.profileImage}
                      src={useCase.icon}
                      alt={useCase.title}
                    />
                    <div className={commonStyles.profileInfo}>
                      <p className={commonStyles.profileName}>
                        {useCase.title}
                      </p>
                      <p className={commonStyles.profileRole}></p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default UseCaseSection;
