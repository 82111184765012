import React, { useEffect, useState } from "react";
import { getUrl } from "aws-amplify/storage";
import { Text, View, Flex, Loader } from "@aws-amplify/ui-react";
import ReactMarkdown from "react-markdown";

const PrivacyPolicy = () => {
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
      try {
        const fileURL = await getUrl({
          path: "public/privacy-policy.md",
        });
        const response = await fetch(fileURL.url);
        const text = await response.text();
        setContent(text);
      } catch (error) {
        setError("Failed to load privacy policy. Please try again later.");
        console.error("Error fetching privacy policy:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPrivacyPolicy();
  }, []);

  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      padding="20px"
      backgroundColor="#f4f4f4"
      width="60%"
      minHeight="100vh"
    >
      <View
        padding="20px"
        backgroundColor="white"
        boxShadow="0 0 10px rgba(0,0,0,0.1)"
      >
        {loading ? (
          <Loader />
        ) : error ? (
          <Text color="red">{error}</Text>
        ) : (
          <ReactMarkdown>{content}</ReactMarkdown>
        )}
      </View>
    </Flex>
  );
};

export default PrivacyPolicy;
