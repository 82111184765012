import React, { useState } from "react";
import { FaLock } from "react-icons/fa";
import { generateClient } from "aws-amplify/api";
import ConfirmationModal from "../../components/ConfirmationModal";
import { createEmailSubscription } from "../../../graphql/mutations";

const ReadyToTransformSection = () => {
  const client = generateClient();

  const containerStyles = "py-10 bg-gray-100 sm:py-16 lg:py-24 rounded-2xl";
  const innerContainerStyles = "px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl";
  const headingStyles =
    "text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl";
  const descriptionStyles =
    "max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600";
  const buttonStyles =
    "inline-flex items-center mt-4 cursor-pointer justify-center w-auto px-4 py-4 bg-gray-900 text-white hover:bg-opacity-90 hover:text-white focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 rounded-xl ml-4";
  const iconStyles = "flex-shrink-0 w-5 h-5 text-gray-600";
  const secureTextStyles = "ml-2 text-sm text-gray-600";

  const [email, setEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const subscribe = async () => {
      try {
        await client.graphql({
          query: createEmailSubscription,
          variables: { input: { email } },
        });
        setEmail("");
        setIsModalOpen(true);
      } catch (error) {
        console.error("Error subscribing:", error);
      }
    };
    subscribe();
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <section className={containerStyles}>
      <div className={innerContainerStyles}>
        <div className="max-w-2xl mx-auto text-center">
          <h2 className={headingStyles}>Subscribe to Our Newsletter!</h2>
          <p className={descriptionStyles}>
            Stay updated with the latest news and offers from Resia. Sign up
            now!
          </p>
          <div className="flex justify-center space-x-4 mt-4">
            <form onSubmit={handleSubmit} className="flex">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="px-4 py-2 border rounded-xl mt-4"
                required
              />
              <button type="submit" className={buttonStyles}>
                Subscribe
              </button>
            </form>
          </div>
        </div>

        <div className="flex items-center justify-center px-8 mt-8 sm:px-0">
          <FaLock className={iconStyles} />
          <span className={secureTextStyles}>
            Your data is completely secure with Resia. We value your privacy and
            do not share your information with anyone.
          </span>
        </div>
      </div>

      <ConfirmationModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        message="Thanks for subscribing!"
      />
    </section>
  );
};

export default ReadyToTransformSection;
