import { useState, useEffect } from "react";
import {
  Grid,
  Card,
  Button,
  Flex,
  Text,
  Badge,
  ScrollView,
  Placeholder,
} from "@aws-amplify/ui-react";
import { FiX } from "react-icons/fi";
import ImageGallery from "react-image-gallery";
import "../../assets/css/style.css";
import { useNavigate, useParams } from "react-router-dom";
import { getUrl } from "aws-amplify/storage";
import { FiShare2 } from "react-icons/fi";
import { generateClient } from "aws-amplify/api";
import { getProject } from "../../../graphql/queries";

export default function ProjectDetailModal({ setShowDetailModal }) {
  const { projectId } = useParams();
  const [projectItem, setProjectItem] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageItems, setImageItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const role = localStorage.getItem("role");

  const client = generateClient();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchProject() {
      setIsLoading(true);
      try {
        const project = (
          await client.graphql({
            query: getProject.replaceAll("__typename", ""),
            variables: { id: projectId },
          })
        ).data;
        setProjectItem(project.getProject);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
    fetchProject();
  }, []);

  const handleBidButtonClick = () => {
    // navigate(`/projects/${projectItem.id}/bid`, {
    navigate(`/projects/bid`, {
      state: { projectItem: projectItem },
    });
  };

  const getImages = async () => {
    const images = await Promise.all(
      projectItem.images?.flatMap(async (image) => {
        const beforeImageUrl = image.beforeImage
          ? await getUrl({ path: image.beforeImage })
          : null;
        const afterImageUrl = image.afterImage
          ? await getUrl({ path: image.afterImage })
          : null;
        if (afterImageUrl === null) {
          return [
            {
              original: beforeImageUrl.url,
              thumbnail: beforeImageUrl.url,
              description: image.description,
            },
          ];
        } else {
          return [
            {
              original: beforeImageUrl.url,
              thumbnail: beforeImageUrl.url,
              description: image.description,
            },
            {
              original: afterImageUrl.url,
              thumbnail: afterImageUrl.url,
              description: image.description,
            },
          ];
        }
      })
    );
    return images.filter((image) => image !== undefined);
  };

  useEffect(() => {
    if (projectItem === undefined || !projectItem.hasOwnProperty("images"))
      return;
    getImages().then((images) => {
      setImageItems(images.flat());
    });
  }, [projectItem]);

  const handleShareButtonClick = () => {
    const url = window.location.origin + "/project/" + projectItem.id;
    navigator.clipboard.writeText(url);
    alert("Project link copied to clipboard");
  };

  return isLoading ? (
    <Placeholder size="large" />
  ) : (
    <Grid
      columnGap="0.2rem"
      rowGap="0.2rem"
      templateColumns="640px 100px 350px"
      templateRows="30px 570px 130px"
      padding="1rem"
    >
      {/* # Top Header */}
      <Card rowStart="1" columnStart="1" columnEnd="-1" textAlign={"center"}>
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          marginTop="-30px"
          marginLeft="-20px"
          marginRight="-20px"
        >
          <Text fontSize="large" fontWeight="800">
            {projectItem.projectName}
          </Text>
          <Button
            borderColor="transparent"
            onClick={() => {
              setShowDetailModal(false);
              navigate("/projects");
            }}
            padding="0"
          >
            <FiX size="25px" />
          </Button>
        </Flex>
      </Card>
      {/* # Top Left */}
      <Card
        rowStart="2"
        columnStart="1"
        columnEnd="3"
        padding="0"
        borderRadius="10px"
        height="100%"
      >
        <ImageGallery
          items={imageItems.map(({ description, ...item }) => item)}
          showPlayButton={false}
          onSlide={setCurrentIndex}
        />{" "}
      </Card>
      {/* # Bottom Left */}
      <Card rowStart="3" columnStart="1" columnEnd="3" padding="0">
        {/* <Flex direction="column" padding="0" gap="1px">
          <Text>Image Description</Text>
          <Card borderColor="gray" borderWidth="1px" borderRadius="10px">
            <Text>{imageItems[currentIndex]?.description || "Loading..."}</Text>
          </Card>
        </Flex> */}
      </Card>
      {/* # Top Right */}
      <Card rowStart="2" columnStart="3" columnEnd="-1">
        <Flex direction="column">
          <Flex gap="5px">
            {projectItem.remodelType.includes("bedroom") && (
              <Badge size="small" children="Bedroom" variation="warning" />
            )}
            {projectItem.remodelType.includes("livingroom") && (
              <Badge size="small" children="Living Room" variation="success" />
            )}
            {projectItem.remodelType.includes("kitchen") && (
              <Badge size="small" children="Kitchen" variation="info" />
            )}
          </Flex>
          <Flex direction="column" padding="0" gap="10px">
            <Flex
              direction="column"
              alignItems="flex-start"
              gap="1px"
              borderColor="gray"
              borderWidth="1px"
            >
              <Text fontWeight="bold" marginRight="0.5rem">
                Post Date:
              </Text>
              <Text>{new Date(projectItem.submitDateTime).toDateString()}</Text>
            </Flex>
            <Flex
              direction="column"
              alignItems="flex-start"
              gap="1px"
              borderColor="gray"
              borderWidth="1px"
            >
              <Text fontWeight="bold" marginRight="0.5rem">
                Expected Start Date:
              </Text>
              <Text>
                {new Date(projectItem.expectedStartDate).toDateString()}
              </Text>
            </Flex>
            <Flex
              direction="column"
              alignItems="flex-start"
              gap="1px"
              borderColor="gray"
              borderWidth="1px"
            >
              <Text fontWeight="bold" marginRight="0.5rem">
                Location:
              </Text>
              <Text>
                {projectItem.location.city}, {projectItem.location.state}
              </Text>
            </Flex>
            <Text fontWeight="bold">Project Description:</Text>
            <Flex
              alignItems="flex-start"
              gap="1px"
              borderColor="gray"
              borderWidth="1px"
            >
              <ScrollView maxHeight="250px" marginBottom="10px">
                <Text>{projectItem.projectDescription}</Text>
              </ScrollView>
            </Flex>
          </Flex>
        </Flex>
      </Card>
      {/* # Bottom Right */}
      <Card
        rowStart="3"
        columnStart="3"
        columnEnd="-1"
        padding="0px 20px 0px 20px"
      >
        <Flex
          direction="column"
          alignItems="flex-end"
          justifyContent="center"
          padding="0"
          gap="10px"
        >
          <Button
            className="colorful-button"
            width="100%"
            gap="10px"
            onClick={handleShareButtonClick}
          >
            <FiShare2 />
            Share Project
          </Button>
          {role === "contractor" && (
            <Button
              width="100%"
              className="colorful-button"
              onClick={handleBidButtonClick}
            >
              Bid Project
            </Button>
          )}
        </Flex>
      </Card>
    </Grid>
  );
}
