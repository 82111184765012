import React, { useState } from "react";
import { Flex, Card, Text } from "@aws-amplify/ui-react";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const ProjectDetailImage = ({ uploadedImages }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <Flex
      direction="column"
      width="640px"
      height="auto"
      alignContent="flex-start"
      alignItems="center"
      padding="10px 0px 0px 0px"
      gap="0"
    >
      <Card width="640px">
        <ImageGallery
          thumbnailPosition="top"
          items={uploadedImages.map(({ description, ...item }) => item)}
          showPlayButton={false}
          onSlide={setCurrentIndex}
        />
      </Card>
      <Flex direction="column" padding="0" gap="5px" alignItems="start">
        {uploadedImages.length > 0 && (
          <>
            <Text fontWeight={"bold"}>Image Description</Text>
            <Card
              borderColor="gray"
              borderWidth="1px"
              borderRadius="10px"
              width="620px"
            >
              <Text>
                {
                  uploadedImages[currentIndex % uploadedImages.length]
                    .description
                }
              </Text>
            </Card>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default ProjectDetailImage;
