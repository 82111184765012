import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import { projectsByCustomerID } from "../../../graphql/queries";
import Sidebar from "../../components/Sidebar";
import ProjectList from "./common/ProjectList";
import ProjectDetail from "./common/ProjectDetail";
import ProfilePage from "../userPage/profilePage";
import {
  FiBarChart,
  FiBriefcase,
  FiFileText,
  FiUsers,
  FiMessageSquare,
  FiStar,
} from "react-icons/fi";

const Dashboard = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const client = generateClient();
  const userData = {
    id: localStorage.getItem("id"),
    role: localStorage.getItem("role"),
  };
  const [selectedProject, setSelectedProject] = useState(null);
  const [activeTab, setActiveTab] = useState("overview");
  const location = useLocation();

  const tabs = [
    // { id: "overview", name: "Overview", icon: FiBarChart },
    { id: "projects", name: "Projects", icon: FiBriefcase },
    { id: "profile", name: "Profile", icon: FiUsers },
    // { id: "tasks", name: "Tasks", icon: FiFileText },
    // { id: "customers", name: "Customers", icon: FiUsers },
    // { id: "contractors", name: "Contractors", icon: FiUsers },
    // { id: "messages", name: "Messages", icon: FiMessageSquare },
    // { id: "reviews", name: "Reviews", icon: FiStar },
  ];

  useEffect(() => {
    const fetchProjects = async () => {
      const userId = userData.id;
      if (!userId) return;

      try {
        setLoading(true);
        const response = await client.graphql({
          query: projectsByCustomerID.replaceAll("__typename", ""),
          variables: { customerID: userId },
        });

        setProjects(response.data.projectsByCustomerID.items);
      } catch (error) {
        console.error("Error fetching projects:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const currentPath = pathSegments[2];
    if (currentPath === "projects" && pathSegments[3]) {
      setActiveTab("projects");
    } else if (tabs.some((tab) => tab.id === currentPath)) {
      setActiveTab(currentPath);
    } else {
      setActiveTab("overview");
    }
  }, [location.pathname]);

  return (
    <div className="flex bg-gray-100 p-4 rounded-lg">
      <Sidebar tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      <main className="flex-1 mr-4 ml-4">
        <Routes>
          <Route path="overview" element={<div>Overview Content</div>} />
          <Route
            path="projects"
            element={
              <ProjectList
                projects={projects}
                setSelectedProject={setSelectedProject}
              />
            }
          />
          <Route
            path="projects/:projectId"
            element={
              <ProjectDetail
                project={selectedProject}
                projects={projects}
                setProjects={setProjects}
              />
            }
          />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="tasks" element={<div>Tasks Content</div>} />
          <Route path="customers" element={<div>Customers Content</div>} />
          <Route path="contractors" element={<div>Contractors Content</div>} />
          <Route path="messages" element={<div>Messages Content</div>} />
          <Route path="reviews" element={<div>Reviews Content</div>} />
        </Routes>
      </main>
    </div>
  );
};

export default Dashboard;
