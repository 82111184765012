import React from "react";
import { FaCheckCircle } from "react-icons/fa";

const ConfirmationModal = ({ isOpen, onRequestClose, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-xl">
        <div className="flex justify-center mb-4">
          <FaCheckCircle className="text-green-500 text-4xl" />
        </div>
        <h2 className="text-xl font-bold mb-4">{message}</h2>
        <div className="flex justify-center">
          <button
            onClick={onRequestClose}
            className="bg-gradient-to-r from-pink-500 to-yellow-500 text-white font-bold py-2 px-4 rounded transition-transform transform hover:scale-105"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
