import { Card, Flex, Image, Text, Badge } from "@aws-amplify/ui-react";
import { StorageImage } from "@aws-amplify/ui-react-storage";

const ProjectCard = ({ project }) => {
  return (
    <Card
      width="360px"
      height="380px"
      backgroundColor={"#f9f9f9"}
      borderRadius={"10px"}
      boxShadow={"0px 0px 5px 0px #AFAFAF"}
      alignSelf={"center"}
      padding={"5px"}
    >
      <Flex direction="column" gap="10px">
        <StorageImage
          height="230px"
          path={project.publicImage}
          alt="project thumbnail"
          borderRadius={"10px"}
        />
        <Flex direction="row" gap="3px" justifyContent={"center"}>
          {project.remodelType.map((type, index) => {
            let badgeType, badgeName;
            if (type.includes("bedroom")) {
              badgeType = "warning";
              badgeName = "Bedroom";
            } else if (type.includes("livingroom")) {
              badgeType = "success";
              badgeName = "Living Room";
            } else if (type.includes("kitchen")) {
              badgeType = "info";
              badgeName = "Kitchen";
            }
            return (
              <Badge key={index} variation={badgeType}>
                {badgeName}
              </Badge>
            );
          })}
        </Flex>
        <Flex
          direction="column"
          gap="5px"
          textAlign={"left"}
          margin="0px 15px 0px 15px"
        >
          <Text fontWeight={"bold"}>{project.projectName}</Text>
          <Text fontWeight="500">
            {project.location.city}, {project.location.state}
          </Text>
          <Text fontWeight="400">
            {new Date(project.submitDateTime).toDateString()}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};

export default ProjectCard;
