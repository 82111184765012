import { useState, useEffect, useRef, useCallback } from "react";
import {
  FaDollarSign,
  FaClipboardCheck,
  FaPaintBrush,
  FaLightbulb,
  FaMagic,
} from "react-icons/fa";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import {
  FeaturesStyle,
  FeaturesCustomize,
  FeaturesCostEstimation,
  FeaturesDetail,
  beforeEx,
  afterEx,
} from "../../imagepath";
import "../../assets/css/style.css";

const commonStyles = {
  section: "py-20 sm:py-16 lg:py-20 xl:py-24 min-h-screen",
  container: "px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl",
  heading:
    "text-4xl font-semibold tracking-tight text-gray-900 sm:text-3xl lg:text-4xl",
  paragraph:
    "mt-4 text-base font-normal leading-7 text-gray-600 lg:text-lg lg:mt-6 lg:leading-8",
  navLink:
    "pb-2 text-xs font-semibold tracking-widest uppercase transition-all duration-200 border-b",
  activeLink: "text-blue-600 border-blue-600",
  inactiveLink: "text-gray-400 border-transparent hover:text-gray-900",
};

const FeatureIcon = ({ selected, children }) => (
  <div
    className={`w-20 h-20 flex justify-center items-center transition-all duration-300 rounded-full ${
      selected
        ? "bg-gradient-to-r from-pink-500 to-orange-400 text-white"
        : "bg-opacity-50 bg-gray text-gray-500"
    } hover:bg-gradient-to-r hover:from-pink-400 hover:to-orange-300 hover:text-white`}
  >
    {children}
  </div>
);

const FeaturesSection = () => {
  const clickableFeatures = [
    "AI Remodeling",
    "Preset Styles",
    "Image Customization",
    "Cost Estimation",
    "Find Professionals",
  ];

  const featureRefs = useRef({});
  const scrollContainerRef = useRef(null);
  const [selectedFeature, setSelectedFeature] = useState(0);
  const [fade, setFade] = useState(true);

  const features = [
    {
      icon: <FaMagic className="text-3xl" />,
      title: "AI Remodeling",
      content: "Transform your space with our AI tool",
    },
    {
      icon: <FaLightbulb className="text-3xl" />,
      title: "Preset Styles",
      content: "Choose from a variety of styles",
      image: FeaturesStyle,
    },
    {
      icon: <FaPaintBrush className="text-3xl" />,
      title: "Image Customization",
      content: "Customize your space with ease",
      image: FeaturesCustomize,
    },
    {
      icon: <FaDollarSign className="text-3xl" />,
      title: "Cost Estimation",
      content: "Get instant, precise quotes",
      image: FeaturesCostEstimation,
    },
    {
      icon: <FaClipboardCheck className="text-3xl" />,
      title: "Find Professionals",
      content: "Connect with verified professionals",
      image: FeaturesDetail,
    },
  ];

  // Automatically cycle through features
  useEffect(() => {
    const intervalId = setInterval(() => {
      setFade(false); // Trigger fade-out
      setTimeout(() => {
        setSelectedFeature((prev) => (prev + 1) % features.length);
        setFade(true);
      }, 500);
    }, 3000);

    return () => clearInterval(intervalId); // Clean up on unmount
  }, [features.length]);

  useEffect(() => {
    if (featureRefs.current[selectedFeature] && scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const element = featureRefs.current[selectedFeature];
      const elementCenter = element.offsetLeft + element.offsetWidth / 2;
      const containerCenter = container.offsetWidth / 2;
      const scrollPosition = elementCenter - containerCenter;

      container.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [selectedFeature]);

  const handleFeatureClick = useCallback((index) => {
    setSelectedFeature(index);
  }, []);

  return (
    <section className={`${commonStyles.section} blur-30`}>
      <div className={commonStyles.container}>
        <div
          className={`${
            fade ? "opacity-100" : "opacity-0"
          } transition-opacity duration-500 transform`}
        >
          <div className="max-w-3xl mx-auto text-center">
            <h2 className={commonStyles.heading}>
              {features[selectedFeature].content}
            </h2>
          </div>
        </div>

        <div
          ref={scrollContainerRef}
          className="flex justify-center gap-4 lg:gap-16 mt-4 transition-transform duration-2000"
        >
          {features.map(({ icon, title }, index) => (
            <div
              key={index}
              ref={(el) => (featureRefs.current[index] = el)}
              className={`text-center flex flex-col items-center cursor-pointer ${
                clickableFeatures.includes(title) ? "pointer" : "default"
              }`}
              onClick={() =>
                clickableFeatures.includes(title) && handleFeatureClick(index)
              }
              background={selectedFeature === index ? "pink" : "white"}
            >
              <FeatureIcon
                selected={selectedFeature === index}
                background={selectedFeature === index ? "pink" : "white"}
              >
                {icon}
              </FeatureIcon>
              <span
                className={`block mt-1 font-bold transition-colors duration-300`}
                style={{
                  color: selectedFeature === index ? "#FE6B8B" : "gray",
                  opacity: selectedFeature === index ? 1 : 0.7,
                }}
              >
                {title}
              </span>
            </div>
          ))}
        </div>
        <div className="relative mt-10 flex justify-center">
          <div className="absolute -inset-x-1 inset-y-16 md:-inset-x-2 md:-inset-y-6 z-[-1]">
            <div
              className="w-full h-full max-w-3xl mx-auto rounded-3xl opacity-30 blur-lg filter"
              style={{
                background:
                  "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)",
              }}
            ></div>
          </div>
          <div className="mx-auto text-center">
            {selectedFeature === 0 ? (
              <ReactCompareSlider
                position={40}
                itemOne={
                  <ReactCompareSliderImage
                    src={beforeEx}
                    alt="Before"
                    style={{ width: "700px", height: "auto" }}
                  />
                }
                itemTwo={
                  <ReactCompareSliderImage
                    src={afterEx}
                    alt="After"
                    style={{
                      width: "700px",
                      height: "auto",
                    }}
                  />
                }
                style={{
                  borderRadius: "30px",
                }}
              />
            ) : (
              <div
                className={`${
                  fade ? "opacity-100" : "opacity-0"
                } transition-opacity duration-500 transform`}
              >
                <img
                  key={features[selectedFeature]?.image} // Adding key to trigger re-render
                  src={features[selectedFeature]?.image}
                  alt={`ClickUp ${features[selectedFeature].title}`}
                  className="w-auto max-h-[700px] rounded-[30px]"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
