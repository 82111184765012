import React from "react";
import { FaDraftingCompass, FaThLarge, FaRobot } from "react-icons/fa";

const features = [
  {
    icon: <FaThLarge fontSize="large" />,
    title: "AI-Generated Floor Plans",
    description:
      "Create multiple floor plans based on your specifications and constraints.",
  },
  {
    icon: <FaDraftingCompass fontSize="large" />,
    title: "AI-Driven Design Tools",
    description:
      "Utilize interactive AI tools to create and refine architectural designs effortlessly.",
  },
  {
    icon: <FaRobot fontSize="large" />,
    title: "Chatbot Assistance",
    description:
      "Get instant help and suggestions from our AI-powered chatbot.",
  },
];

const NewFeaturesSection = () => {
  return (
    <section className="py-12 bg-gray-50 sm:py-16 lg:py-20 rounded-2xl">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-4xl font-bold mb-4">
            Coming Soon
            <br />
            Generative AI for Ground-Up Development
          </h2>
          <p className="text-lg text-gray-600 mb-8 max-w-2xl mx-auto">
            We're expanding our AI capabilities to revolutionize ground-up
            development. Soon, architects and developers will be able to
            generate entire building designs and cost estimates with the power
            of AI.
          </p>
        </div>

        <div className="max-w-xl mx-auto mt-12 sm:px-10">
          {features.map((feature, index) => (
            <div key={index} className="relative pb-10">
              <span
                className="absolute w-px h-full -ml-px bg-gray-200 top-8 left-12"
                aria-hidden="true"
              ></span>
              <div className="absolute -mt-5 inset-y-8 -inset-x-1">
                <div
                  className="w-full h-full mx-auto opacity-30 blur-lg filter"
                  style={{
                    background:
                      "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)",
                  }}
                ></div>
              </div>
              <div className="relative p-5 overflow-hidden bg-white border border-gray-200 rounded-2xl">
                <div className="flex items-start sm:items-center">
                  <div className="inline-flex items-center justify-center flex-shrink-0 text-xl font-bold text-white bg-gray-900 w-14 h-14 rounded-xl">
                    {feature.icon}
                  </div>
                  <div className="ml-6">
                    <h3 className="text-xl font-medium text-gray-900">
                      {feature.title}
                    </h3>
                    <p className="text-gray-600">{feature.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default NewFeaturesSection;
