import ProjectCardCollection from "./projectCardCustomCollection";

const ProjectListPage = () => {
  return (
    <>
      <h2>Projects</h2>
      <ProjectCardCollection />
    </>
  );
};

export default ProjectListPage;
