import React, { useRef, useState } from "react";
import { Button, Flex } from "@aws-amplify/ui-react";
import AvatarEditor from "react-avatar-editor";
import Modal from "react-modal";
import "./style.css";

import { generateClient } from "aws-amplify/api";
import { uploadData } from "aws-amplify/storage";
import { updateCustomer, updateContractor } from "../../../graphql/mutations";

function dataURLToBlob(dataurl) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

const ProfileImageModal = ({
  id,
  role,
  isImageCropperOpen,
  setIsImageCropperOpen,
  imageURL,
  setImageURL,
  setImage,
}) => {
  const client = generateClient();

  const [scale, setScale] = useState(1.2);

  const editorRef = useRef(null);
  const fileInputRef = useRef(null);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImageURL(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSave = () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      const newImage = canvas.toDataURL();
      setImage(newImage);
      const blob = dataURLToBlob(newImage);
      uploadImageAWS(blob);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const uploadImageAWS = async (file) => {
    try {
      const result = await uploadData({
        path: ({ identityId }) => `protected/${identityId}/profile.jpg`,
        data: file,
        options: {
          contentType: "image/jpeg",
        },
      }).result;
      if (role === "customer") {
        updateProfileCustomer(result.path);
      } else if (role === "contractor") {
        updateProfileContractor(result.path);
      }
      setIsImageCropperOpen(false);
    } catch (error) {
      console.error("Error uploading image: ", error);
    }
  };

  const updateProfileCustomer = async (awsPath) => {
    try {
      const result = await client.graphql({
        query: updateCustomer,
        variables: {
          input: {
            id: id,
            photo: awsPath,
          },
        },
      });
    } catch (error) {
      console.error("Error updating customer:", error);
    }
  };

  const updateProfileContractor = async (awsPath) => {
    try {
      const result = await client.graphql({
        query: updateContractor,
        variables: {
          input: {
            id: id,
            photo: awsPath,
          },
        },
      });
    } catch (error) {
      console.error("Error updating customer:", error);
    }
  };

  return (
    <Modal
      ariaHideApp={false}
      className="modal"
      isOpen={isImageCropperOpen}
      onDismiss={() => setIsImageCropperOpen(false)}
    >
      <Flex direction="column" padding="20px" alignItems="center" gap="10px">
        <Button onClick={handleUploadClick}>Upload Image</Button>
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          style={{ display: "none" }}
        />
        <input
          name="scale"
          type="range"
          min="1"
          max="2"
          step="0.01"
          value={scale}
          onChange={(e) => setScale(parseFloat(e.target.value))}
        />
        {imageURL && (
          <AvatarEditor
            ref={editorRef}
            image={imageURL}
            width={250}
            height={250}
            border={50}
            color={[255, 255, 255, 0.6]}
            scale={scale}
            rotate={0}
          />
        )}
        <Button onClick={handleSave}>Save</Button>
        <Button onClick={() => setIsImageCropperOpen(false)}>Close</Button>
      </Flex>
    </Modal>
  );
};

export default ProfileImageModal;
