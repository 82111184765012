import { useState, useEffect } from "react";
import {
  Loader,
  Flex,
  Button,
  Grid,
  Image,
  Text,
  ScrollView,
  SelectField,
  TextAreaField,
} from "@aws-amplify/ui-react";
import { themeImagePaths } from "../../themeImagePaths";
import Modal from "react-modal";

const GenerationModal = ({
  showGenerationModal,
  setShowGenerationModal,
  selectedCategory,
  setSelectedCategory,
  handleGenerateImages,
  prompt,
  setPrompt,
  loading,
}) => {
  const [showLabel, setShowLabel] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const [currentThemeImages, setCurrentThemeImages] = useState([]);

  useEffect(() => {
    if (selectedCategory !== "") {
      setCurrentThemeImages(themeImagePaths[selectedCategory]["style"]);
    }
  }, [selectedCategory]);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setIsSelected(true);
  };

  useEffect(() => {
    if (loading) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }, [loading]);

  return (
    <Modal
      isOpen={showGenerationModal}
      onRequestClose={() => setShowGenerationModal(false)}
      contentLabel="Generate Images"
      shouldCloseOnOverlayClick={!loading}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          padding: "0",
          borderColor: "transparent",
          borderRadius: "20px",
          boxShadow: "0px 0px 5px 0px #AFAFAF",
        },
      }}
    >
      <Flex
        direction="column"
        gap="10px"
        alignItems="stretch"
        borderRadius="20px"
        boxShadow={"0px 0px 5px 0px #AFAFAF"}
        padding="20px 20px 20px 20px"
      >
        {/* # Category Selection */}
        <Flex direction="column" width="355px" marginTop="5px" gap="15px">
          <Text fontSize="20px" fontWeight="700">
            Select Your Room Type
          </Text>
          <SelectField
            onChange={handleCategoryChange}
            gap="0"
            disabled={loading}
            value={selectedCategory}
          >
            <option value="">Select a Room Type</option>
            <option value="bedroom">Bedroom</option>
            <option value="livingroom">Living Room</option>
            <option value="kitchen">Kitchen</option>
          </SelectField>
          {/* # Theme Selection */}
          {isSelected && (
            <>
              <Text fontSize="20px" fontWeight="700">
                Select Style
              </Text>
              <ScrollView maxHeight="350px">
                <Grid templateColumns="repeat(2, 1fr)" gap="1px">
                  {currentThemeImages.map((image, index) => (
                    <div
                      key={index}
                      style={{ position: "relative" }}
                      onMouseEnter={() => setShowLabel(index)}
                      onMouseLeave={() => setShowLabel(null)}
                    >
                      <Button
                        onClick={() => setPrompt(image.prompt)}
                        padding="0"
                        borderColor={"transparent"}
                        disabled={loading}
                      >
                        <Image src={image.path} borderRadius="10px" />
                      </Button>
                      {showLabel === index && (
                        <Text
                          position="absolute"
                          bottom="0"
                          backgroundColor="rgba(0, 0, 0, 0.5)"
                          color="white"
                          width="100%"
                          padding="10px"
                          fontSize="13px"
                          textAlign="center"
                        >
                          {image.label}
                        </Text>
                      )}
                    </div>
                  ))}
                </Grid>
              </ScrollView>
              {/* # Prompt Inputs */}
              <TextAreaField
                label="Prompt"
                placeholder="Enter prompt for image generation"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                disabled={loading}
              />
              <Button
                className="colorful-button"
                borderColor="transparent"
                borderRadius="10px"
                color="white"
                onClick={handleGenerateImages}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Loader marginRight="15px" /> AI Remodeling...
                  </>
                ) : (
                  "Visualize your style"
                )}
              </Button>
            </>
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};

export default GenerationModal;
