/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Autocomplete,
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  ScrollView,
  Text,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import {
  getTask,
  getTaskProject,
  listContractors,
  listProjects,
  listTaskProjects,
} from "../graphql/queries";
import { updateTask } from "../graphql/mutations";
const client = generateClient();
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function TaskUpdateForm(props) {
  const {
    id: idProp,
    task: taskModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    title: "",
    description: "",
    status: "",
    startDate: "",
    endDate: "",
    Project: undefined,
    Contractor: undefined,
    progress: "",
    taskprojectID: undefined,
  };
  const [title, setTitle] = React.useState(initialValues.title);
  const [description, setDescription] = React.useState(
    initialValues.description
  );
  const [status, setStatus] = React.useState(initialValues.status);
  const [startDate, setStartDate] = React.useState(initialValues.startDate);
  const [endDate, setEndDate] = React.useState(initialValues.endDate);
  const [Project, setProject] = React.useState(initialValues.Project);
  const [ProjectLoading, setProjectLoading] = React.useState(false);
  const [projectRecords, setProjectRecords] = React.useState([]);
  const [Contractor, setContractor] = React.useState(initialValues.Contractor);
  const [ContractorLoading, setContractorLoading] = React.useState(false);
  const [contractorRecords, setContractorRecords] = React.useState([]);
  const [progress, setProgress] = React.useState(initialValues.progress);
  const [taskprojectID, setTaskprojectID] = React.useState(
    initialValues.taskprojectID
  );
  const [taskprojectIDLoading, setTaskprojectIDLoading] = React.useState(false);
  const [taskprojectIDRecords, setTaskprojectIDRecords] = React.useState([]);
  const [selectedTaskprojectIDRecords, setSelectedTaskprojectIDRecords] =
    React.useState([]);
  const autocompleteLength = 10;
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = taskRecord
      ? { ...initialValues, ...taskRecord, Project, Contractor, taskprojectID }
      : initialValues;
    setTitle(cleanValues.title);
    setDescription(cleanValues.description);
    setStatus(cleanValues.status);
    setStartDate(cleanValues.startDate);
    setEndDate(cleanValues.endDate);
    setProject(cleanValues.Project);
    setCurrentProjectValue(undefined);
    setCurrentProjectDisplayValue("");
    setContractor(cleanValues.Contractor);
    setCurrentContractorValue(undefined);
    setCurrentContractorDisplayValue("");
    setProgress(cleanValues.progress);
    setTaskprojectID(cleanValues.taskprojectID);
    setCurrentTaskprojectIDValue(undefined);
    setCurrentTaskprojectIDDisplayValue("");
    setErrors({});
  };
  const [taskRecord, setTaskRecord] = React.useState(taskModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getTask.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getTask
        : taskModelProp;
      const ProjectRecord = record ? await record.Project : undefined;
      setProject(ProjectRecord);
      const ContractorRecord = record ? await record.Contractor : undefined;
      setContractor(ContractorRecord);
      const taskprojectIDRecord = record ? record.taskprojectID : undefined;
      const taskProjectRecord = taskprojectIDRecord
        ? (
            await client.graphql({
              query: getTaskProject.replaceAll("__typename", ""),
              variables: { id: taskprojectIDRecord },
            })
          )?.data?.getTaskProject
        : undefined;
      setTaskprojectID(taskprojectIDRecord);
      setSelectedTaskprojectIDRecords([taskProjectRecord]);
      setTaskRecord(record);
    };
    queryData();
  }, [idProp, taskModelProp]);
  React.useEffect(resetStateValues, [
    taskRecord,
    Project,
    Contractor,
    taskprojectID,
  ]);
  const [currentProjectDisplayValue, setCurrentProjectDisplayValue] =
    React.useState("");
  const [currentProjectValue, setCurrentProjectValue] =
    React.useState(undefined);
  const ProjectRef = React.createRef();
  const [currentContractorDisplayValue, setCurrentContractorDisplayValue] =
    React.useState("");
  const [currentContractorValue, setCurrentContractorValue] =
    React.useState(undefined);
  const ContractorRef = React.createRef();
  const [
    currentTaskprojectIDDisplayValue,
    setCurrentTaskprojectIDDisplayValue,
  ] = React.useState("");
  const [currentTaskprojectIDValue, setCurrentTaskprojectIDValue] =
    React.useState(undefined);
  const taskprojectIDRef = React.createRef();
  const getIDValue = {
    Project: (r) => JSON.stringify({ id: r?.id }),
    Contractor: (r) => JSON.stringify({ id: r?.id }),
  };
  const ProjectIdSet = new Set(
    Array.isArray(Project)
      ? Project.map((r) => getIDValue.Project?.(r))
      : getIDValue.Project?.(Project)
  );
  const ContractorIdSet = new Set(
    Array.isArray(Contractor)
      ? Contractor.map((r) => getIDValue.Contractor?.(r))
      : getIDValue.Contractor?.(Contractor)
  );
  const getDisplayValue = {
    Project: (r) => `${r?.projectName ? r?.projectName + " - " : ""}${r?.id}`,
    Contractor: (r) =>
      `${r?.companyName ? r?.companyName + " - " : ""}${r?.id}`,
    taskprojectID: (r) => `${r?.name ? r?.name + " - " : ""}${r?.id}`,
  };
  const validations = {
    title: [],
    description: [],
    status: [],
    startDate: [],
    endDate: [],
    Project: [],
    Contractor: [],
    progress: [],
    taskprojectID: [{ type: "Required" }],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  const fetchProjectRecords = async (value) => {
    setProjectLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [
            { projectName: { contains: value } },
            { id: { contains: value } },
          ],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listProjects.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listProjects?.items;
      var loaded = result.filter(
        (item) => !ProjectIdSet.has(getIDValue.Project?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setProjectRecords(newOptions.slice(0, autocompleteLength));
    setProjectLoading(false);
  };
  const fetchContractorRecords = async (value) => {
    setContractorLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [
            { companyName: { contains: value } },
            { id: { contains: value } },
          ],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listContractors.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listContractors?.items;
      var loaded = result.filter(
        (item) => !ContractorIdSet.has(getIDValue.Contractor?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setContractorRecords(newOptions.slice(0, autocompleteLength));
    setContractorLoading(false);
  };
  const fetchTaskprojectIDRecords = async (value) => {
    setTaskprojectIDLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ name: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listTaskProjects.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listTaskProjects?.items;
      var loaded = result.filter((item) => taskprojectID !== item.id);
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setTaskprojectIDRecords(newOptions.slice(0, autocompleteLength));
    setTaskprojectIDLoading(false);
  };
  React.useEffect(() => {
    fetchProjectRecords("");
    fetchContractorRecords("");
    fetchTaskprojectIDRecords("");
  }, []);
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          title: title ?? null,
          description: description ?? null,
          status: status ?? null,
          startDate: startDate ?? null,
          endDate: endDate ?? null,
          Project: Project ?? null,
          Contractor: Contractor ?? null,
          progress: progress ?? null,
          taskprojectID,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(
                    fieldName,
                    item,
                    getDisplayValue[fieldName]
                  )
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(
                fieldName,
                modelFields[fieldName],
                getDisplayValue[fieldName]
              )
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          const modelFieldsToSave = {
            title: modelFields.title ?? null,
            description: modelFields.description ?? null,
            status: modelFields.status ?? null,
            startDate: modelFields.startDate ?? null,
            endDate: modelFields.endDate ?? null,
            taskProjectId: modelFields?.Project?.id ?? null,
            taskContractorId: modelFields?.Contractor?.id ?? null,
            progress: modelFields.progress ?? null,
            taskprojectID: modelFields.taskprojectID,
          };
          await client.graphql({
            query: updateTask.replaceAll("__typename", ""),
            variables: {
              input: {
                id: taskRecord.id,
                ...modelFieldsToSave,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "TaskUpdateForm")}
      {...rest}
    >
      <TextField
        label="Title"
        isRequired={false}
        isReadOnly={false}
        value={title}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              title: value,
              description,
              status,
              startDate,
              endDate,
              Project,
              Contractor,
              progress,
              taskprojectID,
            };
            const result = onChange(modelFields);
            value = result?.title ?? value;
          }
          if (errors.title?.hasError) {
            runValidationTasks("title", value);
          }
          setTitle(value);
        }}
        onBlur={() => runValidationTasks("title", title)}
        errorMessage={errors.title?.errorMessage}
        hasError={errors.title?.hasError}
        {...getOverrideProps(overrides, "title")}
      ></TextField>
      <TextField
        label="Description"
        isRequired={false}
        isReadOnly={false}
        value={description}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              title,
              description: value,
              status,
              startDate,
              endDate,
              Project,
              Contractor,
              progress,
              taskprojectID,
            };
            const result = onChange(modelFields);
            value = result?.description ?? value;
          }
          if (errors.description?.hasError) {
            runValidationTasks("description", value);
          }
          setDescription(value);
        }}
        onBlur={() => runValidationTasks("description", description)}
        errorMessage={errors.description?.errorMessage}
        hasError={errors.description?.hasError}
        {...getOverrideProps(overrides, "description")}
      ></TextField>
      <TextField
        label="Status"
        isRequired={false}
        isReadOnly={false}
        value={status}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              title,
              description,
              status: value,
              startDate,
              endDate,
              Project,
              Contractor,
              progress,
              taskprojectID,
            };
            const result = onChange(modelFields);
            value = result?.status ?? value;
          }
          if (errors.status?.hasError) {
            runValidationTasks("status", value);
          }
          setStatus(value);
        }}
        onBlur={() => runValidationTasks("status", status)}
        errorMessage={errors.status?.errorMessage}
        hasError={errors.status?.hasError}
        {...getOverrideProps(overrides, "status")}
      ></TextField>
      <TextField
        label="Start date"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={startDate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              title,
              description,
              status,
              startDate: value,
              endDate,
              Project,
              Contractor,
              progress,
              taskprojectID,
            };
            const result = onChange(modelFields);
            value = result?.startDate ?? value;
          }
          if (errors.startDate?.hasError) {
            runValidationTasks("startDate", value);
          }
          setStartDate(value);
        }}
        onBlur={() => runValidationTasks("startDate", startDate)}
        errorMessage={errors.startDate?.errorMessage}
        hasError={errors.startDate?.hasError}
        {...getOverrideProps(overrides, "startDate")}
      ></TextField>
      <TextField
        label="End date"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={endDate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              title,
              description,
              status,
              startDate,
              endDate: value,
              Project,
              Contractor,
              progress,
              taskprojectID,
            };
            const result = onChange(modelFields);
            value = result?.endDate ?? value;
          }
          if (errors.endDate?.hasError) {
            runValidationTasks("endDate", value);
          }
          setEndDate(value);
        }}
        onBlur={() => runValidationTasks("endDate", endDate)}
        errorMessage={errors.endDate?.errorMessage}
        hasError={errors.endDate?.hasError}
        {...getOverrideProps(overrides, "endDate")}
      ></TextField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              title,
              description,
              status,
              startDate,
              endDate,
              Project: value,
              Contractor,
              progress,
              taskprojectID,
            };
            const result = onChange(modelFields);
            value = result?.Project ?? value;
          }
          setProject(value);
          setCurrentProjectValue(undefined);
          setCurrentProjectDisplayValue("");
        }}
        currentFieldValue={currentProjectValue}
        label={"Project"}
        items={Project ? [Project] : []}
        hasError={errors?.Project?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("Project", currentProjectValue)
        }
        errorMessage={errors?.Project?.errorMessage}
        getBadgeText={getDisplayValue.Project}
        setFieldValue={(model) => {
          setCurrentProjectDisplayValue(
            model ? getDisplayValue.Project(model) : ""
          );
          setCurrentProjectValue(model);
        }}
        inputFieldRef={ProjectRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Project"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Project"
          value={currentProjectDisplayValue}
          options={projectRecords
            .filter((r) => !ProjectIdSet.has(getIDValue.Project?.(r)))
            .map((r) => ({
              id: getIDValue.Project?.(r),
              label: getDisplayValue.Project?.(r),
            }))}
          isLoading={ProjectLoading}
          onSelect={({ id, label }) => {
            setCurrentProjectValue(
              projectRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentProjectDisplayValue(label);
            runValidationTasks("Project", label);
          }}
          onClear={() => {
            setCurrentProjectDisplayValue("");
          }}
          defaultValue={Project}
          onChange={(e) => {
            let { value } = e.target;
            fetchProjectRecords(value);
            if (errors.Project?.hasError) {
              runValidationTasks("Project", value);
            }
            setCurrentProjectDisplayValue(value);
            setCurrentProjectValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks("Project", currentProjectDisplayValue)
          }
          errorMessage={errors.Project?.errorMessage}
          hasError={errors.Project?.hasError}
          ref={ProjectRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "Project")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              title,
              description,
              status,
              startDate,
              endDate,
              Project,
              Contractor: value,
              progress,
              taskprojectID,
            };
            const result = onChange(modelFields);
            value = result?.Contractor ?? value;
          }
          setContractor(value);
          setCurrentContractorValue(undefined);
          setCurrentContractorDisplayValue("");
        }}
        currentFieldValue={currentContractorValue}
        label={"Contractor"}
        items={Contractor ? [Contractor] : []}
        hasError={errors?.Contractor?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("Contractor", currentContractorValue)
        }
        errorMessage={errors?.Contractor?.errorMessage}
        getBadgeText={getDisplayValue.Contractor}
        setFieldValue={(model) => {
          setCurrentContractorDisplayValue(
            model ? getDisplayValue.Contractor(model) : ""
          );
          setCurrentContractorValue(model);
        }}
        inputFieldRef={ContractorRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Contractor"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Contractor"
          value={currentContractorDisplayValue}
          options={contractorRecords
            .filter((r) => !ContractorIdSet.has(getIDValue.Contractor?.(r)))
            .map((r) => ({
              id: getIDValue.Contractor?.(r),
              label: getDisplayValue.Contractor?.(r),
            }))}
          isLoading={ContractorLoading}
          onSelect={({ id, label }) => {
            setCurrentContractorValue(
              contractorRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentContractorDisplayValue(label);
            runValidationTasks("Contractor", label);
          }}
          onClear={() => {
            setCurrentContractorDisplayValue("");
          }}
          defaultValue={Contractor}
          onChange={(e) => {
            let { value } = e.target;
            fetchContractorRecords(value);
            if (errors.Contractor?.hasError) {
              runValidationTasks("Contractor", value);
            }
            setCurrentContractorDisplayValue(value);
            setCurrentContractorValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks("Contractor", currentContractorDisplayValue)
          }
          errorMessage={errors.Contractor?.errorMessage}
          hasError={errors.Contractor?.hasError}
          ref={ContractorRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "Contractor")}
        ></Autocomplete>
      </ArrayField>
      <TextField
        label="Progress"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={progress}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              title,
              description,
              status,
              startDate,
              endDate,
              Project,
              Contractor,
              progress: value,
              taskprojectID,
            };
            const result = onChange(modelFields);
            value = result?.progress ?? value;
          }
          if (errors.progress?.hasError) {
            runValidationTasks("progress", value);
          }
          setProgress(value);
        }}
        onBlur={() => runValidationTasks("progress", progress)}
        errorMessage={errors.progress?.errorMessage}
        hasError={errors.progress?.hasError}
        {...getOverrideProps(overrides, "progress")}
      ></TextField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              title,
              description,
              status,
              startDate,
              endDate,
              Project,
              Contractor,
              progress,
              taskprojectID: value,
            };
            const result = onChange(modelFields);
            value = result?.taskprojectID ?? value;
          }
          setTaskprojectID(value);
          setCurrentTaskprojectIDValue(undefined);
        }}
        currentFieldValue={currentTaskprojectIDValue}
        label={"Taskproject id"}
        items={taskprojectID ? [taskprojectID] : []}
        hasError={errors?.taskprojectID?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("taskprojectID", currentTaskprojectIDValue)
        }
        errorMessage={errors?.taskprojectID?.errorMessage}
        getBadgeText={(value) =>
          value
            ? getDisplayValue.taskprojectID(
                taskprojectIDRecords.find((r) => r.id === value) ??
                  selectedTaskprojectIDRecords.find((r) => r.id === value)
              )
            : ""
        }
        setFieldValue={(value) => {
          setCurrentTaskprojectIDDisplayValue(
            value
              ? getDisplayValue.taskprojectID(
                  taskprojectIDRecords.find((r) => r.id === value) ??
                    selectedTaskprojectIDRecords.find((r) => r.id === value)
                )
              : ""
          );
          setCurrentTaskprojectIDValue(value);
          const selectedRecord = taskprojectIDRecords.find(
            (r) => r.id === value
          );
          if (selectedRecord) {
            setSelectedTaskprojectIDRecords([selectedRecord]);
          }
        }}
        inputFieldRef={taskprojectIDRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Taskproject id"
          isRequired={true}
          isReadOnly={false}
          placeholder="Search TaskProject"
          value={currentTaskprojectIDDisplayValue}
          options={taskprojectIDRecords
            .filter(
              (r, i, arr) =>
                arr.findIndex((member) => member?.id === r?.id) === i
            )
            .map((r) => ({
              id: r?.id,
              label: getDisplayValue.taskprojectID?.(r),
            }))}
          isLoading={taskprojectIDLoading}
          onSelect={({ id, label }) => {
            setCurrentTaskprojectIDValue(id);
            setCurrentTaskprojectIDDisplayValue(label);
            runValidationTasks("taskprojectID", label);
          }}
          onClear={() => {
            setCurrentTaskprojectIDDisplayValue("");
          }}
          defaultValue={taskprojectID}
          onChange={(e) => {
            let { value } = e.target;
            fetchTaskprojectIDRecords(value);
            if (errors.taskprojectID?.hasError) {
              runValidationTasks("taskprojectID", value);
            }
            setCurrentTaskprojectIDDisplayValue(value);
            setCurrentTaskprojectIDValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks("taskprojectID", currentTaskprojectIDValue)
          }
          errorMessage={errors.taskprojectID?.errorMessage}
          hasError={errors.taskprojectID?.hasError}
          ref={taskprojectIDRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "taskprojectID")}
        ></Autocomplete>
      </ArrayField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || taskModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || taskModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
