import { useState, useEffect } from "react";
import { StorageManager } from "@aws-amplify/ui-react-storage";
import { list } from "aws-amplify/storage";
import { getCurrentUser } from "aws-amplify/auth";
import {
  Flex,
  Text,
  Table,
  Button,
  Loader,
  TableCell,
  TableBody,
  TableRow,
  TextField,
  ScrollView,
  TextAreaField,
  CheckboxField,
} from "@aws-amplify/ui-react";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import { createBid } from "../../../graphql/mutations";

const client = generateClient();

const ProjectBid = ({ projectItem }) => {
  const oneMonthFromNow = new Date();
  const [expectedStartDate, setExpectedStartDate] = useState(oneMonthFromNow);
  oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);
  const [expectedEndDate, setExpectedEndDate] = useState(oneMonthFromNow);
  const parts = projectItem.images[0].beforeImage.split("/");
  const projectS3Path = parts[parts.length - 2];
  const [descriptionText, setDescriptionText] = useState("");
  const [costText, setCostText] = useState("");
  const [titleText, setTitleText] = useState("");
  const [loading, setLoading] = useState(false);
  const contractorId = localStorage.getItem("id");
  const role = localStorage.getItem("role");

  const navigate = useNavigate();

  const fetchUploadedFiles = async () => {
    try {
      const result = await list({
        path: ({ identityId }) =>
          `protected/${identityId}/project/${projectS3Path}/bid/${contractorId}/`,
      });
      return result;
    } catch (error) {
      console.error("Error fetching uploaded files:", error);
      return [];
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const files = await fetchUploadedFiles();
    const input = {
      title: titleText,
      amount: costText,
      message: [],
      startDate: expectedStartDate.toISOString().slice(0, 10),
      endDate: expectedEndDate.toISOString().slice(0, 10),
      description: descriptionText,
      files: files?.items.map((file) => file.path) || [],
      contractorID: contractorId,
      status: "PENDING",
      projectID: projectItem.id,
    };
    try {
      const newBid = await client.graphql({
        query: createBid,
        variables: {
          input: input,
        },
      });
      setLoading(false);
      if (role === "contractor") {
        navigate("/biddashboard");
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Error creating bid:", error);
      setLoading(false);
    }
  };

  return (
    <Flex
      direction="column"
      gap="1rem"
      padding="20px 20px 20px 20px"
      alignItems="center"
      borderRadius={"20px"}
      boxShadow={"0px 0px 5px 0px #AFAFAF"}
    >
      <Text fontSize="1rem" fontWeight="bold" marginBottom="5px">
        Bid Information
      </Text>
      <Table caption="" highlightOnHover={true} width="440px" size="small">
        <TableBody>
          <TableRow>
            <TableCell fontWeight={"bold"}>Title</TableCell>
            <TableCell>
              <TextField
                size="small"
                placeholder="Write your bid title here"
                style={{ borderColor: "gray", borderWidth: "1px" }}
                value={titleText}
                onChange={(e) => setTitleText(e.target.value)}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell fontWeight={"bold"}>Cost</TableCell>
            <TableCell>
              <TextField
                size="small"
                placeholder="Write your estimate range here"
                style={{ borderColor: "gray", borderWidth: "1px" }}
                value={costText}
                onChange={(e) => setCostText(e.target.value)}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell fontWeight={"bold"}>Period</TableCell>
            <TableCell>
              <Flex direction="row" padding="0" alignItems="center" gap="5px">
                <DatePicker
                  placeholderText="start date"
                  selected={expectedStartDate}
                  onChange={(date) => setExpectedStartDate(date)}
                />
                <Text marginLeft="2px" marginRight="2px">
                  -
                </Text>
                <DatePicker
                  placeholderText="end date"
                  selected={expectedEndDate}
                  onChange={(date) => setExpectedEndDate(date)}
                />
              </Flex>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell fontWeight={"bold"}>Bid Description</TableCell>
            <TableCell>
              <TextAreaField
                size="small"
                rows="10"
                placeholder="Write your description for this project bid here"
                style={{ borderColor: "gray", borderWidth: "1px" }}
                value={descriptionText}
                onChange={(e) => setDescriptionText(e.target.value)}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <ScrollView maxHeight={"300px"} width="520px">
        <StorageManager
          path={({ identityId }) =>
            `protected/${identityId}/project/${projectS3Path}/bid/${contractorId}/`
          }
          autoUpload={false}
          size="small"
          displayText={{
            dropFilesText: "Drop files here to show your detailed plan",
          }}
          maxFileCount={5}
        />
      </ScrollView>
      <CheckboxField
        label="I agree to the terms and conditions"
        value="yes"
        labelPosition="center"
      />
      <Button
        onClick={handleSubmit}
        variation="primary"
        borderColor={"transparent"}
        borderRadius={"10px"}
        disabled={loading}
      >
        {loading ? <Loader /> : "Submit Bid"}
      </Button>
    </Flex>
  );
};

export default ProjectBid;
