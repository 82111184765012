import { useNavigateAction } from "../../../ui-components/utils";
import { Authenticator } from "@aws-amplify/ui-react";
import { Flex } from "@aws-amplify/ui-react";

export default function AuthenticationPage() {
  const navigateHome = useNavigateAction({ type: "url", url: "/" });

  return (
    <Flex height="850px" justifyContent="center">
      <Authenticator>
        {({ signOut, user }) => {
          if (user) {
            navigateHome();
          }
        }}
      </Authenticator>
    </Flex>
  );
}
