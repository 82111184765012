/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Autocomplete,
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  ScrollView,
  Text,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { listContractors, listProjects } from "../graphql/queries";
import { createReview } from "../graphql/mutations";
const client = generateClient();
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function ReviewCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    rating: "",
    comment: "",
    Contractor: undefined,
    Project: undefined,
  };
  const [rating, setRating] = React.useState(initialValues.rating);
  const [comment, setComment] = React.useState(initialValues.comment);
  const [Contractor, setContractor] = React.useState(initialValues.Contractor);
  const [ContractorLoading, setContractorLoading] = React.useState(false);
  const [contractorRecords, setContractorRecords] = React.useState([]);
  const [Project, setProject] = React.useState(initialValues.Project);
  const [ProjectLoading, setProjectLoading] = React.useState(false);
  const [projectRecords, setProjectRecords] = React.useState([]);
  const autocompleteLength = 10;
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setRating(initialValues.rating);
    setComment(initialValues.comment);
    setContractor(initialValues.Contractor);
    setCurrentContractorValue(undefined);
    setCurrentContractorDisplayValue("");
    setProject(initialValues.Project);
    setCurrentProjectValue(undefined);
    setCurrentProjectDisplayValue("");
    setErrors({});
  };
  const [currentContractorDisplayValue, setCurrentContractorDisplayValue] =
    React.useState("");
  const [currentContractorValue, setCurrentContractorValue] =
    React.useState(undefined);
  const ContractorRef = React.createRef();
  const [currentProjectDisplayValue, setCurrentProjectDisplayValue] =
    React.useState("");
  const [currentProjectValue, setCurrentProjectValue] =
    React.useState(undefined);
  const ProjectRef = React.createRef();
  const getIDValue = {
    Contractor: (r) => JSON.stringify({ id: r?.id }),
    Project: (r) => JSON.stringify({ id: r?.id }),
  };
  const ContractorIdSet = new Set(
    Array.isArray(Contractor)
      ? Contractor.map((r) => getIDValue.Contractor?.(r))
      : getIDValue.Contractor?.(Contractor)
  );
  const ProjectIdSet = new Set(
    Array.isArray(Project)
      ? Project.map((r) => getIDValue.Project?.(r))
      : getIDValue.Project?.(Project)
  );
  const getDisplayValue = {
    Contractor: (r) =>
      `${r?.companyName ? r?.companyName + " - " : ""}${r?.id}`,
    Project: (r) => `${r?.projectName ? r?.projectName + " - " : ""}${r?.id}`,
  };
  const validations = {
    rating: [],
    comment: [],
    Contractor: [],
    Project: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  const fetchContractorRecords = async (value) => {
    setContractorLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [
            { companyName: { contains: value } },
            { id: { contains: value } },
          ],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listContractors.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listContractors?.items;
      var loaded = result.filter(
        (item) => !ContractorIdSet.has(getIDValue.Contractor?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setContractorRecords(newOptions.slice(0, autocompleteLength));
    setContractorLoading(false);
  };
  const fetchProjectRecords = async (value) => {
    setProjectLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [
            { projectName: { contains: value } },
            { id: { contains: value } },
          ],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listProjects.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listProjects?.items;
      var loaded = result.filter(
        (item) => !ProjectIdSet.has(getIDValue.Project?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setProjectRecords(newOptions.slice(0, autocompleteLength));
    setProjectLoading(false);
  };
  React.useEffect(() => {
    fetchContractorRecords("");
    fetchProjectRecords("");
  }, []);
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          rating,
          comment,
          Contractor,
          Project,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(
                    fieldName,
                    item,
                    getDisplayValue[fieldName]
                  )
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(
                fieldName,
                modelFields[fieldName],
                getDisplayValue[fieldName]
              )
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          const modelFieldsToSave = {
            rating: modelFields.rating,
            comment: modelFields.comment,
            reviewContractorId: modelFields?.Contractor?.id,
            reviewProjectId: modelFields?.Project?.id,
          };
          await client.graphql({
            query: createReview.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFieldsToSave,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "ReviewCreateForm")}
      {...rest}
    >
      <TextField
        label="Rating"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={rating}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              rating: value,
              comment,
              Contractor,
              Project,
            };
            const result = onChange(modelFields);
            value = result?.rating ?? value;
          }
          if (errors.rating?.hasError) {
            runValidationTasks("rating", value);
          }
          setRating(value);
        }}
        onBlur={() => runValidationTasks("rating", rating)}
        errorMessage={errors.rating?.errorMessage}
        hasError={errors.rating?.hasError}
        {...getOverrideProps(overrides, "rating")}
      ></TextField>
      <TextField
        label="Comment"
        isRequired={false}
        isReadOnly={false}
        value={comment}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              rating,
              comment: value,
              Contractor,
              Project,
            };
            const result = onChange(modelFields);
            value = result?.comment ?? value;
          }
          if (errors.comment?.hasError) {
            runValidationTasks("comment", value);
          }
          setComment(value);
        }}
        onBlur={() => runValidationTasks("comment", comment)}
        errorMessage={errors.comment?.errorMessage}
        hasError={errors.comment?.hasError}
        {...getOverrideProps(overrides, "comment")}
      ></TextField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              rating,
              comment,
              Contractor: value,
              Project,
            };
            const result = onChange(modelFields);
            value = result?.Contractor ?? value;
          }
          setContractor(value);
          setCurrentContractorValue(undefined);
          setCurrentContractorDisplayValue("");
        }}
        currentFieldValue={currentContractorValue}
        label={"Contractor"}
        items={Contractor ? [Contractor] : []}
        hasError={errors?.Contractor?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("Contractor", currentContractorValue)
        }
        errorMessage={errors?.Contractor?.errorMessage}
        getBadgeText={getDisplayValue.Contractor}
        setFieldValue={(model) => {
          setCurrentContractorDisplayValue(
            model ? getDisplayValue.Contractor(model) : ""
          );
          setCurrentContractorValue(model);
        }}
        inputFieldRef={ContractorRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Contractor"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Contractor"
          value={currentContractorDisplayValue}
          options={contractorRecords
            .filter((r) => !ContractorIdSet.has(getIDValue.Contractor?.(r)))
            .map((r) => ({
              id: getIDValue.Contractor?.(r),
              label: getDisplayValue.Contractor?.(r),
            }))}
          isLoading={ContractorLoading}
          onSelect={({ id, label }) => {
            setCurrentContractorValue(
              contractorRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentContractorDisplayValue(label);
            runValidationTasks("Contractor", label);
          }}
          onClear={() => {
            setCurrentContractorDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchContractorRecords(value);
            if (errors.Contractor?.hasError) {
              runValidationTasks("Contractor", value);
            }
            setCurrentContractorDisplayValue(value);
            setCurrentContractorValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks("Contractor", currentContractorDisplayValue)
          }
          errorMessage={errors.Contractor?.errorMessage}
          hasError={errors.Contractor?.hasError}
          ref={ContractorRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "Contractor")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              rating,
              comment,
              Contractor,
              Project: value,
            };
            const result = onChange(modelFields);
            value = result?.Project ?? value;
          }
          setProject(value);
          setCurrentProjectValue(undefined);
          setCurrentProjectDisplayValue("");
        }}
        currentFieldValue={currentProjectValue}
        label={"Project"}
        items={Project ? [Project] : []}
        hasError={errors?.Project?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("Project", currentProjectValue)
        }
        errorMessage={errors?.Project?.errorMessage}
        getBadgeText={getDisplayValue.Project}
        setFieldValue={(model) => {
          setCurrentProjectDisplayValue(
            model ? getDisplayValue.Project(model) : ""
          );
          setCurrentProjectValue(model);
        }}
        inputFieldRef={ProjectRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Project"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Project"
          value={currentProjectDisplayValue}
          options={projectRecords
            .filter((r) => !ProjectIdSet.has(getIDValue.Project?.(r)))
            .map((r) => ({
              id: getIDValue.Project?.(r),
              label: getDisplayValue.Project?.(r),
            }))}
          isLoading={ProjectLoading}
          onSelect={({ id, label }) => {
            setCurrentProjectValue(
              projectRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentProjectDisplayValue(label);
            runValidationTasks("Project", label);
          }}
          onClear={() => {
            setCurrentProjectDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchProjectRecords(value);
            if (errors.Project?.hasError) {
              runValidationTasks("Project", value);
            }
            setCurrentProjectDisplayValue(value);
            setCurrentProjectValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks("Project", currentProjectDisplayValue)
          }
          errorMessage={errors.Project?.errorMessage}
          hasError={errors.Project?.hasError}
          ref={ProjectRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "Project")}
        ></Autocomplete>
      </ArrayField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
