import {
  Text,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TextField,
} from "@aws-amplify/ui-react";

const CostEstimationTable = ({
  costEstimation,
  setCostEstimation,
  editable,
}) => {
  const handleCostChange = (idx, field, value) => {
    const updatedCosts = [...costEstimation];
    updatedCosts[idx][field] = value;
    setCostEstimation(updatedCosts);
  };

  return (
    <Table variation="bordered" highlightOnHover={true}>
      <TableHead>
        <TableRow>
          <TableCell as="th">Component</TableCell>
          <TableCell as="th">
            Material/ <br />
            Labor Cost
          </TableCell>
          <TableCell as="th">Total</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {costEstimation.map((cost, idx) => (
          <TableRow key={`row-${idx}`}>
            <TableCell>
              <Text fontWeight="700">{cost.component}</Text>
              {cost.description}
            </TableCell>
            <TableCell>
              <TextField
                // type="number"
                size="small"
                value={cost.materialCost}
                onChange={(e) =>
                  handleCostChange(idx, "materialCost", e.target.value)
                }
                disabled={!editable}
              />
              <TextField
                // type="number"
                size="small"
                value={cost.laborCost}
                onChange={(e) =>
                  handleCostChange(idx, "laborCost", e.target.value)
                }
                disabled={!editable}
              />
            </TableCell>
            <TableCell>
              $
              {parseFloat(cost.materialCost || 0) +
                parseFloat(cost.laborCost || 0)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CostEstimationTable;
