import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getCurrentUser } from "aws-amplify/auth";
import { useAuthSignOutAction } from "../../ui-components/utils";
import { Logo } from "../imagepath";

const Header = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const logoutOnClick = useAuthSignOutAction({ global: false });
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    checkUser();
  }, []);

  const checkUser = async () => {
    try {
      const { userId } = await getCurrentUser();
      setIsAuthenticated(true);
    } catch (err) {
      setIsAuthenticated(false);
    }
  };

  const handleLogout = () => {
    logoutOnClick();
    setIsAuthenticated(false);
    navigate("/");
    localStorage.removeItem("role");
    localStorage.removeItem("id");
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navItems = [
    ...(role === "contractor" ? [{ label: "Leads", link: "/projects" }] : []),
    ...(isAuthenticated
      ? [{ label: "Dashboard", link: "/dashboard/projects" }]
      : []),
  ];

  return (
    <header className="bg-transparent text-gray-900">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <nav className="relative flex justify-between items-center h-16 bg-white lg:rounded-md lg:h-24 lg:px-8 lg:py-6">
          <Link to="/" className="flex items-center">
            <img src={Logo} alt="Resia Logo" className="h-10 w-auto" />
          </Link>
          <div className="hidden md:flex lg:flex lg:items-center lg:space-x-10">
            {navItems.map((item) => (
              <Link
                key={item.label}
                to={item.link}
                className="text-base font-bold text-gray-900 hover:text-gray-600 transition duration-200"
              >
                {item.label}
              </Link>
            ))}
          </div>
          <div className="hidden md:flex lg:flex lg:items-center lg:space-x-10 ml-8">
            {isAuthenticated ? (
              <>
                <button
                  onClick={handleLogout}
                  className="text-base font-bold text-gray-900 hover:text-gray-600 transition duration-200"
                >
                  Logout
                </button>
                <Link
                  to="/create"
                  className="bg-gradient-to-r from-pink-500 to-yellow-500 text-white font-bold py-2 px-4 rounded transition-transform transform hover:scale-105"
                >
                  Get Started
                </Link>
              </>
            ) : (
              <>
                <Link
                  to="/login"
                  className="text-base font-bold text-gray-900 hover:text-gray-600 transition duration-200"
                >
                  Login
                </Link>
                <Link
                  to="/create"
                  className="bg-gradient-to-r from-pink-500 to-yellow-500 text-white font-bold py-2 px-4 rounded transition-transform transform hover:scale-105"
                >
                  Get Started
                </Link>
              </>
            )}
          </div>
          <button
            onClick={handleDrawerToggle}
            className="md:hidden text-gray-900"
            aria-label="open drawer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </nav>
      </div>
      {mobileOpen && (
        <div className="w-full md:hidden bg-white shadow-lg">
          <div className="flex flex-col items-center py-4">
            {navItems.map((item) => (
              <Link
                key={item.label}
                to={item.link}
                className="text-base font-bold text-gray-900 hover:text-gray-600 transition duration-200"
                onClick={handleDrawerToggle}
              >
                {item.label}
              </Link>
            ))}
            {isAuthenticated ? (
              <>
                <button
                  onClick={handleLogout}
                  className="text-base font-bold text-gray-900 hover:text-gray-600 transition duration-200"
                >
                  Logout
                </button>
                <Link
                  to="/create"
                  className="bg-gradient-to-r from-pink-500 to-yellow-500 text-white font-bold py-2 px-4 rounded transition-transform transform hover:scale-105"
                >
                  Get Started
                </Link>
              </>
            ) : (
              <>
                <Link
                  to="/login"
                  className="text-base font-bold text-gray-900 hover:text-gray-600 transition duration-200"
                  onClick={handleDrawerToggle}
                >
                  Login
                </Link>
                <Link
                  to="/create"
                  className="bg-gradient-to-r from-pink-500 to-yellow-500 text-white font-bold py-2 px-4 rounded transition-transform transform hover:scale-105"
                  onClick={handleDrawerToggle}
                >
                  Get Started
                </Link>
              </>
            )}
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
