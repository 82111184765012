import React from "react";
import YouTube from "react-youtube";

const VideoModal = ({ isOpen, onClose, videoId }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative min-w-[90%] min-h-[90%] bg-white shadow-lg p-4 rounded-lg flex flex-col">
        <button
          aria-label="close"
          onClick={onClose}
          className="self-end text-gray-500 hover:text-gray-700 text-xl"
        >
          &times;
        </button>
        <YouTube
          videoId={videoId}
          opts={{
            width: "100%",
            height: "100%",
            playerVars: {
              autoplay: 1,
            },
          }}
          className="absolute inset-14"
        />
      </div>
    </div>
  );
};

export default VideoModal;
