import { useState } from "react";
import Modal from "react-modal";
import {
  Text,
  Flex,
  Image,
  Badge,
  Button,
  TextField,
  SelectField,
  Autocomplete,
} from "@aws-amplify/ui-react";
import { generateClient } from "aws-amplify/api";
import { Logo } from "../../imagepath";
import {
  createUsers,
  createCustomer,
  createContractor,
} from "../../../graphql/mutations";
import { Geo } from "@aws-amplify/geo";

const DEFAULT_CREDITS = 20;

const client = generateClient();

const WelcomeModal = ({ user, userAttributes, isOpen, closeModal }) => {
  const [name, setName] = useState("");
  const [roleType, setRoleType] = useState("customer");
  const [location, setLocation] = useState({});
  const [zipcodeResult, setZipcodeResult] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const expertises = [
    "General Contractor",
    "Plumber",
    "Electrician",
    "Carpenter",
    "Painter",
    "Roofing Contractor",
    "Flooring Contractor",
    "HVAC Contractor",
    "Landscaper",
    "Mason",
    "Etc...",
  ];
  const [selectedExpertises, setSelectedExpertises] = useState([]);

  const handleExpertiseSelect = (option) => {
    if (!selectedExpertises.includes(option.label)) {
      setSelectedExpertises([...selectedExpertises, option.label]);
    }
  };

  const removeExpertise = (expertise) => {
    setSelectedExpertises(selectedExpertises.filter((e) => e !== expertise));
  };

  const handleZipcodeChange = async (event) => {
    const zipcode = event.target.value;
    const results = await Geo.searchByText(zipcode, {
      MaxResults: 5,
      countries: ["USA"],
    });
    if (results.length > 0) {
      const uniqueResults = Array.from(
        new Set(results.map((result) => result.label))
      ).map((label) => {
        return results.find((result) => result.label === label);
      });
      const zipAutoComplete = uniqueResults.map((result, idx) => ({
        id: result.label,
        label: result.label,
        location: {
          city: result.municipality,
          state: result.region,
          address1: "",
          address2: "",
          zipCode: result.postalCode,
        },
      }));
      setZipcodeResult(zipAutoComplete);
    }
  };

  const handleZipcodeSelect = (option) => {
    let newLocation;
    if (!option.location) {
      newLocation = {
        city: option.municipality,
        state: option.region,
        address1: "",
        address2: "",
        zipCode: option.postalCode.includes("-")
          ? option.postalCode.split("-")[0]
          : option.postalCode,
      };
    } else {
      newLocation = option.location;
    }
    setLocation(newLocation);
  };

  const handleSubmmit = async () => {
    let roleId = null;

    try {
      if (roleType === "customer") {
        try {
          const newCustomer = await client.graphql({
            query: createCustomer,
            variables: {
              input: {
                name: name,
                email: userAttributes.email,
                phone: null,
                location: location,
                photo: null,
                status: "active",
                paidStatus: DEFAULT_CREDITS,
              },
            },
          });
          roleId = newCustomer.data.createCustomer.id;
        } catch (error) {
          console.error("Error creating customer:", error);
          return;
        }
      } else if (roleType === "contractor") {
        try {
          const newContractor = await client.graphql({
            query: createContractor,
            variables: {
              input: {
                name: name,
                companyName: companyName,
                website: null,
                socialNetworkLinks: null,
                expertises: selectedExpertises,
                phone: null,
                email: userAttributes.email,
                location: location,
                photo: null,
                status: "active",
                paidStatus: DEFAULT_CREDITS,
              },
            },
          });
          roleId = newContractor.data.createContractor.id;
        } catch (error) {
          console.error("Error creating contractor:", error);
          return;
        }
      }

      try {
        const newUsers = await client.graphql({
          query: createUsers,
          variables: {
            input: {
              userId: user,
              role: roleType,
              roleId: roleId,
            },
          },
        });
      } catch (error) {
        console.error("Error creating user:", error);
        return;
      }

      closeModal();
      window.location.reload();
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  return (
    <Modal
      appElement={document.querySelector("#root")}
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Welcome Modal"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          padding: "3rem",
          transform: "translate(-50%, -50%)",
          borderRadius: "20px",
        },
      }}
    >
      <Flex direction="column" gap="1rem" alignItems="center">
        <Image
          src={Logo}
          alt="logo"
          width="200px"
          marginLeft="-10px"
          marginRight="-15px"
          marginTop="-20px"
          marginBottom="20px"
        />
        {/* <Text>
          Resia is a platform for homeowners to post their home renovation
          projects and for contractors to bid on those projects. We hope you
          find the perfect match for your project!
        </Text> */}
        <Flex direction="column" gap="1rem">
          <TextField
            label="What's your name?"
            onChange={(e) => setName(e.target.value)}
          />
          <SelectField
            label="Who are you?"
            onChange={(e) => setRoleType(e.target.value)}
          >
            <option value="customer">Customer</option>
            <option value="contractor">Contractor</option>
            <option value="estator">Real Estate Expert</option>
          </SelectField>
          <Autocomplete
            label="Where do you want to remodel/work?"
            labelHidden={false}
            options={zipcodeResult}
            placeholder="Search ZipCode..."
            onChange={handleZipcodeChange}
            hasSearchButton={true}
            onSelect={handleZipcodeSelect}
          />
          {roleType === "contractor" || roleType === "estator" ? (
            <>
              <TextField
                label="Company Name"
                onChange={(e) => setCompanyName(e.target.value)}
              />
              <Text>What are your expertises?</Text>
              <Autocomplete
                options={expertises.map((expertise, idx) => ({
                  id: expertise,
                  label: expertise,
                }))}
                placeholder="Choose expertise..."
                onSelect={handleExpertiseSelect}
              />
              <Flex direction="row" gap="1rem" marginBottom="30px">
                {selectedExpertises.map((expertise, idx) => (
                  <Badge
                    key={idx}
                    onClick={() => removeExpertise(expertise)}
                    style={{ cursor: "pointer" }}
                  >
                    {expertise} X
                  </Badge>
                ))}
              </Flex>
            </>
          ) : null}
        </Flex>
        <Button onClick={handleSubmmit} variation="primary">
          Submit
        </Button>
      </Flex>
    </Modal>
  );
};

export default WelcomeModal;
