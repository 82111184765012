/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "SageMakerStableDiffusionInpaint",
            "endpoint": "https://uef30r56o3.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        },
        {
            "name": "T2Iadapter",
            "endpoint": "https://55u4r0ur4i.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        },
        {
            "name": "openaiAPI",
            "endpoint": "https://d7sh3zizo4.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        },
        {
            "name": "payment",
            "endpoint": "https://c3h0cc7bc7.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://z3tdewv7fjazlatgjpednumwti.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-2p34pwtpzbgkljni6isktzi7zi",
    "aws_cognito_identity_pool_id": "us-east-2:a231d66c-0187-4438-bc90-2d297c87d7cf",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_UOBCozNYd",
    "aws_user_pools_web_client_id": "6d030n0h7sv9tb3cj8j90oc4uo",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "resiaremodel-storage-9c79dba1f1d83-dev",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "geo": {
        "amazon_location_service": {
            "region": "us-east-2",
            "search_indices": {
                "items": [
                    "placeIndexa4a08331-dev"
                ],
                "default": "placeIndexa4a08331-dev"
            }
        }
    }
};


export default awsmobile;
