import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Text,
  Flex,
  Image,
  ScrollView,
  Button,
  SwitchField,
} from "@aws-amplify/ui-react";
import Modal from "react-modal";
import { FaXmark } from "react-icons/fa6";
import GenerationModal from "./generationModal";
import { post } from "aws-amplify/api";
import GenerationView from "./generationView";
import { encodeBlobToBase64 } from "../imageUtils";
import "../style.css";

import { getCustomer, getContractor } from "../../../../graphql/queries";
import {
  updateCustomer,
  updateContractor,
} from "../../../../graphql/mutations";
import { generateClient } from "aws-amplify/api";
import { useNavigate } from "react-router-dom";

Modal.setAppElement("#root");

const ImageGenerationPage = ({
  imageItems,
  setImageItems,
  showGenExample,
  setShowGenExample,
  setShowExInfo,
  selectedItems,
  setSelectedItems,
  selectedHearts,
  setSelectedHearts,
  isDebug,
}) => {
  const client = generateClient();
  const navigate = useNavigate();
  const [showGenerationModal, setShowGenerationModal] = useState(false);
  const [showBigImageModal, setShowBigImageModal] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentCredit, setCurrentCredit] = useState(2);

  const id = localStorage.getItem("id");
  const role = localStorage.getItem("role");

  const hiddenInput = useRef(null);
  const seed = Math.floor(Math.random() * 100) + 1;

  useEffect(() => {
    const fetchCredit = async () => {
      const credit = await getCurrentCredit();
      setCurrentCredit(credit);
    };
    if (id) {
      fetchCredit();
    }
  }, [loading]);

  useEffect(() => {
    if (currentCredit <= 0) {
      setShowPricingModal(true);
    }
  }, [currentCredit]);

  const onFilePickerChange = (event) => {
    const { files } = event.target;
    if (!files || files.length === 0) {
      return;
    }
    handleFileUpload(Array.from(files));
  };

  const handleFileUpload = (files) => {
    const newImages = files.map((file) => ({
      original: URL.createObjectURL(file),
      file: file,
      generated: [],
      category: "",
      isExample: false,
    }));
    setImageItems((prevItems) => [...prevItems, ...newImages]);
    setCurrentImageIndex(imageItems.length);
    setShowGenerationModal(true);
  };

  const getCurrentCredit = async () => {
    let query;
    if (role === "customer") {
      query = getCustomer.replaceAll("__typename", "");
    } else {
      query = getContractor.replaceAll("__typename", "");
    }

    const result = await client.graphql({
      query,
      variables: { id },
    });

    if (result && result.data) {
      if (role === "customer" && result.data.getCustomer) {
        return result.data.getCustomer.paidStatus;
      } else if (role !== "customer" && result.data.getContractor) {
        return result.data.getContractor.paidStatus;
      }
    }

    return 0;
  };

  const decrementCredit = async (credit) => {
    try {
      let query;
      if (role === "customer") {
        query = updateCustomer.replaceAll("__typename", "");
      } else {
        query = updateContractor.replaceAll("__typename", "");
      }

      const response = await client.graphql({
        query,
        variables: {
          input: {
            id,
            paidStatus: credit - 1,
          },
        },
      });

      if (response) {
        setCurrentCredit(credit - 1);
        // console.log("Successfully decremented credit");
      }
    } catch (error) {
      console.log("Error updating credit:", error);
    }
  };

  const handleGenerateImages = async () => {
    setLoading(true);

    const currentImage = imageItems[currentImageIndex];

    if (isDebug) {
      setShowGenerationModal(false);
      const newGeneratedImages = [
        {
          src: currentImage.original,
          alt: "Debugging image",
        },
      ];
      setImageItems((prevItems) => {
        const newItems = [...prevItems];
        newItems[currentImageIndex] = {
          ...newItems[currentImageIndex],
          generated: [
            ...newItems[currentImageIndex].generated,
            ...newGeneratedImages,
          ],
          category: "kitchen",
        };
        return newItems;
      });
      return;
    }

    let credit;
    if (id) {
      credit = await getCurrentCredit();
      if (credit <= 0) {
        console.error("Insufficient credits");
        setLoading(false);
        return;
      }
    } else {
      credit = currentCredit;
    }

    const encodedImage = await encodeBlobToBase64(currentImage.original);

    const payload = {
      body: {
        prompt: prompt,
        input_image: encodedImage,
        run_mode: "segment",
        seed,
      },
    };

    try {
      const restOperation = post({
        apiName: "openaiAPI",
        path: "/resia-gen",
        options: {
          body: payload,
          headers: {
            "Content-Type": "application/json",
          },
        },
      });
      const { body } = await restOperation.response;
      const response = await body.json();
      const responseImage = JSON.parse(response.body).image;
      const newGeneratedImage = {
        src: `data:image/png;base64,${responseImage}`,
        alt: `Generated image`,
      };

      setImageItems((prevItems) => {
        const newItems = [...prevItems];
        newItems[currentImageIndex] = {
          ...newItems[currentImageIndex],
          generated: [
            ...newItems[currentImageIndex].generated,
            newGeneratedImage,
          ],
          category: selectedCategory,
        };
        return newItems;
      });
      if (id) {
        await decrementCredit(credit);
      } else {
        setCurrentCredit(credit - 1);
      }
      setShowGenerationModal(false);
    } catch (e) {
      console.error("Error invoking API:", e);
    } finally {
      setLoading(false);
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowBigImageModal(true);
  };

  const handleHeartClick = (oriIndex, genIndex) => {
    setSelectedHearts((prevSelectedHearts) => {
      const newSelectedHearts = { ...prevSelectedHearts };
      if (newSelectedHearts[oriIndex] === genIndex) {
        // Deselect if the same heart is clicked again
        delete newSelectedHearts[oriIndex];
        if (selectedItems[genIndex].isExample) {
          setShowExInfo(false);
        }
        setSelectedItems((prev) =>
          prev.filter((item) => item.oriIndex !== oriIndex)
        );
      } else {
        // Select the new heart
        newSelectedHearts[oriIndex] = genIndex;
        const selectedImageItem = imageItems[oriIndex];
        setSelectedItems((prev) => [
          ...prev.filter((item) => item.oriIndex !== oriIndex),
          {
            original: selectedImageItem.original,
            generated: selectedImageItem.generated[genIndex].src,
            file: selectedImageItem.file,
            category: selectedImageItem.category,
            inpainted: [selectedImageItem.generated[genIndex].src],
            cost: [],
            description: "",
            idx: prev.length,
            isMain: prev.length === 0 ? true : false,
            oriIndex,
            isExample: selectedImageItem.isExample,
          },
        ]);
        if (selectedImageItem.isExample) {
          setShowExInfo(true);
        }
      }
      return newSelectedHearts;
    });
  };

  const handleGoToPricing = () => {
    setShowPricingModal(false);
    navigate("/#pricing");
  };

  return (
    <Flex
      className="create-flex"
      direction="column"
      gap="20px"
      alignItems="center"
      padding="20px"
      borderRadius="20px"
    >
      <Flex
        direction="row"
        gap="20px"
        justifyContent="center"
        alignItems="center"
      >
        <SwitchField
          label="Show example"
          trackCheckedColor="green.60"
          size="large"
          onChange={() => setShowGenExample(!showGenExample)}
          isChecked={showGenExample}
        />
        <Text fontSize="large" color="gray.60">
          {currentCredit} credits remaining
        </Text>
      </Flex>
      <ScrollView autoScroll="smooth">
        <GenerationView
          imageItems={imageItems}
          hiddenInput={hiddenInput}
          showGenExample={showGenExample}
          selectedHearts={selectedHearts}
          handleFileUpload={handleFileUpload}
          handleImageClick={handleImageClick}
          handleHeartClick={handleHeartClick}
          onFilePickerChange={onFilePickerChange}
          setCurrentImageIndex={setCurrentImageIndex}
          setShowGenerationModal={setShowGenerationModal}
        />
      </ScrollView>

      <GenerationModal
        showGenerationModal={showGenerationModal}
        setShowGenerationModal={setShowGenerationModal}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        handleGenerateImages={handleGenerateImages}
        prompt={prompt}
        setPrompt={setPrompt}
        loading={loading}
      />

      <ScrollView
        height="150px"
        direction="horizontal"
        borderRadius="medium"
        style={{ marginTop: "20px" }}
      >
        <Flex direction="row" gap="20px" padding="10px">
          {selectedItems?.map((img, idx) => (
            <Card
              key={idx}
              borderRadius="medium"
              variation="elevated"
              padding="0"
            >
              <Image
                src={img.generated}
                alt={idx}
                width="150px"
                height="auto"
                onClick={() => handleImageClick(img.generated)}
                borderRadius="medium"
                borderColor={"transparent"}
                padding={0}
                style={{
                  cursor: "pointer",
                }}
              />
            </Card>
          ))}
        </Flex>
      </ScrollView>

      <Modal
        isOpen={showBigImageModal}
        onRequestClose={() => setShowBigImageModal(false)}
        contentLabel="Big Image View"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <Flex direction="column" gap="10px" justifyContent="flex-end">
          <Button
            alignSelf="end"
            size="small"
            padding="10px"
            onClick={() => setShowBigImageModal(false)}
          >
            <FaXmark />
          </Button>
          <Image
            src={selectedImage}
            alt="Big view"
            width="auto"
            height="80vh"
            style={{
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          />
        </Flex>
      </Modal>

      <Modal
        isOpen={showPricingModal}
        onRequestClose={() => setShowPricingModal(false)}
        contentLabel="Low Credits"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <Flex
          direction="column"
          gap="10px"
          alignContent="center"
          alignItems="center"
        >
          <Text fontSize="large" color="gray.60">
            Please add more credits to continue. <br />
            Do you want to buy more credits?
          </Text>
          <Flex direction="row" gap="10px" alignContent="center">
            <Button
              borderColor="transparent"
              backgroundColor="green.60"
              color="white"
              size="small"
              padding="10px"
              borderRadius="10px"
              onClick={() => handleGoToPricing()}
            >
              Yes
            </Button>
            <Button
              colorTheme="error"
              borderColor="gray"
              color="gray"
              size="small"
              padding="10px"
              borderRadius="10px"
              onClick={() => setShowPricingModal(false)}
            >
              No
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  );
};

export default ImageGenerationPage;
