import {
  BedrockRuntimeClient,
  InvokeModelCommand,
} from "@aws-sdk/client-bedrock-runtime";
import { fetchAuthSession } from "aws-amplify/auth";

const SYS_CAPTION_PROMPT =
  "You are an expert image description generator specializing in generating detailed and precise prompts for inpainting models. Your task is to provide thorough and specific descriptions of the scene or object in the image to guide the inpainting model accurately. The descriptions should include type, style, material, color, size, and any distinguishing features. The final description should be formatted as a single, continuous string without any special characters or line breaks.";
const USER_CAPTION_PROMPT =
  "Generate a comprehensive and detailed description of the item in the image within two sentences. Include the type of item (or furniture), style, material, color, size, and any distinguishing features. Ensure the description is specific and clear, avoiding any special characters or line breaks, so it can be used directly as a prompt for an inpainting model.";
const SYS_COST_PROMPT =
  "You are a detailed cost estimation assistant specializing in home remodeling projects. Your task is to analyze images of remodeling projects and break down the costs for various components involved. For each component, provide a description, materialCost, laborCost and any relevant details such as material, size, and labor required. Make sure to be as specific and detailed as possible to help guide the cost estimation accurately.";

function generateCostUserPrompt(roomCategory) {
  return `
    Given the before and after images of the remodeled ${roomCategory}, please provide a detailed cost breakdown for the renovation. Analyze the differences between the images to identify all changed components and new additions. For each identified change or addition:

    1. Describe the modification or new element
    2. Estimate the cost of materials, mid range cost.
    3. Estimate the associated labor cost

    Please structure your response as a JSON object. Each identified component should be a key, with nested objects containing the description, estimated material cost, and estimated labor cost. Do not limit your analysis to predefined categories; include all noticeable changes and additions.

    Here's the expected structure:

    {
      "component name": {
        "description": "A brief description of the change or addition",
        "materialCost": "Estimated cost of materials in dollars",
        "laborCost": "Estimated cost of labor in dollars"
      }
    }

    Ensure your response is formatted as a single JSON object. Provide the estimated costs within 1500 max_tokens. Do not include any text before or after the JSON object.
  `;
}

async function getBedrockRuntimeClient() {
  const authSession = await fetchAuthSession();
  const credentials = authSession.credentials;
  return new BedrockRuntimeClient({
    region: "us-east-1",
    apiVersion: "2023-09-30",
    credentials: {
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      sessionToken: credentials.sessionToken,
    },
  });
}

async function invokeModel(
  bedrockruntime,
  modelId,
  systemPrompt,
  userContent,
  maxTokens
) {
  const result = await bedrockruntime.send(
    new InvokeModelCommand({
      modelId,
      contentType: "application/json",
      accept: "application/json",
      body: JSON.stringify({
        max_tokens: maxTokens,
        temperature: 1,
        anthropic_version: "bedrock-2023-05-31",
        system: systemPrompt,
        messages: [
          {
            role: "user",
            content: userContent,
          },
        ],
      }),
    })
  );

  const response = Object.values(result.body)
    .map((code) => String.fromCharCode(code))
    .join("");
  return JSON.parse(response);
}

export async function callCostEstimationAPI(imageBase64, roomCategory) {
  const maxTokens = 1500;
  const userPrompt = generateCostUserPrompt(roomCategory);
  const bedrockruntime = await getBedrockRuntimeClient();
  const userContent = [
    {
      type: "image",
      source: {
        type: "base64",
        media_type: "image/jpeg",
        data: imageBase64,
      },
    },
    {
      type: "text",
      text: userPrompt,
    },
  ];
  try {
    return await invokeModel(
      bedrockruntime,
      "anthropic.claude-3-5-sonnet-20240620-v1:0",
      SYS_COST_PROMPT,
      userContent,
      maxTokens
    );
  } catch (error) {
    console.log("Error generating cost estimation:", error);
    return null;
  }
}

export async function genImageCaptionAPI(imageURL) {
  const maxTokens = 300;
  const bedrockruntime = await getBedrockRuntimeClient();
  const userContent = [
    {
      type: "text",
      text: USER_CAPTION_PROMPT + " " + imageURL,
    },
  ];
  try {
    const response = await invokeModel(
      bedrockruntime,
      "anthropic.claude-3-5-sonnet-20240620-v1:0",
      SYS_CAPTION_PROMPT,
      userContent,
      maxTokens
    );
    return await response.content[0].text;
  } catch (error) {
    console.log("Error generating image caption:", error);
    return null;
  }
}
