import React, { useState, useRef, useEffect } from "react";
import { Flex, Image, ScrollView, Text, Button } from "@aws-amplify/ui-react";
import CostEstimation from "./costEstimation";
import { encodeBlobToBase64 } from "../imageUtils";
import { callCostEstimationAPI } from "../../../components/api";
import Modal from "react-modal";

Modal.setAppElement("#root");

const EstimatePage = ({ selectedItems, setSelectedItems }) => {
  const [costEstimation, setCostEstimation] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [imageViewURL, setImageViewURL] = useState(null);
  const [totalCost, setTotalCost] = useState(0);

  useEffect(() => {
    const selectedItem = selectedItems[selectedImageIndex];
    if (imageViewURL === null) {
      setImageViewURL(
        selectedItem.inpainted[selectedItem.inpainted.length - 1]
      );
    }
  }, [selectedItems]);

  useEffect(() => {
    const selectedItem = selectedItems[selectedImageIndex];
    setTotalCost(0);
    for (const cost of selectedItem.cost) {
      const materialCost = parseFloat(cost.materialCost || 0);
      const laborCost = parseFloat(cost.laborCost || 0);
      setTotalCost((prev) => prev + materialCost + laborCost);
    }
  }, [costEstimation, selectedItems]);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    const selectedItem = selectedItems[index];
    setImageViewURL(selectedItem.generated);
  };

  const handleAddCost = () => {
    // if (prompt.trim() !== "") {
    //   const selectedItem = selectedItems[selectedImageIndex];
    //   const cost = selectedItem.cost || [];
    //   setSelectedItems([...cost, { name: prompt, cost: null }]);
    //   // Todo: Add cost to selectedItems with new text inputs
    //   const updatedSelectedItems = [...selectedItems];
    //   if (updatedSelectedItems[selectedImageIndex]) {
    //     const selectedItem = updatedSelectedItems[selectedImageIndex];
    //     const updatedCosts = selectedItem.cost
    //       ? [...selectedItem.cost, newCost]
    //       : [newCost];
    //     updatedSelectedItems[selectedImageIndex] = {
    //       ...selectedItem,
    //       cost: updatedCosts,
    //     };
    //   }
    //   setSelectedItems(updatedSelectedItems);
    //   setPrompt("");
    // }
  };

  const handleDeleteCost = (index) => {
    setCostEstimation(costEstimation.filter((_, i) => i !== index));
  };

  const handleCostEstimation = async () => {
    setLoading(true);
    const selectedItem = selectedItems[selectedImageIndex];
    const imageURL = selectedItem.inpainted[selectedItem.inpainted.length - 1];
    const imageBase64 = await encodeBlobToBase64(imageURL);

    try {
      const response = await callCostEstimationAPI(
        imageBase64,
        selectedItem.category
      );
      const responseText = response.content[0].text;
      const startIndex = responseText.indexOf("{");
      const endIndex = responseText.lastIndexOf("}");
      const jsonString = responseText.substring(startIndex, endIndex + 1);
      const estimatedObjects = JSON.parse(jsonString);
      const costEstimationArray = Object.entries(estimatedObjects).map(
        ([component, { description, materialCost, laborCost }]) => ({
          component,
          description,
          materialCost: materialCost.replace("$", ""),
          laborCost: laborCost.replace("$", ""),
        })
      );

      const updatedSelectedItems = [...selectedItems];
      if (updatedSelectedItems[selectedImageIndex]) {
        const selectedItem = updatedSelectedItems[selectedImageIndex];
        const updatedCosts = selectedItem.cost
          ? [...selectedItem.cost, ...costEstimationArray]
          : costEstimationArray;
        updatedSelectedItems[selectedImageIndex] = {
          ...selectedItem,
          cost: updatedCosts,
        };
      }
      setSelectedItems(updatedSelectedItems);
    } catch (error) {
      console.error("Error processing cost estimation:", error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex
      className="create-flex"
      direction="row"
      alignItems="start"
      borderRadius="20px"
      padding="20px"
      gap="20px"
    >
      <Flex direction="column" alignContent="start" alignItems="start">
        {selectedImageIndex !== null && (
          <Image src={imageViewURL} maxHeight="auto" borderRadius="20px" />
        )}
        <ScrollView
          width="100%"
          style={{ overflowX: "scroll", whiteSpace: "nowrap" }}
        >
          <Flex direction="row" gap="20px" alignItems="center">
            {selectedItems.length > 0 ? (
              <>
                {selectedItems.map((item, index) => (
                  <Image
                    key={index}
                    src={item.generated}
                    alt={`Selected ${index}`}
                    onClick={() => handleImageClick(index)}
                    style={{ cursor: "pointer", height: "100px" }}
                    borderRadius="20px"
                    marginRight="10px"
                  />
                ))}
              </>
            ) : (
              <Text>Loading...</Text>
            )}
          </Flex>
        </ScrollView>
      </Flex>

      <CostEstimation
        windowWidth={window.innerWidth * 0.25}
        windowHeight={window.innerHeight * 0.7}
        costEstimation={selectedItems[selectedImageIndex].cost}
        setCostEstimation={setCostEstimation}
        handleCostEstimation={handleCostEstimation}
        handleAddCost={handleAddCost}
        handleDeleteCost={handleDeleteCost}
        loading={loading}
        totalCost={totalCost}
      />
    </Flex>
  );
};

export default EstimatePage;
