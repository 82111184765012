import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Image,
  Alert,
  Button,
  Divider,
  SearchField,
  TextField,
} from "@aws-amplify/ui-react";
import Modal from "react-modal";
import axios from "axios";
import { ikeaLogo } from "../../../imagepath";
import { LiaMagicSolid } from "react-icons/lia";
import "../../../assets/css/style.css";
import { IoMdClose } from "react-icons/io";

const ImageSearchModal = ({
  showSearchModal,
  setShowSearchModal,
  prompt,
  setPrompt,
  handleGenerateInpainting,
  selectedCategory,
  product,
  setProduct,
  loading,
  errorMessage,
  setErrorMessage,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = async () => {
    const baseUrl = `https://sik.search.blue.cdtapps.com/us/en/search-result-page`;
    const params = {
      autocorrect: "true",
      "subcategories-style": "tree-navigation",
      types: "PRODUCT",
      q: searchQuery,
      size: 40,
      c: "sr",
      v: "20210322",
    };

    try {
      const response = await axios.get(baseUrl, { params });
      setSearchResults(response.data.searchResultPage.products.main.items);
    } catch (error) {
      console.error("Error fetching from IKEA API:", error);
    }
  };

  const handleSetProduct = (result) => {
    setProduct((currentProduct) => {
      if (currentProduct && currentProduct.id === result.product.id) {
        setPrompt("");
        return null;
      } else {
        setPrompt(result.product.mainImageAlt.replace(/^\S+\s*/, ""));
        return {
          id: result.product.id,
          src: result.product.mainImageUrl,
          alt: result.product.mainImageAlt,
          name: result.product.name,
          price:
            result.product.salesPrice.current.prefix +
            result.product.salesPrice.current.wholeNumber,
          searchQuery,
        };
      }
    });
  };

  return (
    <Modal
      isOpen={showSearchModal}
      onRequestClose={() => setShowSearchModal(false)}
      contentLabel="Modify Appliance"
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          padding: "10px",
          borderRadius: "10px",
          width: "600px",
          maxHeight: "90vh",
          overflowY: "auto",
        },
      }}
    >
      <Flex direction="column" gap="10px" alignItems="center">
        <Flex alignSelf="end">
          <IoMdClose
            size={25}
            onClick={() => {
              if (!loading) {
                setShowSearchModal(false);
              }
            }}
            style={{
              cursor: loading ? "not-allowed" : "pointer",
              pointerEvents: loading ? "none" : "auto",
            }}
          />
        </Flex>
        <Image src={ikeaLogo} alt="Ikea Logo" width="100px" />
        <SearchField
          placeholder="Search for appliances or furniture"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onSubmit={handleSearch}
          onClear={() => setSearchQuery("")}
          disabled={loading}
        />
        {searchResults.length > 0 && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "10px",
              maxHeight: "400px",
              overflowY: "auto",
            }}
          >
            {searchResults.map((result, index) => (
              <Flex key={result.product.id} direction="column" gap="5px">
                <Image
                  key={result.product.id}
                  src={result.product.mainImageUrl}
                  alt={result.product.name}
                  style={{
                    cursor: "pointer",
                    filter:
                      product && product.id === result.product.id
                        ? "grayscale(100%)"
                        : "none",
                    opacity:
                      product && product.id === result.product.id ? 0.5 : 1,
                    boxShadow:
                      product && product.id === result.product.id
                        ? "0px 0px 10px 5px rgba(0,0,0,0.15)"
                        : "none",
                  }}
                  onClick={() => handleSetProduct(result)}
                />
                <Text>
                  {result.product.mainImageAlt} :{" "}
                  {result.product.salesPrice.current.prefix}
                  {result.product.salesPrice.current.wholeNumber}{" "}
                </Text>
              </Flex>
            ))}
          </div>
        )}
        <Divider />
        <Flex
          direction="column"
          marginTop="10px"
          alignItems="stretch"
          width="100%"
        >
          <TextField
            label="What would you like to generate here?"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            disabled={loading}
          />
          <Alert variation="info" isDismissible={false} hasIcon={true}>
            You can write your own prompt here to generate a custom object.
            <br />
            For example, "A red chair with a wooden frame" or "A white fridge
            with a water dispenser"
          </Alert>
          <Button
            className="colorful-button"
            isLoading={loading}
            loadingText="Generating..."
            onClick={() => {
              handleGenerateInpainting(product);
            }}
          >
            <LiaMagicSolid size="30" />
            Replace
          </Button>
          {loading && (
            <Alert variation="info" isDismissible={false} hasIcon={true}>
              We will generate a new object based on your image and prompt to
              replace the selected mask, creating a similar but not identical
              result.
            </Alert>
          )}
          {errorMessage && (
            <Alert
              variation="error"
              isDismissible={true}
              hasIcon={true}
              heading={errorMessage.heading}
              onDismiss={() => setErrorMessage(null)}
            >
              {errorMessage.message}
            </Alert>
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ImageSearchModal;
