import React from "react";
import { NavLink } from "react-router-dom";

const Sidebar = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <aside className="h-screen w-64 bg-white shadow-md rounded-lg">
      <nav className="mt-6">
        {tabs.map((tab) => {
          const IconComponent = tab.icon; // Get the icon component
          return (
            <NavLink
              key={tab.id}
              to={`/dashboard/${tab.id}`}
              className={`flex items-center px-6 py-3 text-gray-600 hover:bg-gray-100 hover:text-gray-800 ${
                activeTab === tab.id ? "bg-gray-100 text-gray-800" : ""
              }`}
              onClick={() => setActiveTab(tab.id)}
            >
              <IconComponent className="w-5 h-5 mr-3" />
              {tab.name}
            </NavLink>
          );
        })}
      </nav>
    </aside>
  );
};

export default Sidebar;
