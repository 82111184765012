/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Autocomplete,
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  ScrollView,
  SwitchField,
  Text,
  TextAreaField,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import {
  getCustomer,
  getProject,
  listBids,
  listCustomers,
} from "../graphql/queries";
import { updateBid, updateProject } from "../graphql/mutations";
const client = generateClient();
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function ProjectUpdateForm(props) {
  const {
    id: idProp,
    project: projectModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    projectName: "",
    projectDescription: "",
    images: [],
    expectedStartDate: "",
    location: "",
    remodelType: [],
    publiclyVisible: false,
    customerID: undefined,
    phone: "",
    email: "",
    submitDateTime: "",
    status: "",
    publicImage: "",
    Bids: [],
    createdAt: "",
  };
  const [projectName, setProjectName] = React.useState(
    initialValues.projectName
  );
  const [projectDescription, setProjectDescription] = React.useState(
    initialValues.projectDescription
  );
  const [images, setImages] = React.useState(initialValues.images);
  const [expectedStartDate, setExpectedStartDate] = React.useState(
    initialValues.expectedStartDate
  );
  const [location, setLocation] = React.useState(initialValues.location);
  const [remodelType, setRemodelType] = React.useState(
    initialValues.remodelType
  );
  const [publiclyVisible, setPubliclyVisible] = React.useState(
    initialValues.publiclyVisible
  );
  const [customerID, setCustomerID] = React.useState(initialValues.customerID);
  const [customerIDLoading, setCustomerIDLoading] = React.useState(false);
  const [customerIDRecords, setCustomerIDRecords] = React.useState([]);
  const [selectedCustomerIDRecords, setSelectedCustomerIDRecords] =
    React.useState([]);
  const [phone, setPhone] = React.useState(initialValues.phone);
  const [email, setEmail] = React.useState(initialValues.email);
  const [submitDateTime, setSubmitDateTime] = React.useState(
    initialValues.submitDateTime
  );
  const [status, setStatus] = React.useState(initialValues.status);
  const [publicImage, setPublicImage] = React.useState(
    initialValues.publicImage
  );
  const [Bids, setBids] = React.useState(initialValues.Bids);
  const [BidsLoading, setBidsLoading] = React.useState(false);
  const [bidsRecords, setBidsRecords] = React.useState([]);
  const [createdAt, setCreatedAt] = React.useState(initialValues.createdAt);
  const autocompleteLength = 10;
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = projectRecord
      ? { ...initialValues, ...projectRecord, customerID, Bids: linkedBids }
      : initialValues;
    setProjectName(cleanValues.projectName);
    setProjectDescription(cleanValues.projectDescription);
    setImages(
      cleanValues.images?.map((item) =>
        typeof item === "string" ? item : JSON.stringify(item)
      ) ?? []
    );
    setCurrentImagesValue("");
    setExpectedStartDate(cleanValues.expectedStartDate);
    setLocation(
      typeof cleanValues.location === "string" || cleanValues.location === null
        ? cleanValues.location
        : JSON.stringify(cleanValues.location)
    );
    setRemodelType(cleanValues.remodelType ?? []);
    setCurrentRemodelTypeValue("");
    setPubliclyVisible(cleanValues.publiclyVisible);
    setCustomerID(cleanValues.customerID);
    setCurrentCustomerIDValue(undefined);
    setCurrentCustomerIDDisplayValue("");
    setPhone(cleanValues.phone);
    setEmail(cleanValues.email);
    setSubmitDateTime(cleanValues.submitDateTime);
    setStatus(cleanValues.status);
    setPublicImage(cleanValues.publicImage);
    setBids(cleanValues.Bids ?? []);
    setCurrentBidsValue(undefined);
    setCurrentBidsDisplayValue("");
    setCreatedAt(cleanValues.createdAt);
    setErrors({});
  };
  const [projectRecord, setProjectRecord] = React.useState(projectModelProp);
  const [linkedBids, setLinkedBids] = React.useState([]);
  const canUnlinkBids = false;
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getProject.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getProject
        : projectModelProp;
      const customerIDRecord = record ? record.customerID : undefined;
      const customerRecord = customerIDRecord
        ? (
            await client.graphql({
              query: getCustomer.replaceAll("__typename", ""),
              variables: { id: customerIDRecord },
            })
          )?.data?.getCustomer
        : undefined;
      setCustomerID(customerIDRecord);
      setSelectedCustomerIDRecords([customerRecord]);
      const linkedBids = record?.Bids?.items ?? [];
      setLinkedBids(linkedBids);
      setProjectRecord(record);
    };
    queryData();
  }, [idProp, projectModelProp]);
  React.useEffect(resetStateValues, [projectRecord, customerID, linkedBids]);
  const [currentImagesValue, setCurrentImagesValue] = React.useState("");
  const imagesRef = React.createRef();
  const [currentRemodelTypeValue, setCurrentRemodelTypeValue] =
    React.useState("");
  const remodelTypeRef = React.createRef();
  const [currentCustomerIDDisplayValue, setCurrentCustomerIDDisplayValue] =
    React.useState("");
  const [currentCustomerIDValue, setCurrentCustomerIDValue] =
    React.useState(undefined);
  const customerIDRef = React.createRef();
  const [currentBidsDisplayValue, setCurrentBidsDisplayValue] =
    React.useState("");
  const [currentBidsValue, setCurrentBidsValue] = React.useState(undefined);
  const BidsRef = React.createRef();
  const getIDValue = {
    Bids: (r) => JSON.stringify({ id: r?.id }),
  };
  const BidsIdSet = new Set(
    Array.isArray(Bids)
      ? Bids.map((r) => getIDValue.Bids?.(r))
      : getIDValue.Bids?.(Bids)
  );
  const getDisplayValue = {
    customerID: (r) => `${r?.name ? r?.name + " - " : ""}${r?.id}`,
    Bids: (r) => `${r?.title ? r?.title + " - " : ""}${r?.id}`,
  };
  const validations = {
    projectName: [],
    projectDescription: [],
    images: [{ type: "JSON" }],
    expectedStartDate: [],
    location: [{ type: "JSON" }],
    remodelType: [],
    publiclyVisible: [],
    customerID: [{ type: "Required" }],
    phone: [{ type: "Phone" }],
    email: [{ type: "Email" }],
    submitDateTime: [{ type: "Required" }],
    status: [],
    publicImage: [],
    Bids: [],
    createdAt: [{ type: "Required" }],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  const convertToLocal = (date) => {
    const df = new Intl.DateTimeFormat("default", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      calendar: "iso8601",
      numberingSystem: "latn",
      hourCycle: "h23",
    });
    const parts = df.formatToParts(date).reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
    return `${parts.year}-${parts.month}-${parts.day}T${parts.hour}:${parts.minute}`;
  };
  const fetchCustomerIDRecords = async (value) => {
    setCustomerIDLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ name: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listCustomers.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listCustomers?.items;
      var loaded = result.filter((item) => customerID !== item.id);
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setCustomerIDRecords(newOptions.slice(0, autocompleteLength));
    setCustomerIDLoading(false);
  };
  const fetchBidsRecords = async (value) => {
    setBidsLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ title: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listBids.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listBids?.items;
      var loaded = result.filter(
        (item) => !BidsIdSet.has(getIDValue.Bids?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setBidsRecords(newOptions.slice(0, autocompleteLength));
    setBidsLoading(false);
  };
  React.useEffect(() => {
    fetchCustomerIDRecords("");
    fetchBidsRecords("");
  }, []);
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          projectName: projectName ?? null,
          projectDescription: projectDescription ?? null,
          images: images ?? null,
          expectedStartDate: expectedStartDate ?? null,
          location: location ?? null,
          remodelType: remodelType ?? null,
          publiclyVisible: publiclyVisible ?? null,
          customerID,
          phone: phone ?? null,
          email: email ?? null,
          submitDateTime,
          status: status ?? null,
          publicImage: publicImage ?? null,
          Bids: Bids ?? null,
          createdAt,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(
                    fieldName,
                    item,
                    getDisplayValue[fieldName]
                  )
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(
                fieldName,
                modelFields[fieldName],
                getDisplayValue[fieldName]
              )
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          const promises = [];
          const bidsToLink = [];
          const bidsToUnLink = [];
          const bidsSet = new Set();
          const linkedBidsSet = new Set();
          Bids.forEach((r) => bidsSet.add(getIDValue.Bids?.(r)));
          linkedBids.forEach((r) => linkedBidsSet.add(getIDValue.Bids?.(r)));
          linkedBids.forEach((r) => {
            if (!bidsSet.has(getIDValue.Bids?.(r))) {
              bidsToUnLink.push(r);
            }
          });
          Bids.forEach((r) => {
            if (!linkedBidsSet.has(getIDValue.Bids?.(r))) {
              bidsToLink.push(r);
            }
          });
          bidsToUnLink.forEach((original) => {
            if (!canUnlinkBids) {
              throw Error(
                `Bid ${original.id} cannot be unlinked from Project because projectID is a required field.`
              );
            }
            promises.push(
              client.graphql({
                query: updateBid.replaceAll("__typename", ""),
                variables: {
                  input: {
                    id: original.id,
                    projectID: null,
                  },
                },
              })
            );
          });
          bidsToLink.forEach((original) => {
            promises.push(
              client.graphql({
                query: updateBid.replaceAll("__typename", ""),
                variables: {
                  input: {
                    id: original.id,
                    projectID: projectRecord.id,
                  },
                },
              })
            );
          });
          const modelFieldsToSave = {
            projectName: modelFields.projectName ?? null,
            projectDescription: modelFields.projectDescription ?? null,
            expectedStartDate: modelFields.expectedStartDate ?? null,
            remodelType: modelFields.remodelType ?? null,
            publiclyVisible: modelFields.publiclyVisible ?? null,
            customerID: modelFields.customerID,
            phone: modelFields.phone ?? null,
            email: modelFields.email ?? null,
            submitDateTime: modelFields.submitDateTime,
            status: modelFields.status ?? null,
            publicImage: modelFields.publicImage ?? null,
            createdAt: modelFields.createdAt,
            images: modelFields.images.map((s) => JSON.parse(s)),
            location: modelFields.location
              ? JSON.parse(modelFields.location)
              : modelFields.location,
          };
          promises.push(
            client.graphql({
              query: updateProject.replaceAll("__typename", ""),
              variables: {
                input: {
                  id: projectRecord.id,
                  ...modelFieldsToSave,
                },
              },
            })
          );
          await Promise.all(promises);
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "ProjectUpdateForm")}
      {...rest}
    >
      <TextField
        label="Project name"
        isRequired={false}
        isReadOnly={false}
        value={projectName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              projectName: value,
              projectDescription,
              images,
              expectedStartDate,
              location,
              remodelType,
              publiclyVisible,
              customerID,
              phone,
              email,
              submitDateTime,
              status,
              publicImage,
              Bids,
              createdAt,
            };
            const result = onChange(modelFields);
            value = result?.projectName ?? value;
          }
          if (errors.projectName?.hasError) {
            runValidationTasks("projectName", value);
          }
          setProjectName(value);
        }}
        onBlur={() => runValidationTasks("projectName", projectName)}
        errorMessage={errors.projectName?.errorMessage}
        hasError={errors.projectName?.hasError}
        {...getOverrideProps(overrides, "projectName")}
      ></TextField>
      <TextField
        label="Project description"
        isRequired={false}
        isReadOnly={false}
        value={projectDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              projectName,
              projectDescription: value,
              images,
              expectedStartDate,
              location,
              remodelType,
              publiclyVisible,
              customerID,
              phone,
              email,
              submitDateTime,
              status,
              publicImage,
              Bids,
              createdAt,
            };
            const result = onChange(modelFields);
            value = result?.projectDescription ?? value;
          }
          if (errors.projectDescription?.hasError) {
            runValidationTasks("projectDescription", value);
          }
          setProjectDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("projectDescription", projectDescription)
        }
        errorMessage={errors.projectDescription?.errorMessage}
        hasError={errors.projectDescription?.hasError}
        {...getOverrideProps(overrides, "projectDescription")}
      ></TextField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              projectName,
              projectDescription,
              images: values,
              expectedStartDate,
              location,
              remodelType,
              publiclyVisible,
              customerID,
              phone,
              email,
              submitDateTime,
              status,
              publicImage,
              Bids,
              createdAt,
            };
            const result = onChange(modelFields);
            values = result?.images ?? values;
          }
          setImages(values);
          setCurrentImagesValue("");
        }}
        currentFieldValue={currentImagesValue}
        label={"Images"}
        items={images}
        hasError={errors?.images?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("images", currentImagesValue)
        }
        errorMessage={errors?.images?.errorMessage}
        setFieldValue={setCurrentImagesValue}
        inputFieldRef={imagesRef}
        defaultFieldValue={""}
      >
        <TextAreaField
          label="Images"
          isRequired={false}
          isReadOnly={false}
          value={currentImagesValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.images?.hasError) {
              runValidationTasks("images", value);
            }
            setCurrentImagesValue(value);
          }}
          onBlur={() => runValidationTasks("images", currentImagesValue)}
          errorMessage={errors.images?.errorMessage}
          hasError={errors.images?.hasError}
          ref={imagesRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "images")}
        ></TextAreaField>
      </ArrayField>
      <TextField
        label="Expected start date"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={expectedStartDate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              projectName,
              projectDescription,
              images,
              expectedStartDate: value,
              location,
              remodelType,
              publiclyVisible,
              customerID,
              phone,
              email,
              submitDateTime,
              status,
              publicImage,
              Bids,
              createdAt,
            };
            const result = onChange(modelFields);
            value = result?.expectedStartDate ?? value;
          }
          if (errors.expectedStartDate?.hasError) {
            runValidationTasks("expectedStartDate", value);
          }
          setExpectedStartDate(value);
        }}
        onBlur={() =>
          runValidationTasks("expectedStartDate", expectedStartDate)
        }
        errorMessage={errors.expectedStartDate?.errorMessage}
        hasError={errors.expectedStartDate?.hasError}
        {...getOverrideProps(overrides, "expectedStartDate")}
      ></TextField>
      <TextAreaField
        label="Location"
        isRequired={false}
        isReadOnly={false}
        value={location}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              projectName,
              projectDescription,
              images,
              expectedStartDate,
              location: value,
              remodelType,
              publiclyVisible,
              customerID,
              phone,
              email,
              submitDateTime,
              status,
              publicImage,
              Bids,
              createdAt,
            };
            const result = onChange(modelFields);
            value = result?.location ?? value;
          }
          if (errors.location?.hasError) {
            runValidationTasks("location", value);
          }
          setLocation(value);
        }}
        onBlur={() => runValidationTasks("location", location)}
        errorMessage={errors.location?.errorMessage}
        hasError={errors.location?.hasError}
        {...getOverrideProps(overrides, "location")}
      ></TextAreaField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              projectName,
              projectDescription,
              images,
              expectedStartDate,
              location,
              remodelType: values,
              publiclyVisible,
              customerID,
              phone,
              email,
              submitDateTime,
              status,
              publicImage,
              Bids,
              createdAt,
            };
            const result = onChange(modelFields);
            values = result?.remodelType ?? values;
          }
          setRemodelType(values);
          setCurrentRemodelTypeValue("");
        }}
        currentFieldValue={currentRemodelTypeValue}
        label={"Remodel type"}
        items={remodelType}
        hasError={errors?.remodelType?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("remodelType", currentRemodelTypeValue)
        }
        errorMessage={errors?.remodelType?.errorMessage}
        setFieldValue={setCurrentRemodelTypeValue}
        inputFieldRef={remodelTypeRef}
        defaultFieldValue={""}
      >
        <TextField
          label="Remodel type"
          isRequired={false}
          isReadOnly={false}
          value={currentRemodelTypeValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.remodelType?.hasError) {
              runValidationTasks("remodelType", value);
            }
            setCurrentRemodelTypeValue(value);
          }}
          onBlur={() =>
            runValidationTasks("remodelType", currentRemodelTypeValue)
          }
          errorMessage={errors.remodelType?.errorMessage}
          hasError={errors.remodelType?.hasError}
          ref={remodelTypeRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "remodelType")}
        ></TextField>
      </ArrayField>
      <SwitchField
        label="Publicly visible"
        defaultChecked={false}
        isDisabled={false}
        isChecked={publiclyVisible}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              projectName,
              projectDescription,
              images,
              expectedStartDate,
              location,
              remodelType,
              publiclyVisible: value,
              customerID,
              phone,
              email,
              submitDateTime,
              status,
              publicImage,
              Bids,
              createdAt,
            };
            const result = onChange(modelFields);
            value = result?.publiclyVisible ?? value;
          }
          if (errors.publiclyVisible?.hasError) {
            runValidationTasks("publiclyVisible", value);
          }
          setPubliclyVisible(value);
        }}
        onBlur={() => runValidationTasks("publiclyVisible", publiclyVisible)}
        errorMessage={errors.publiclyVisible?.errorMessage}
        hasError={errors.publiclyVisible?.hasError}
        {...getOverrideProps(overrides, "publiclyVisible")}
      ></SwitchField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              projectName,
              projectDescription,
              images,
              expectedStartDate,
              location,
              remodelType,
              publiclyVisible,
              customerID: value,
              phone,
              email,
              submitDateTime,
              status,
              publicImage,
              Bids,
              createdAt,
            };
            const result = onChange(modelFields);
            value = result?.customerID ?? value;
          }
          setCustomerID(value);
          setCurrentCustomerIDValue(undefined);
        }}
        currentFieldValue={currentCustomerIDValue}
        label={"Customer id"}
        items={customerID ? [customerID] : []}
        hasError={errors?.customerID?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("customerID", currentCustomerIDValue)
        }
        errorMessage={errors?.customerID?.errorMessage}
        getBadgeText={(value) =>
          value
            ? getDisplayValue.customerID(
                customerIDRecords.find((r) => r.id === value) ??
                  selectedCustomerIDRecords.find((r) => r.id === value)
              )
            : ""
        }
        setFieldValue={(value) => {
          setCurrentCustomerIDDisplayValue(
            value
              ? getDisplayValue.customerID(
                  customerIDRecords.find((r) => r.id === value) ??
                    selectedCustomerIDRecords.find((r) => r.id === value)
                )
              : ""
          );
          setCurrentCustomerIDValue(value);
          const selectedRecord = customerIDRecords.find((r) => r.id === value);
          if (selectedRecord) {
            setSelectedCustomerIDRecords([selectedRecord]);
          }
        }}
        inputFieldRef={customerIDRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Customer id"
          isRequired={true}
          isReadOnly={false}
          placeholder="Search Customer"
          value={currentCustomerIDDisplayValue}
          options={customerIDRecords
            .filter(
              (r, i, arr) =>
                arr.findIndex((member) => member?.id === r?.id) === i
            )
            .map((r) => ({
              id: r?.id,
              label: getDisplayValue.customerID?.(r),
            }))}
          isLoading={customerIDLoading}
          onSelect={({ id, label }) => {
            setCurrentCustomerIDValue(id);
            setCurrentCustomerIDDisplayValue(label);
            runValidationTasks("customerID", label);
          }}
          onClear={() => {
            setCurrentCustomerIDDisplayValue("");
          }}
          defaultValue={customerID}
          onChange={(e) => {
            let { value } = e.target;
            fetchCustomerIDRecords(value);
            if (errors.customerID?.hasError) {
              runValidationTasks("customerID", value);
            }
            setCurrentCustomerIDDisplayValue(value);
            setCurrentCustomerIDValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks("customerID", currentCustomerIDValue)
          }
          errorMessage={errors.customerID?.errorMessage}
          hasError={errors.customerID?.hasError}
          ref={customerIDRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "customerID")}
        ></Autocomplete>
      </ArrayField>
      <TextField
        label="Phone"
        isRequired={false}
        isReadOnly={false}
        type="tel"
        value={phone}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              projectName,
              projectDescription,
              images,
              expectedStartDate,
              location,
              remodelType,
              publiclyVisible,
              customerID,
              phone: value,
              email,
              submitDateTime,
              status,
              publicImage,
              Bids,
              createdAt,
            };
            const result = onChange(modelFields);
            value = result?.phone ?? value;
          }
          if (errors.phone?.hasError) {
            runValidationTasks("phone", value);
          }
          setPhone(value);
        }}
        onBlur={() => runValidationTasks("phone", phone)}
        errorMessage={errors.phone?.errorMessage}
        hasError={errors.phone?.hasError}
        {...getOverrideProps(overrides, "phone")}
      ></TextField>
      <TextField
        label="Email"
        isRequired={false}
        isReadOnly={false}
        value={email}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              projectName,
              projectDescription,
              images,
              expectedStartDate,
              location,
              remodelType,
              publiclyVisible,
              customerID,
              phone,
              email: value,
              submitDateTime,
              status,
              publicImage,
              Bids,
              createdAt,
            };
            const result = onChange(modelFields);
            value = result?.email ?? value;
          }
          if (errors.email?.hasError) {
            runValidationTasks("email", value);
          }
          setEmail(value);
        }}
        onBlur={() => runValidationTasks("email", email)}
        errorMessage={errors.email?.errorMessage}
        hasError={errors.email?.hasError}
        {...getOverrideProps(overrides, "email")}
      ></TextField>
      <TextField
        label="Submit date time"
        isRequired={true}
        isReadOnly={false}
        type="datetime-local"
        value={submitDateTime && convertToLocal(new Date(submitDateTime))}
        onChange={(e) => {
          let value =
            e.target.value === "" ? "" : new Date(e.target.value).toISOString();
          if (onChange) {
            const modelFields = {
              projectName,
              projectDescription,
              images,
              expectedStartDate,
              location,
              remodelType,
              publiclyVisible,
              customerID,
              phone,
              email,
              submitDateTime: value,
              status,
              publicImage,
              Bids,
              createdAt,
            };
            const result = onChange(modelFields);
            value = result?.submitDateTime ?? value;
          }
          if (errors.submitDateTime?.hasError) {
            runValidationTasks("submitDateTime", value);
          }
          setSubmitDateTime(value);
        }}
        onBlur={() => runValidationTasks("submitDateTime", submitDateTime)}
        errorMessage={errors.submitDateTime?.errorMessage}
        hasError={errors.submitDateTime?.hasError}
        {...getOverrideProps(overrides, "submitDateTime")}
      ></TextField>
      <TextField
        label="Status"
        isRequired={false}
        isReadOnly={false}
        value={status}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              projectName,
              projectDescription,
              images,
              expectedStartDate,
              location,
              remodelType,
              publiclyVisible,
              customerID,
              phone,
              email,
              submitDateTime,
              status: value,
              publicImage,
              Bids,
              createdAt,
            };
            const result = onChange(modelFields);
            value = result?.status ?? value;
          }
          if (errors.status?.hasError) {
            runValidationTasks("status", value);
          }
          setStatus(value);
        }}
        onBlur={() => runValidationTasks("status", status)}
        errorMessage={errors.status?.errorMessage}
        hasError={errors.status?.hasError}
        {...getOverrideProps(overrides, "status")}
      ></TextField>
      <TextField
        label="Public image"
        isRequired={false}
        isReadOnly={false}
        value={publicImage}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              projectName,
              projectDescription,
              images,
              expectedStartDate,
              location,
              remodelType,
              publiclyVisible,
              customerID,
              phone,
              email,
              submitDateTime,
              status,
              publicImage: value,
              Bids,
              createdAt,
            };
            const result = onChange(modelFields);
            value = result?.publicImage ?? value;
          }
          if (errors.publicImage?.hasError) {
            runValidationTasks("publicImage", value);
          }
          setPublicImage(value);
        }}
        onBlur={() => runValidationTasks("publicImage", publicImage)}
        errorMessage={errors.publicImage?.errorMessage}
        hasError={errors.publicImage?.hasError}
        {...getOverrideProps(overrides, "publicImage")}
      ></TextField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              projectName,
              projectDescription,
              images,
              expectedStartDate,
              location,
              remodelType,
              publiclyVisible,
              customerID,
              phone,
              email,
              submitDateTime,
              status,
              publicImage,
              Bids: values,
              createdAt,
            };
            const result = onChange(modelFields);
            values = result?.Bids ?? values;
          }
          setBids(values);
          setCurrentBidsValue(undefined);
          setCurrentBidsDisplayValue("");
        }}
        currentFieldValue={currentBidsValue}
        label={"Bids"}
        items={Bids}
        hasError={errors?.Bids?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("Bids", currentBidsValue)
        }
        errorMessage={errors?.Bids?.errorMessage}
        getBadgeText={getDisplayValue.Bids}
        setFieldValue={(model) => {
          setCurrentBidsDisplayValue(model ? getDisplayValue.Bids(model) : "");
          setCurrentBidsValue(model);
        }}
        inputFieldRef={BidsRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Bids"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Bid"
          value={currentBidsDisplayValue}
          options={bidsRecords
            .filter((r) => !BidsIdSet.has(getIDValue.Bids?.(r)))
            .map((r) => ({
              id: getIDValue.Bids?.(r),
              label: getDisplayValue.Bids?.(r),
            }))}
          isLoading={BidsLoading}
          onSelect={({ id, label }) => {
            setCurrentBidsValue(
              bidsRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentBidsDisplayValue(label);
            runValidationTasks("Bids", label);
          }}
          onClear={() => {
            setCurrentBidsDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchBidsRecords(value);
            if (errors.Bids?.hasError) {
              runValidationTasks("Bids", value);
            }
            setCurrentBidsDisplayValue(value);
            setCurrentBidsValue(undefined);
          }}
          onBlur={() => runValidationTasks("Bids", currentBidsDisplayValue)}
          errorMessage={errors.Bids?.errorMessage}
          hasError={errors.Bids?.hasError}
          ref={BidsRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "Bids")}
        ></Autocomplete>
      </ArrayField>
      <TextField
        label="Created at"
        isRequired={true}
        isReadOnly={false}
        type="datetime-local"
        value={createdAt && convertToLocal(new Date(createdAt))}
        onChange={(e) => {
          let value =
            e.target.value === "" ? "" : new Date(e.target.value).toISOString();
          if (onChange) {
            const modelFields = {
              projectName,
              projectDescription,
              images,
              expectedStartDate,
              location,
              remodelType,
              publiclyVisible,
              customerID,
              phone,
              email,
              submitDateTime,
              status,
              publicImage,
              Bids,
              createdAt: value,
            };
            const result = onChange(modelFields);
            value = result?.createdAt ?? value;
          }
          if (errors.createdAt?.hasError) {
            runValidationTasks("createdAt", value);
          }
          setCreatedAt(value);
        }}
        onBlur={() => runValidationTasks("createdAt", createdAt)}
        errorMessage={errors.createdAt?.errorMessage}
        hasError={errors.createdAt?.hasError}
        {...getOverrideProps(overrides, "createdAt")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || projectModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || projectModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
