import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import { updateProject, deleteProject } from "../../../../graphql/mutations";
import { getProject } from "../../../../graphql/queries";
import { getUrl } from "aws-amplify/storage";
import CostEstimationTable from "../../../components/costEstimationTable";
import ImageGallery from "react-image-gallery";

const ProjectDetail = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const client = generateClient();
  const [isEditing, setIsEditing] = useState(false);
  const [editedProject, setEditedProject] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [costEstimation, setCostEstimation] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const [imageItems, setImageItems] = useState([]);

  const getImages = async () => {
    if (project && project.images) {
      const images = await Promise.all(
        project.images.flatMap(async (image) => {
          const beforeImageUrl = image.beforeImage
            ? await getUrl({ path: image.beforeImage })
            : null;
          const afterImageUrl = image.afterImage
            ? await getUrl({ path: image.afterImage })
            : null;
          return afterImageUrl === null
            ? [
                {
                  original: beforeImageUrl.url,
                  thumbnail: beforeImageUrl.url,
                  description: image.description,
                },
              ]
            : [
                {
                  original: afterImageUrl.url,
                  thumbnail: afterImageUrl.url,
                  description: image.description,
                },
                {
                  original: beforeImageUrl.url,
                  thumbnail: beforeImageUrl.url,
                  description: image.description,
                },
              ];
        })
      );
      setImageItems(images.flat());
    }
  };

  useEffect(() => {
    const fetchProject = async () => {
      try {
        setLoading(true);
        const response = await client.graphql({
          query: getProject.replaceAll("__typename", ""),
          variables: { id: projectId },
        });
        setProject(response.data.getProject);
      } catch (error) {
        console.error("Error fetching project:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [projectId]);

  useEffect(() => {
    getImages();
  }, [project]);

  useEffect(() => {
    try {
      const cost = project.images[currentIndex]?.cost;
      setCostEstimation(JSON.parse(cost));
    } catch (err) {
      setCostEstimation(null);
    }
  }, [currentIndex, project]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
      </div>
    );
  }

  const handleEditClick = () => {
    setEditedProject({ ...project });
    setIsEditing(true);
  };

  const handleUpdateProject = async (editedProject) => {
    try {
      const updateInput = {
        id: editedProject.id,
        projectName: editedProject.projectName,
        projectDescription: editedProject.projectDescription,
        expectedStartDate: editedProject.expectedStartDate,
        status: editedProject.status,
      };

      const response = await client.graphql({
        query: updateProject.replaceAll("__typename", ""),
        variables: { input: updateInput },
      });
      setProject(response.data.updateProject);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating project:", error);
    }
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteProject = async () => {
    try {
      await client.graphql({
        query: deleteProject,
        variables: { input: { id: project.id } },
      });
      navigate("/dashboard#projects"); // Redirect after deletion
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };

  return (
    <div className="w-full p-6 bg-white rounded-lg shadow-md">
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
        </div>
      ) : !project ? (
        <div>Project not found</div>
      ) : (
        <div>
          <div className="flex items-center justify-between mb-6 items-center">
            <h2 className="text-3xl font-semibold text-gray-800 mb-6">
              {project.projectName}
            </h2>
            {isEditing ? (
              <div>
                <input
                  type="text"
                  className="border rounded p-2 mb-4 w-full"
                  value={editedProject.projectName}
                  onChange={(e) =>
                    setEditedProject({
                      ...editedProject,
                      projectName: e.target.value,
                    })
                  }
                />
                <textarea
                  className="border rounded p-2 mb-4 w-full"
                  value={editedProject.projectDescription}
                  onChange={(e) =>
                    setEditedProject({
                      ...editedProject,
                      projectDescription: e.target.value,
                    })
                  }
                />
                <button
                  className="bg-blue-500 text-white rounded p-2 mr-2 hover:bg-blue-600"
                  onClick={() => handleUpdateProject(editedProject)}
                >
                  Save
                </button>
                <button
                  className="bg-gray-300 text-gray-700 rounded p-2 hover:bg-gray-400"
                  onClick={() => setIsEditing(false)}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div>
                <button
                  className="bg-blue-500 text-white rounded p-2 mr-2 hover:bg-blue-600"
                  onClick={handleEditClick}
                >
                  Edit
                </button>
                <button
                  className="bg-red-500 text-white rounded p-2 hover:bg-red-600"
                  onClick={handleDeleteClick}
                >
                  Delete
                </button>
              </div>
            )}
          </div>
          <p className="text-gray-600 mb-4">{project.projectDescription}</p>
          {imageItems.length > 0 && (
            <div className="mt-6">
              <ImageGallery
                items={imageItems.map(({ description, ...item }) => item)}
                showPlayButton={false}
                onSlide={setCurrentIndex}
              />
            </div>
          )}
          {costEstimation && (
            <CostEstimationTable
              costEstimation={costEstimation}
              setCostEstimation={setCostEstimation}
              editable={false}
            />
          )}
          {/* Modal for delete confirmation */}
          {showDeleteModal && (
            <div className="mt-6">
              <p>Are you sure you want to delete this project?</p>
              <button
                className="bg-red-500 text-white rounded p-2 mr-2"
                onClick={handleDeleteProject}
              >
                Yes
              </button>
              <button
                className="bg-gray-300 rounded p-2"
                onClick={() => setShowDeleteModal(false)}
              >
                No
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProjectDetail;
