import React from "react";
import Modal from "react-modal";
import { Button, Flex, Heading } from "@aws-amplify/ui-react";
import "./style.css";

Modal.setAppElement("#root");

const UserDeleteModal = ({ show, setShow, handleDelete }) => {
  return (
    <Modal
      className="modal"
      isOpen={show}
      onRequestClose={() => setShow(false)}
      contentLabel="Delete User Modal"
    >
      <Flex direction="column" gap="1rem" alignItems="center" padding="20px">
        <Heading level={5}>Are you sure you want to delete this user?</Heading>
        <Flex direction="row" gap="1rem">
          <Button
            variant="primary"
            backgroundColor="lime"
            onClick={() => setShow(false)}
          >
            Cancel
          </Button>
          <Button
            color={"gray"}
            borderColor={"transparent"}
            onClick={() => {
              handleDelete();
              setShow(false);
            }}
          >
            Delete
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default UserDeleteModal;
