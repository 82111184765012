import React from "react";
import { useNavigate } from "react-router-dom";
import { StorageImage } from "@aws-amplify/ui-react-storage";

const ProjectList = ({ projects, setSelectedProject }) => {
  const navigate = useNavigate();

  const handleProjectClick = (project) => {
    setSelectedProject(project);
    navigate(`/dashboard/projects/${project.id}`);
  };

  const handleCreateProjectClick = () => {
    navigate("/create");
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <div
        className="bg-gradient-to-r from-pink-500 to-yellow-500 rounded-lg shadow-lg p-6 cursor-pointer flex items-center justify-center transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl text-center"
        onClick={handleCreateProjectClick}
      >
        <h3 className="text-2xl font-semibold text-white">
          + <br /> Add New Project
        </h3>
      </div>

      {projects.map((project) => {
        return (
          <div
            key={project.id}
            className="bg-white rounded-lg shadow-md p-6 cursor-pointer transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl"
            onClick={() => handleProjectClick(project)}
          >
            <h3 className="text-xl font-semibold text-gray-700 mb-2">
              {project.projectName}
            </h3>
            <StorageImage
              path={project.publicImage}
              alt={project.projectName}
              className="w-full h-auto rounded-lg"
            />
            <div className="flex items-center space-x-2 mt-2">
              <span
                className={`inline-block px-3 py-1 text-sm font-semibold text-white rounded-full ${
                  project.status === "OPEN"
                    ? "bg-green-500"
                    : project.status === "Pending"
                    ? "bg-yellow-500"
                    : "bg-red-500"
                }`}
              >
                {project.status}
              </span>
              <span className="text-sm text-gray-500">
                {new Date(project.submitDateTime).toLocaleDateString()}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProjectList;
