import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  Image,
  Text,
  Alert,
  Heading,
  TextField,
  Autocomplete,
  PhoneNumberField,
} from "@aws-amplify/ui-react";
import { FiEdit } from "react-icons/fi";
import {
  getCustomer,
  getContractor,
  listUsers,
} from "../../../graphql/queries";
import {
  updateCustomer,
  updateContractor,
  updateUsers,
} from "../../../graphql/mutations";
import { generateClient } from "aws-amplify/api";
import { defaultProfileImage } from "../../imagepath";
import { getUrl } from "aws-amplify/storage";
import { deleteUser } from "aws-amplify/auth";
import { Geo } from "@aws-amplify/geo";

import ProfileImageModal from "./profileImageModal";
import UserDeleteModal from "./userDeleteModal";

const client = generateClient();

const ProfilePage = () => {
  // Common states for both customer and contractor
  const id = localStorage.getItem("id");
  const role = localStorage.getItem("role");

  const [user, setUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [profileImage, setProfileImage] = useState(null); // AWS S3 URL
  const [imageURL, setImageURL] = useState(null); // Blob URL

  const [isHovered, setIsHovered] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [originalUser, setOriginalUser] = useState(user);

  const [isImageCropperOpen, setIsImageCropperOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteModalClose = () => setShowDeleteModal(false);
  const handleDeleteModalShow = () => setShowDeleteModal(true);

  const [zipcodeResult, setZipcodeResult] = useState([]);

  useEffect(() => {
    if (id) {
      fetchUser();
    }
  }, [id]);

  const fetchUser = async () => {
    setIsLoading(true);
    let result;
    if (id && role === "customer") {
      result = await client.graphql({
        query: getCustomer.replaceAll("__typename", ""),
        variables: { id: id },
      });
    } else if (id && role === "contractor") {
      result = await client.graphql({
        query: getContractor.replaceAll("__typename", ""),
        variables: { id: id },
      });
    }

    if (result) {
      const user =
        role === "customer"
          ? result.data.getCustomer
          : result.data.getContractor;
      setUser(user);
      setOriginalUser(user);
      setProfileImage(await getImageUrl(user.photo));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const loadImage = async () => {
      const response = await fetch(profileImage);
      const blob = await response.blob();
      const objectURL = URL.createObjectURL(blob);
      setImageURL(objectURL);
    };

    if (profileImage) {
      loadImage();
    }
  }, [profileImage]);

  const getImageUrl = async (path) => {
    if (path) {
      const url = await getUrl({ path: path });
      return url.url;
    }
    return null;
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setErrorMessage(null);
    setIsEditing(false);
  };

  const handlePhoneChange = (value) => {
    const dialCode = user.phone ? user.phone.split(" ")[0] : "+1";
    const newPhoneNumber = `${dialCode} ${value}`;
    setUser({ ...user, phone: newPhoneNumber });
  };

  const handleDialCodeChange = (value) => {
    const phoneNumber = user.phone ? user.phone.split(" ")[1] : "";
    const newPhoneNumber = `${value} ${phoneNumber}`;
    setUser({ ...user, phone: newPhoneNumber });
  };

  const handleSave = async () => {
    try {
      if (role === "customer") {
        const result = await client.graphql({
          query: updateCustomer.replaceAll("__typename", ""),
          variables: {
            input: {
              id: user.id,
              name: user.name,
              phone: user.phone,
              location: user.location,
              photo: user.photo,
              status: "active",
            },
          },
        });
        setIsEditing(false);
      } else if (role === "contractor") {
        const result = await client.graphql({
          query: updateContractor.replaceAll("__typename", ""),
          variables: {
            input: {
              id: user.id,
              name: user.name,
              phone: user.phone,
              photo: user.photo,
              companyName: user.companyName,
              website: user.website,
              socialNetworkLinks: user.socialNetworkLinks,
              expertises: user.expertises,
              email: user.email,
              location: user.location,
              status: "active",
            },
          },
        });
      }

      setIsEditing(false);
      setErrorMessage(null);
      await fetchUser();
    } catch (error) {
      console.error("An error occurred while updating the contractor:", error);
      setErrorMessage("An error occurred while updating the contractor.");
    }
  };

  const handleDelete = async () => {
    try {
      await deleteUser();
      await updateDeletedUsers();
    } catch (error) {
      console.log(error);
      setErrorMessage("An error occurred while deleting the user.");
    }
  };

  const updateDeletedUsers = async () => {
    try {
      const result = await client.graphql({
        query: listUsers.replaceAll("__typename", ""),
      })?.data?.listUsers?.items;
      // result has id, role, userId, roleId, status
    } catch (error) {
      console.error("An error occurred while getting the users:", error);
      setErrorMessage("An error occurred while getting the users.");
    }
  };

  const handleZipcodeChange = async (event) => {
    const zipcode = event.target.value;
    if (zipcode.length > 0) {
      const results = await Geo.searchByText(zipcode, {
        MaxResults: 5,
        countries: ["USA"],
      });
      if (results.length > 0) {
        const uniqueResults = Array.from(
          new Set(results.map((result) => result.label))
        ).map((label) => {
          return results.find((result) => result.label === label);
        });
        const zipAutoComplete = uniqueResults.map((result, idx) => ({
          id: result.label,
          label: result.label,
          location: {
            city: result.municipality,
            state: result.region,
            address1: "",
            address2: "",
            zipCode: result.postalCode,
          },
        }));
        setZipcodeResult(zipAutoComplete);
      }
    }
  };

  const handleZipcodeSelect = (option) => {
    let newLocation;
    if (!option.location) {
      newLocation = {
        city: option.municipality,
        state: option.region,
        address1: "",
        address2: "",
        zipCode: option.postalCode.includes("-")
          ? option.postalCode.split("-")[0]
          : option.postalCode,
      };
    } else {
      newLocation = option.location;
    }
    if (JSON.stringify(originalUser.location) !== JSON.stringify(newLocation)) {
      setUser({ ...user, location: newLocation });
    }
  };

  if (!user) {
    return null;
  }

  return isLoading ? (
    <Text>Loading...</Text>
  ) : (
    <Flex
      direction="column"
      alignItems="center"
      width="400px"
      height="auto"
      padding="32px"
      gap="1rem"
      backgroundColor={"#f9f9f9"}
      borderRadius={"20px"}
      boxShadow={"0px 0px 5px 0px #AFAFAF"}
    >
      <Heading level={4}>Profile</Heading>
      <Flex justifyContent="right" alignItems="end" width="100%">
        {!isEditing && (
          <Button variation="link" onClick={handleEdit}>
            <FiEdit size="20" /> Edit
          </Button>
        )}
      </Flex>
      {isEditing ? (
        <Flex direction="column">
          <TextField
            label="Name"
            defaultValue={originalUser.name}
            onChange={(e) => setUser({ ...user, name: e.target.value })}
          />
          <PhoneNumberField
            defaultDialCode={
              originalUser.phone ? originalUser.phone.split(" ")[0] : "+1"
            }
            label="PhoneNumber"
            defaultValue={
              originalUser.phone ? originalUser.phone.split(" ")[1] : ""
            }
            onInput={(e) => handlePhoneChange(e.target.value)}
            onDialCodeChange={(e) => handleDialCodeChange(e.target.value)}
          />
          <Autocomplete
            label="Address"
            labelHidden={false}
            options={zipcodeResult}
            placeholder="Search ZipCode..."
            onChange={handleZipcodeChange}
            hasSearchButton={true}
            onSelect={handleZipcodeSelect}
          />
          <Flex direction="row" justifyContent="center">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              variation="primary"
              onClick={handleSave}
              disabled={JSON.stringify(user) === JSON.stringify(originalUser)}
            >
              Save
            </Button>
          </Flex>
          <Button
            variation="link"
            style={{ color: "gray", backgroundColor: "transparent" }}
            onClick={() => setShowDeleteModal(true)}
          >
            Delete User
          </Button>
        </Flex>
      ) : (
        <Flex direction="column" alignItems="center" gap="1rem">
          <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{ position: "relative", width: "150px", height: "150px" }}
          >
            <Image
              src={profileImage || defaultProfileImage}
              alt="Profile"
              width="150px"
              height="150px"
              style={{ borderRadius: "50%" }}
            />
            {isHovered && (
              <FiEdit
                size="20"
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  cursor: "pointer",
                }}
                onClick={() => setIsImageCropperOpen(true)}
              />
            )}
          </div>
          <Heading level={5}>{originalUser.name}</Heading>
          <Heading level={6}>{role}</Heading>
          <Flex direction="column" gap="0.5rem" alignItems="center">
            <Text>
              {originalUser.location.city}, {originalUser.location.state},{" "}
              {originalUser.location.zipCode}
            </Text>
            <Text>{originalUser.email}</Text>
            <Text>
              {originalUser.phone
                ? originalUser.phone
                : "Please add your phone number"}
            </Text>
            <Text>Generation Credits: {originalUser.paidStatus}</Text>
            {/* <Button onClick={grantCreditContractor}>Contractor Grant Credit</Button> */}
          </Flex>
        </Flex>
      )}
      {errorMessage && <Alert variation="error">{errorMessage}</Alert>}

      <ProfileImageModal
        id={id}
        role={role}
        isImageCropperOpen={isImageCropperOpen}
        setIsImageCropperOpen={setIsImageCropperOpen}
        imageURL={imageURL}
        setImageURL={setImageURL}
        setImage={setProfileImage}
      />

      <UserDeleteModal
        show={showDeleteModal}
        setShow={handleDeleteModalClose}
        handleDelete={handleDelete}
      />
    </Flex>
  );
};

export default ProfilePage;
