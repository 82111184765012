import * as images from "../imagepath";

export const themeImagePaths = {
  kitchen: {
    style: [
      {
        label: "Modern",
        prompt:
          "Modern, Kitchen, Clean lines, minimalistic, sleek surfaces, high-tech appliances.",
        path: images.ImageModernKitchen,
      },
      {
        label: "Minimalist",
        prompt:
          "Minimalist, Kitchen, Simple, streamlined, hidden storage solutions.",
        path: images.ImageMinimalistKitchen,
      },
      {
        label: "Mid-Century",
        prompt:
          "Mid Century Modern, Kitchen, warm woods, bold colors, vintage appliances.",
        path: images.ImageMidcenturyKitchen,
      },
      {
        label: "Rustic",
        prompt:
          "Rustic, Kitchen, Warm, inviting, distressed wood, farmhouse sink, vintage touches.",
        path: images.ImageRusticKitchen,
      },
      {
        label: "Scandinavian",
        prompt:
          "Scandinavian, Kitchen, Light, airy, white cabinets, natural wood accents.",
        path: images.ImageScandinavianKitchen,
      },
      {
        label: "Bohemian",
        prompt: "bohemian style kitchen",
        path: images.ImageBohemianKitchen,
      },
      {
        label: "Industrial",
        prompt:
          "Industrial, Kitchen, urban aesthetic, exposed materials, metal accents, edgy lighting.",
        path: images.ImageIndustrialKitchen,
      },
      {
        label: "Coastal",
        prompt:
          "Kitchen, Beachy vibes, blue hues, light wood tones, natural textures, relaxed atmosphere.",
        path: images.ImageCoastalKitchen,
      },
      {
        label: "Art Deco",
        prompt: "art deco style kitchen",
        path: images.ImageArtdecoKitchen,
      },
      {
        label: "Tropical",
        prompt:
          "Tropical, Kitchen, Island-inspired, natural materials, exotic prints, tropical motifs..png",
        path: images.ImageTropicalKitchen,
      },
    ],
  },
  bedroom: {
    style: [
      {
        label: "Modern",
        prompt:
          "Modern, Bedroom, Sleek, minimalist, neutral tones, built-in storage, statement lighting.",
        path: images.ImageModernBedroom,
      },
      {
        label: "Minimalist",
        prompt:
          "Minimalist, Bedroom, Simple, serene, clean lines, neutral colors, uncluttered space.",
        path: images.ImageMinimalistBedroom,
      },
      {
        label: "Mid-Century",
        prompt:
          "Mid Century Modern, Bedroom, Retro-inspired, iconic furniture, bold patterns, warm woods.",
        path: images.ImageMidcenturyBedroom,
      },
      {
        label: "Rustic",
        prompt:
          "Rustic, Bedroom, Cozy, warm, natural materials, earthy tones, rustic accents.",
        path: images.ImageRusticBedroom,
      },
      {
        label: "Scandinavian",
        prompt:
          "Scandinavian, Bedroom, Light, airy, cozy textiles, natural wood.",
        path: images.ImageScandinavianBedroom,
      },
      {
        label: "Bohemian",
        prompt: "bohemian style, bedroom",
        path: images.ImageBohemianBedroom,
      },
      {
        label: "Industrial",
        prompt:
          "Industrial, Bedroom, Raw, urban, exposed materials, metal accents, edgy lighting.",
        path: images.ImageIndustrialBedroom,
      },
      {
        label: "Coastal",
        prompt:
          "Bedroom, Beachy vibes, blue hues, light wood tones, natural textures, relaxed atmosphere.",
        path: images.ImageCoastalBedroom,
      },
      {
        label: "Art Deco",
        prompt: "art deco style, bedroom",
        path: images.ImageArtdecoBedroom,
      },
      {
        label: "Tropical",
        prompt:
          "Tropical, Bedroom, Island-inspired, natural materials, exotic prints, tropical motifs.",
        path: images.ImageTropicalBedroom,
      },
    ],
  },
  livingroom: {
    style: [
      {
        label: "Modern",
        prompt:
          "Modern Living Room, Clean lines, neutral tones, sleek furniture, statement artwork.",
        path: images.ImageModernLivingRoom,
      },
      {
        label: "Minimalist",
        prompt:
          "Living room, Minimalist, Simple, clutter-free, neutral color palette, essential furniture.",
        path: images.ImageMinimalistLivingRoom,
      },

      {
        label: "Mid-Century",
        prompt:
          "Mid Century Modern, Living Room, Vintage-inspired, iconic furniture, bold patterns, retro accents.",
        path: images.ImageMidcenturyLivingRoom,
      },
      {
        label: "Rustic",
        prompt:
          "Rustic, Living room, Natural materials, cozy textures, warm colors, rustic accents.",
        path: images.ImageRusticLivingRoom,
      },
      {
        label: "Scandinavian",
        prompt:
          "Scandinavian, Living room, Light woods, white walls, minimalist design, cozy textiles.",
        path: images.ImageScandinavianLivingRoom,
      },
      {
        label: "Bohemian",
        prompt: "bohemian style, living room",
        path: images.ImageBohemianLivingRoom,
      },
      {
        label: "Industrial",
        prompt: "Industrial, living room, urban aesthetic",
        path: images.ImageIndustrialLivingRoom,
      },
      {
        label: "Coastal",
        prompt:
          "Living room, Beachy vibes, blue hues, light wood tones, natural textures, relaxed atmosphere.",
        path: images.ImageCoastalLivingRoom,
      },
      {
        label: "Art Deco",
        prompt: "art deco style living room, glass, minimalism, bold color",
        path: images.ImageArtdecoLivingRoom,
      },
      {
        label: "Tropical",
        prompt:
          "Tropical, Living room, Island-inspired, natural materials, exotic prints, tropical motifs.",
        path: images.ImageTropicalLivingRoom,
      },
    ],
  },
};
