/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEmailSubscription = /* GraphQL */ `
  mutation CreateEmailSubscription(
    $input: CreateEmailSubscriptionInput!
    $condition: ModelEmailSubscriptionConditionInput
  ) {
    createEmailSubscription(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEmailSubscription = /* GraphQL */ `
  mutation UpdateEmailSubscription(
    $input: UpdateEmailSubscriptionInput!
    $condition: ModelEmailSubscriptionConditionInput
  ) {
    updateEmailSubscription(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEmailSubscription = /* GraphQL */ `
  mutation DeleteEmailSubscription(
    $input: DeleteEmailSubscriptionInput!
    $condition: ModelEmailSubscriptionConditionInput
  ) {
    deleteEmailSubscription(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTaskProject = /* GraphQL */ `
  mutation CreateTaskProject(
    $input: CreateTaskProjectInput!
    $condition: ModelTaskProjectConditionInput
  ) {
    createTaskProject(input: $input, condition: $condition) {
      id
      name
      Tasks {
        items {
          id
          title
          description
          status
          startDate
          endDate
          progress
          taskprojectID
          createdAt
          updatedAt
          taskProjectId
          taskContractorId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTaskProject = /* GraphQL */ `
  mutation UpdateTaskProject(
    $input: UpdateTaskProjectInput!
    $condition: ModelTaskProjectConditionInput
  ) {
    updateTaskProject(input: $input, condition: $condition) {
      id
      name
      Tasks {
        items {
          id
          title
          description
          status
          startDate
          endDate
          progress
          taskprojectID
          createdAt
          updatedAt
          taskProjectId
          taskContractorId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTaskProject = /* GraphQL */ `
  mutation DeleteTaskProject(
    $input: DeleteTaskProjectInput!
    $condition: ModelTaskProjectConditionInput
  ) {
    deleteTaskProject(input: $input, condition: $condition) {
      id
      name
      Tasks {
        items {
          id
          title
          description
          status
          startDate
          endDate
          progress
          taskprojectID
          createdAt
          updatedAt
          taskProjectId
          taskContractorId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBid = /* GraphQL */ `
  mutation CreateBid(
    $input: CreateBidInput!
    $condition: ModelBidConditionInput
  ) {
    createBid(input: $input, condition: $condition) {
      id
      title
      amount
      message
      startDate
      endDate
      description
      files
      status
      contractorID
      projectID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBid = /* GraphQL */ `
  mutation UpdateBid(
    $input: UpdateBidInput!
    $condition: ModelBidConditionInput
  ) {
    updateBid(input: $input, condition: $condition) {
      id
      title
      amount
      message
      startDate
      endDate
      description
      files
      status
      contractorID
      projectID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBid = /* GraphQL */ `
  mutation DeleteBid(
    $input: DeleteBidInput!
    $condition: ModelBidConditionInput
  ) {
    deleteBid(input: $input, condition: $condition) {
      id
      title
      amount
      message
      startDate
      endDate
      description
      files
      status
      contractorID
      projectID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      id
      role
      userId
      roleId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      role
      userId
      roleId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $input: DeleteUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    deleteUsers(input: $input, condition: $condition) {
      id
      role
      userId
      roleId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReview = /* GraphQL */ `
  mutation CreateReview(
    $input: CreateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    createReview(input: $input, condition: $condition) {
      id
      rating
      comment
      Contractor {
        id
        companyName
        website
        socialNetworkLinks
        expertises
        portfolio
        PortfolioItems {
          nextToken
          __typename
        }
        phone
        email
        location {
          city
          state
          address1
          address2
          zipCode
          __typename
        }
        photo
        name
        status
        Bids {
          nextToken
          __typename
        }
        Messages {
          nextToken
          __typename
        }
        paidStatus
        paidStart
        paidEnd
        paidPlan
        createdAt
        updatedAt
        __typename
      }
      Project {
        id
        projectName
        projectDescription
        images {
          afterImage
          beforeImage
          isMain
          roomType
          idx
          description
          cost
          __typename
        }
        expectedStartDate
        location {
          city
          state
          address1
          address2
          zipCode
          __typename
        }
        remodelType
        publiclyVisible
        customerID
        phone
        email
        submitDateTime
        status
        publicImage
        Bids {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      reviewContractorId
      reviewProjectId
      __typename
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview(
    $input: UpdateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    updateReview(input: $input, condition: $condition) {
      id
      rating
      comment
      Contractor {
        id
        companyName
        website
        socialNetworkLinks
        expertises
        portfolio
        PortfolioItems {
          nextToken
          __typename
        }
        phone
        email
        location {
          city
          state
          address1
          address2
          zipCode
          __typename
        }
        photo
        name
        status
        Bids {
          nextToken
          __typename
        }
        Messages {
          nextToken
          __typename
        }
        paidStatus
        paidStart
        paidEnd
        paidPlan
        createdAt
        updatedAt
        __typename
      }
      Project {
        id
        projectName
        projectDescription
        images {
          afterImage
          beforeImage
          isMain
          roomType
          idx
          description
          cost
          __typename
        }
        expectedStartDate
        location {
          city
          state
          address1
          address2
          zipCode
          __typename
        }
        remodelType
        publiclyVisible
        customerID
        phone
        email
        submitDateTime
        status
        publicImage
        Bids {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      reviewContractorId
      reviewProjectId
      __typename
    }
  }
`;
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview(
    $input: DeleteReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    deleteReview(input: $input, condition: $condition) {
      id
      rating
      comment
      Contractor {
        id
        companyName
        website
        socialNetworkLinks
        expertises
        portfolio
        PortfolioItems {
          nextToken
          __typename
        }
        phone
        email
        location {
          city
          state
          address1
          address2
          zipCode
          __typename
        }
        photo
        name
        status
        Bids {
          nextToken
          __typename
        }
        Messages {
          nextToken
          __typename
        }
        paidStatus
        paidStart
        paidEnd
        paidPlan
        createdAt
        updatedAt
        __typename
      }
      Project {
        id
        projectName
        projectDescription
        images {
          afterImage
          beforeImage
          isMain
          roomType
          idx
          description
          cost
          __typename
        }
        expectedStartDate
        location {
          city
          state
          address1
          address2
          zipCode
          __typename
        }
        remodelType
        publiclyVisible
        customerID
        phone
        email
        submitDateTime
        status
        publicImage
        Bids {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      reviewContractorId
      reviewProjectId
      __typename
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      title
      description
      status
      startDate
      endDate
      Project {
        id
        projectName
        projectDescription
        images {
          afterImage
          beforeImage
          isMain
          roomType
          idx
          description
          cost
          __typename
        }
        expectedStartDate
        location {
          city
          state
          address1
          address2
          zipCode
          __typename
        }
        remodelType
        publiclyVisible
        customerID
        phone
        email
        submitDateTime
        status
        publicImage
        Bids {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Contractor {
        id
        companyName
        website
        socialNetworkLinks
        expertises
        portfolio
        PortfolioItems {
          nextToken
          __typename
        }
        phone
        email
        location {
          city
          state
          address1
          address2
          zipCode
          __typename
        }
        photo
        name
        status
        Bids {
          nextToken
          __typename
        }
        Messages {
          nextToken
          __typename
        }
        paidStatus
        paidStart
        paidEnd
        paidPlan
        createdAt
        updatedAt
        __typename
      }
      progress
      taskprojectID
      createdAt
      updatedAt
      taskProjectId
      taskContractorId
      __typename
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      title
      description
      status
      startDate
      endDate
      Project {
        id
        projectName
        projectDescription
        images {
          afterImage
          beforeImage
          isMain
          roomType
          idx
          description
          cost
          __typename
        }
        expectedStartDate
        location {
          city
          state
          address1
          address2
          zipCode
          __typename
        }
        remodelType
        publiclyVisible
        customerID
        phone
        email
        submitDateTime
        status
        publicImage
        Bids {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Contractor {
        id
        companyName
        website
        socialNetworkLinks
        expertises
        portfolio
        PortfolioItems {
          nextToken
          __typename
        }
        phone
        email
        location {
          city
          state
          address1
          address2
          zipCode
          __typename
        }
        photo
        name
        status
        Bids {
          nextToken
          __typename
        }
        Messages {
          nextToken
          __typename
        }
        paidStatus
        paidStart
        paidEnd
        paidPlan
        createdAt
        updatedAt
        __typename
      }
      progress
      taskprojectID
      createdAt
      updatedAt
      taskProjectId
      taskContractorId
      __typename
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      title
      description
      status
      startDate
      endDate
      Project {
        id
        projectName
        projectDescription
        images {
          afterImage
          beforeImage
          isMain
          roomType
          idx
          description
          cost
          __typename
        }
        expectedStartDate
        location {
          city
          state
          address1
          address2
          zipCode
          __typename
        }
        remodelType
        publiclyVisible
        customerID
        phone
        email
        submitDateTime
        status
        publicImage
        Bids {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Contractor {
        id
        companyName
        website
        socialNetworkLinks
        expertises
        portfolio
        PortfolioItems {
          nextToken
          __typename
        }
        phone
        email
        location {
          city
          state
          address1
          address2
          zipCode
          __typename
        }
        photo
        name
        status
        Bids {
          nextToken
          __typename
        }
        Messages {
          nextToken
          __typename
        }
        paidStatus
        paidStart
        paidEnd
        paidPlan
        createdAt
        updatedAt
        __typename
      }
      progress
      taskprojectID
      createdAt
      updatedAt
      taskProjectId
      taskContractorId
      __typename
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      name
      email
      Projects {
        items {
          id
          projectName
          projectDescription
          expectedStartDate
          remodelType
          publiclyVisible
          customerID
          phone
          email
          submitDateTime
          status
          publicImage
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      phone
      location {
        city
        state
        address1
        address2
        zipCode
        __typename
      }
      photo
      status
      Messages {
        items {
          id
          content
          createdAt
          customerRead
          contractorRead
          customerID
          contractorID
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      paidStatus
      paidStart
      paidEnd
      paidPlan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      name
      email
      Projects {
        items {
          id
          projectName
          projectDescription
          expectedStartDate
          remodelType
          publiclyVisible
          customerID
          phone
          email
          submitDateTime
          status
          publicImage
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      phone
      location {
        city
        state
        address1
        address2
        zipCode
        __typename
      }
      photo
      status
      Messages {
        items {
          id
          content
          createdAt
          customerRead
          contractorRead
          customerID
          contractorID
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      paidStatus
      paidStart
      paidEnd
      paidPlan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      name
      email
      Projects {
        items {
          id
          projectName
          projectDescription
          expectedStartDate
          remodelType
          publiclyVisible
          customerID
          phone
          email
          submitDateTime
          status
          publicImage
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      phone
      location {
        city
        state
        address1
        address2
        zipCode
        __typename
      }
      photo
      status
      Messages {
        items {
          id
          content
          createdAt
          customerRead
          contractorRead
          customerID
          contractorID
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      paidStatus
      paidStart
      paidEnd
      paidPlan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPortfolioItem = /* GraphQL */ `
  mutation CreatePortfolioItem(
    $input: CreatePortfolioItemInput!
    $condition: ModelPortfolioItemConditionInput
  ) {
    createPortfolioItem(input: $input, condition: $condition) {
      id
      contractorID
      projectName
      images
      description
      dateCompleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePortfolioItem = /* GraphQL */ `
  mutation UpdatePortfolioItem(
    $input: UpdatePortfolioItemInput!
    $condition: ModelPortfolioItemConditionInput
  ) {
    updatePortfolioItem(input: $input, condition: $condition) {
      id
      contractorID
      projectName
      images
      description
      dateCompleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePortfolioItem = /* GraphQL */ `
  mutation DeletePortfolioItem(
    $input: DeletePortfolioItemInput!
    $condition: ModelPortfolioItemConditionInput
  ) {
    deletePortfolioItem(input: $input, condition: $condition) {
      id
      contractorID
      projectName
      images
      description
      dateCompleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContractor = /* GraphQL */ `
  mutation CreateContractor(
    $input: CreateContractorInput!
    $condition: ModelContractorConditionInput
  ) {
    createContractor(input: $input, condition: $condition) {
      id
      companyName
      website
      socialNetworkLinks
      expertises
      portfolio
      PortfolioItems {
        items {
          id
          contractorID
          projectName
          images
          description
          dateCompleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      phone
      email
      location {
        city
        state
        address1
        address2
        zipCode
        __typename
      }
      photo
      name
      status
      Bids {
        items {
          id
          title
          amount
          message
          startDate
          endDate
          description
          files
          status
          contractorID
          projectID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Messages {
        items {
          id
          content
          createdAt
          customerRead
          contractorRead
          customerID
          contractorID
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      paidStatus
      paidStart
      paidEnd
      paidPlan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContractor = /* GraphQL */ `
  mutation UpdateContractor(
    $input: UpdateContractorInput!
    $condition: ModelContractorConditionInput
  ) {
    updateContractor(input: $input, condition: $condition) {
      id
      companyName
      website
      socialNetworkLinks
      expertises
      portfolio
      PortfolioItems {
        items {
          id
          contractorID
          projectName
          images
          description
          dateCompleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      phone
      email
      location {
        city
        state
        address1
        address2
        zipCode
        __typename
      }
      photo
      name
      status
      Bids {
        items {
          id
          title
          amount
          message
          startDate
          endDate
          description
          files
          status
          contractorID
          projectID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Messages {
        items {
          id
          content
          createdAt
          customerRead
          contractorRead
          customerID
          contractorID
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      paidStatus
      paidStart
      paidEnd
      paidPlan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContractor = /* GraphQL */ `
  mutation DeleteContractor(
    $input: DeleteContractorInput!
    $condition: ModelContractorConditionInput
  ) {
    deleteContractor(input: $input, condition: $condition) {
      id
      companyName
      website
      socialNetworkLinks
      expertises
      portfolio
      PortfolioItems {
        items {
          id
          contractorID
          projectName
          images
          description
          dateCompleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      phone
      email
      location {
        city
        state
        address1
        address2
        zipCode
        __typename
      }
      photo
      name
      status
      Bids {
        items {
          id
          title
          amount
          message
          startDate
          endDate
          description
          files
          status
          contractorID
          projectID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Messages {
        items {
          id
          content
          createdAt
          customerRead
          contractorRead
          customerID
          contractorID
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      paidStatus
      paidStart
      paidEnd
      paidPlan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      projectName
      projectDescription
      images {
        afterImage
        beforeImage
        isMain
        roomType
        idx
        description
        cost
        __typename
      }
      expectedStartDate
      location {
        city
        state
        address1
        address2
        zipCode
        __typename
      }
      remodelType
      publiclyVisible
      customerID
      phone
      email
      submitDateTime
      status
      publicImage
      Bids {
        items {
          id
          title
          amount
          message
          startDate
          endDate
          description
          files
          status
          contractorID
          projectID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      projectName
      projectDescription
      images {
        afterImage
        beforeImage
        isMain
        roomType
        idx
        description
        cost
        __typename
      }
      expectedStartDate
      location {
        city
        state
        address1
        address2
        zipCode
        __typename
      }
      remodelType
      publiclyVisible
      customerID
      phone
      email
      submitDateTime
      status
      publicImage
      Bids {
        items {
          id
          title
          amount
          message
          startDate
          endDate
          description
          files
          status
          contractorID
          projectID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      projectName
      projectDescription
      images {
        afterImage
        beforeImage
        isMain
        roomType
        idx
        description
        cost
        __typename
      }
      expectedStartDate
      location {
        city
        state
        address1
        address2
        zipCode
        __typename
      }
      remodelType
      publiclyVisible
      customerID
      phone
      email
      submitDateTime
      status
      publicImage
      Bids {
        items {
          id
          title
          amount
          message
          startDate
          endDate
          description
          files
          status
          contractorID
          projectID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      content
      createdAt
      customerRead
      contractorRead
      customerID
      contractorID
      updatedAt
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      content
      createdAt
      customerRead
      contractorRead
      customerID
      contractorID
      updatedAt
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      content
      createdAt
      customerRead
      contractorRead
      customerID
      contractorID
      updatedAt
      __typename
    }
  }
`;
