import Header from "./components/Header";
import Footer from "./components/Footer";
import Banner from "./components/Banner";

const Layout = ({ children }) => {
  return (
    <div className="max-w-7xl flex flex-col min-h-screen mx-auto px-4">
      <Banner />
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
